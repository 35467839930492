import { useTheme } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { transition } from '../../styles/StyledComponentsStyles';

const StyledLink = styled.a<{ textDecorationColor: string; nav: boolean }>`
  width: fit-content;
  font-size: ${({ nav }) => (nav ? '1rem' : '')};
  color: ${({ textDecorationColor }) => textDecorationColor};
  position: relative;
  text-decoration: none;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({ textDecorationColor }) => textDecorationColor};
    will-change: transform;
    transform: translate3d(-55%, 0, 0) scaleX(0);
    ${transition};
  }
  &:hover:after {
    transform: translate3d(0%, 0, 0) scaleX(1);
  }
`;

type Props = {
  href: string;
  nav?: boolean;
  children: React.ReactNode;
};
export function Link({ href, nav = false, children }: Props) {
  const theme = useTheme();
  return (
    <StyledLink
      href={href}
      textDecorationColor={theme.palette.secondary.main}
      nav={nav}
    >
      {children}
    </StyledLink>
  );
}
