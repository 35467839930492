import { CSSStyle } from '../types/types';

export const transition: CSSStyle = {
  transition: 'all 0.7s cubic-bezier(0.01, 0.21, 0.35, 1)',
};

export const hoverable: CSSStyle = {
  ...transition,
  '&:hover': {
    transform: 'scale(1.04)',
  },
};

export const imgBase: CSSStyle = {
  borderRadius: '10px',
  position: 'relative',
};
