import styled, { css } from 'styled-components';
import { flowDownAnim, flowUpAnim } from './starsKeyframes';

const StarContainerLg = styled.div<{ duration: number; flowDown: boolean }>`
  position: relative;
  top: -300px;
  left: -50vw;
  z-index: -1;
  will-change: transform;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: rgb(240 240 240) 2939.2px 3335.79px,
    rgb(240 240 240) 226.42px 2710.98px, rgb(240 240 240) 1976.59px 2846.26px,
    rgb(240 240 240) 1134.29px 493.15px, rgb(240 240 240) 1397.04px 258.579px,
    rgb(240 240 240) 2626.12px 2853.15px, rgb(240 240 240) 3009.3px 2794.55px,
    rgb(240 240 240) 2521.85px 2802.89px, rgb(240 240 240) 977.34px 901.595px,
    rgb(240 240 240) 2581.54px 2644.35px, rgb(240 240 240) 1438.34px 2871.83px,
    rgb(240 240 240) 3218.46px 1783.64px, rgb(240 240 240) 881.802px 2947.66px,
    rgb(240 240 240) 1261.57px 2504.13px, rgb(240 240 240) 2064.21px 2842.06px,
    rgb(240 240 240) 2901.42px 3582.09px, rgb(240 240 240) 3590.43px 1722.03px,
    rgb(240 240 240) 3154.4px 2186.3px, rgb(240 240 240) 770.419px 3358.23px,
    rgb(240 240 240) 637.309px 588.979px, rgb(240 240 240) 494.19px 2022.97px,
    rgb(240 240 240) 3825.73px 2169.32px, rgb(240 240 240) 1709.52px 3772.47px,
    rgb(240 240 240) 3069.04px 727.636px, rgb(240 240 240) 975.166px 2139.74px,
    rgb(240 240 240) 2150.54px 2275.95px, rgb(240 240 240) 804.751px 794.617px,
    rgb(240 240 240) 3013.03px 3161.77px, rgb(240 240 240) 2962.87px 936.301px,
    rgb(240 240 240) 3519.22px 1898.67px, rgb(240 240 240) 2830.47px 395.754px,
    rgb(240 240 240) 293.076px 611.346px, rgb(240 240 240) 3824.46px 3739.4px,
    rgb(240 240 240) 1595.29px 1055.47px, rgb(240 240 240) 139.355px 1763.74px,
    rgb(240 240 240) 1666.33px 1496.42px, rgb(240 240 240) 3291.24px 2888.99px,
    rgb(240 240 240) 1538.82px 3653.9px, rgb(240 240 240) 590.72px 748.522px,
    rgb(240 240 240) 930.302px 3786.88px, rgb(240 240 240) 2067.96px 3475.94px,
    rgb(240 240 240) 2306.38px 3944.26px, rgb(240 240 240) 1925.08px 568.56px,
    rgb(240 240 240) 2734.51px 2389.29px, rgb(240 240 240) 2320.89px 3808.25px,
    rgb(240 240 240) 910.145px 1087.88px, rgb(240 240 240) 3520.07px 2579.38px,
    rgb(240 240 240) 3170.94px 3807.6px, rgb(240 240 240) 601.459px 2334.07px,
    rgb(240 240 240) 2453.29px 3386.43px, rgb(240 240 240) 3889.97px 870.814px,
    rgb(240 240 240) 2892.77px 3497.81px, rgb(240 240 240) 6.4142px 3698.27px,
    rgb(240 240 240) 832.488px 3579.41px, rgb(240 240 240) 2932.45px 685.111px,
    rgb(240 240 240) 678.971px 1424.77px, rgb(240 240 240) 3437.13px 1238.44px,
    rgb(240 240 240) 2032.62px 2735.15px, rgb(240 240 240) 2955.97px 3764.54px,
    rgb(240 240 240) 3288.07px 3421.94px, rgb(240 240 240) 639.272px 1805.38px,
    rgb(240 240 240) 1910.41px 2159.9px, rgb(240 240 240) 2144.43px 3049.69px,
    rgb(240 240 240) 3158.6px 3065.47px, rgb(240 240 240) 1373.94px 2048.04px,
    rgb(240 240 240) 3437.76px 3172.22px, rgb(240 240 240) 1929.83px 107.925px,
    rgb(240 240 240) 3731.08px 2419.73px, rgb(240 240 240) 2119.02px 395.926px,
    rgb(240 240 240) 3710.29px 2097.82px, rgb(240 240 240) 300.237px 251.799px,
    rgb(240 240 240) 2785.84px 2944.91px, rgb(240 240 240) 1899.33px 3625.9px,
    rgb(240 240 240) 1510.84px 1640.81px, rgb(240 240 240) 3400.1px 2787.36px,
    rgb(240 240 240) 3062.95px 679.696px, rgb(240 240 240) 1525.84px 1738.06px,
    rgb(240 240 240) 1604.66px 405.728px, rgb(240 240 240) 617.602px 2606.75px,
    rgb(240 240 240) 2739.09px 2981.46px, rgb(240 240 240) 1055.65px 2085.33px,
    rgb(240 240 240) 3435.24px 3476.11px, rgb(240 240 240) 800.945px 3698.96px,
    rgb(240 240 240) 1077.19px 891.277px, rgb(240 240 240) 343.731px 2036px,
    rgb(240 240 240) 305.877px 1257.19px, rgb(240 240 240) 3631.34px 2985.74px,
    rgb(240 240 240) 3500.66px 2924.8px, rgb(240 240 240) 3818.53px 3128.25px,
    rgb(240 240 240) 3210.31px 1490.48px, rgb(240 240 240) 3022.06px 1068.92px,
    rgb(240 240 240) 2449.46px 693.407px, rgb(240 240 240) 2019.63px 921.271px,
    rgb(240 240 240) 2147.75px 3514.68px, rgb(240 240 240) 2646.12px 980.774px,
    rgb(240 240 240) 2067.4px 3718.12px, rgb(240 240 240) 1959.47px 274.615px,
    rgb(240 240 240) 752.239px 3940.03px, rgb(240 240 240) 2844.93px 1572.43px,
    rgb(240 240 240) 3034.8px 2885.24px, rgb(240 240 240) 941.117px 2646.55px,
    rgb(240 240 240) 2653.67px 1057.29px, rgb(240 240 240) 3938.79px 469.474px,
    rgb(240 240 240) 1477.91px 376.495px, rgb(240 240 240) 870.905px 1550.71px,
    rgb(240 240 240) 2022.83px 3159.53px, rgb(240 240 240) 780.477px 723.629px,
    rgb(240 240 240) 3847.48px 3604.31px, rgb(240 240 240) 2266.18px 2903.25px,
    rgb(240 240 240) 21.0702px 1712.54px, rgb(240 240 240) 930.477px 508.43px,
    rgb(240 240 240) 2327.45px 2104.88px, rgb(240 240 240) 1654.81px 53.1865px,
    rgb(240 240 240) 3134.07px 1059.71px, rgb(240 240 240) 775.805px 1546.48px,
    rgb(240 240 240) 2153.69px 3779.49px, rgb(240 240 240) 1543.76px 2190.63px,
    rgb(240 240 240) 2222.88px 3167.49px, rgb(240 240 240) 1100px 1275.73px,
    rgb(240 240 240) 3397.22px 2704.81px, rgb(240 240 240) 2082.79px 135.203px,
    rgb(240 240 240) 4000.95px 1406.95px, rgb(240 240 240) 3733.08px 2982.83px,
    rgb(240 240 240) 439.65px 657.091px, rgb(240 240 240) 2576.56px 3584.02px,
    rgb(240 240 240) 2645.06px 556.868px, rgb(240 240 240) 3409.33px 3949.49px,
    rgb(240 240 240) 1418.68px 2143.69px, rgb(240 240 240) 420.868px 1071.11px,
    rgb(240 240 240) 1775.56px 1235.86px, rgb(240 240 240) 1927.19px 3869.99px,
    rgb(240 240 240) 1199.34px 2563.16px, rgb(240 240 240) 3209.94px 3241.84px,
    rgb(240 240 240) 1809.9px 199.804px, rgb(240 240 240) 3356.4px 2081.29px,
    rgb(240 240 240) 2899.27px 1910.54px, rgb(240 240 240) 3459.16px 137.533px,
    rgb(240 240 240) 2662.18px 1475.72px, rgb(240 240 240) 2271.82px 1946.05px,
    rgb(240 240 240) 3882.25px 298.53px, rgb(240 240 240) 1157.48px 3989.77px,
    rgb(240 240 240) 3586.19px 1446.08px, rgb(240 240 240) 1854.89px 2937.47px,
    rgb(240 240 240) 1936.23px 304.532px, rgb(240 240 240) 325.324px 2634.09px,
    rgb(240 240 240) 3083.59px 3535.09px, rgb(240 240 240) 644.263px 1485.79px,
    rgb(240 240 240) 2576.58px 3153.95px, rgb(240 240 240) 1123.92px 3301.95px,
    rgb(240 240 240) 1029.04px 2214.57px, rgb(240 240 240) 3496.08px 2642.8px,
    rgb(240 240 240) 2642.86px 2853.2px, rgb(240 240 240) 1799.14px 272.804px,
    rgb(240 240 240) 2949.56px 3079.46px, rgb(240 240 240) 3190.81px 2283.34px,
    rgb(240 240 240) 1125.47px 326.972px, rgb(240 240 240) 1091.82px 3700.16px,
    rgb(240 240 240) 1651.95px 1704.17px, rgb(240 240 240) 3078.31px 3883.73px,
    rgb(240 240 240) 3967.19px 2924.79px, rgb(240 240 240) 2186.6px 3912.12px,
    rgb(240 240 240) 3409.28px 1169.68px, rgb(240 240 240) 2659px 607.752px,
    rgb(240 240 240) 3334.07px 1791.64px, rgb(240 240 240) 3432.22px 2426.29px,
    rgb(240 240 240) 2791.9px 3776.49px, rgb(240 240 240) 2893.85px 343.944px,
    rgb(240 240 240) 3087.68px 3754.68px, rgb(240 240 240) 3097.76px 178.65px,
    rgb(240 240 240) 273.214px 3914.41px, rgb(240 240 240) 1919.99px 849.316px,
    rgb(240 240 240) 3271.46px 554.825px, rgb(240 240 240) 3697.24px 1856.55px,
    rgb(240 240 240) 3674.15px 2081.03px, rgb(240 240 240) 823.997px 952.979px,
    rgb(240 240 240) 140.436px 2446.42px, rgb(240 240 240) 3933.57px 843.035px,
    rgb(240 240 240) 1041.49px 2312.39px, rgb(240 240 240) 3111.36px 22.0425px,
    rgb(240 240 240) 3973.49px 2504.49px, rgb(240 240 240) 1815.88px 417.661px,
    rgb(240 240 240) 1302.44px 2591.27px, rgb(240 240 240) 1819.59px 3455.2px,
    rgb(240 240 240) 490.039px 459.28px, rgb(240 240 240) 2179.65px 1586.01px,
    rgb(240 240 240) 383.75px 1761.23px, rgb(240 240 240) 79.5877px 3541.12px,
    rgb(240 240 240) 3034.19px 3573.18px, rgb(240 240 240) 904.145px 1464.95px,
    rgb(240 240 240) 365.149px 3014.48px, rgb(240 240 240) 339.898px 1484.81px,
    rgb(240 240 240) 2541.54px 3622.4px, rgb(240 240 240) 3471.27px 877.049px,
    rgb(240 240 240) 3243.55px 3430.25px, rgb(240 240 240) 3238.66px 2268.57px,
    rgb(240 240 240) 124.277px 2065.6px, rgb(240 240 240) 2971.13px 2264.47px,
    rgb(240 240 240) 3693.53px 3640.56px, rgb(240 240 240) 1284.92px 3568.08px,
    rgb(240 240 240) 3638.71px 836.07px, rgb(240 240 240) 3508px 3118.21px,
    rgb(240 240 240) 1312.52px 1960.34px, rgb(240 240 240) 3239.85px 3480.15px,
    rgb(240 240 240) 2017.61px 1325.1px, rgb(240 240 240) 893.078px 2046.51px,
    rgb(240 240 240) 3798.9px 1900.26px, rgb(240 240 240) 2947.22px 1396.91px,
    rgb(240 240 240) 3785.58px 1132.42px, rgb(240 240 240) 2101.65px 3625.78px,
    rgb(240 240 240) 2521.47px 2331.52px, rgb(240 240 240) 1574.76px 794.502px,
    rgb(240 240 240) 2277.18px 476.463px, rgb(240 240 240) 975.758px 2835.43px,
    rgb(240 240 240) 3501.96px 1368.82px, rgb(240 240 240) 234.985px 1073.45px,
    rgb(240 240 240) 484.409px 567.287px, rgb(240 240 240) 1403.69px 278.054px,
    rgb(240 240 240) 3014.91px 2728.42px, rgb(240 240 240) 718.348px 160.508px,
    rgb(240 240 240) 2394.32px 2970.86px, rgb(240 240 240) 3662.51px 1559.29px,
    rgb(240 240 240) 2718.01px 1658.91px, rgb(240 240 240) 1291.04px 2201.43px,
    rgb(240 240 240) 1301.15px 3861.57px, rgb(240 240 240) 1007.3px 1053.13px,
    rgb(240 240 240) 109.252px 2373.74px, rgb(240 240 240) 2554.72px 3900.72px,
    rgb(240 240 240) 1219.34px 422.332px, rgb(240 240 240) 3546.42px 436.909px,
    rgb(240 240 240) 2946.6px 2897.4px, rgb(240 240 240) 929.161px 3621.12px,
    rgb(240 240 240) 3693.77px 1637.04px, rgb(240 240 240) 37.8273px 2114.8px,
    rgb(240 240 240) 802.164px 144.281px, rgb(240 240 240) 3470.29px 3072.07px,
    rgb(240 240 240) 1110.21px 3697.92px, rgb(240 240 240) 32.8983px 1627.08px,
    rgb(240 240 240) 225.358px 1434.44px, rgb(240 240 240) 11.2975px 3324.77px,
    rgb(240 240 240) 1065.01px 2548.48px, rgb(240 240 240) 2191.15px 880.412px,
    rgb(240 240 240) 421.495px 3542.94px, rgb(240 240 240) 3188.27px 1764.93px,
    rgb(240 240 240) 534.76px 231.077px, rgb(240 240 240) 2404.24px 3187.99px,
    rgb(240 240 240) 3810.49px 846.836px, rgb(240 240 240) 1746.54px 1890.32px,
    rgb(240 240 240) 958.9px 880.33px, rgb(240 240 240) 3801.1px 3278.47px,
    rgb(240 240 240) 3270.99px 28.42px, rgb(240 240 240) 2983.52px 430.291px,
    rgb(240 240 240) 1624.25px 1180.38px, rgb(240 240 240) 3058.4px 2657.47px,
    rgb(240 240 240) 3663.56px 674.321px, rgb(240 240 240) 3959.89px 3746.95px,
    rgb(240 240 240) 1735.32px 3654.24px, rgb(240 240 240) 3866.15px 1363.04px,
    rgb(240 240 240) 1560.25px 408.354px, rgb(240 240 240) 309.884px 2959.93px,
    rgb(240 240 240) 3314.98px 2071.46px, rgb(240 240 240) 93.3072px 2272.98px,
    rgb(240 240 240) 257.671px 1723.83px, rgb(240 240 240) 1078.5px 1525.88px,
    rgb(240 240 240) 1310.18px 1151.86px, rgb(240 240 240) 2970.81px 413.036px,
    rgb(240 240 240) 3933.8px 2238.07px, rgb(240 240 240) 394.773px 1552.34px,
    rgb(240 240 240) 1396.27px 917.015px, rgb(240 240 240) 69.4516px 721.858px,
    rgb(240 240 240) 2722.35px 3580.74px, rgb(240 240 240) 1689.91px 2691.07px,
    rgb(240 240 240) 2322.85px 206.181px, rgb(240 240 240) 219.994px 887.547px,
    rgb(240 240 240) 2546.8px 1770.37px, rgb(240 240 240) 3223.19px 3186.78px,
    rgb(240 240 240) 2160.75px 2592.98px, rgb(240 240 240) 3860.31px 2851.46px,
    rgb(240 240 240) 1670.1px 3372.23px, rgb(240 240 240) 860.351px 2544.46px,
    rgb(240 240 240) 1574.01px 2697.9px, rgb(240 240 240) 3813.95px 96.411px,
    rgb(240 240 240) 354.502px 3672.42px, rgb(240 240 240) 527.222px 3819.62px,
    rgb(240 240 240) 800.449px 3441.14px, rgb(240 240 240) 72.0115px 3960.93px,
    rgb(240 240 240) 867.889px 2515.81px, rgb(240 240 240) 1164.18px 2555.22px,
    rgb(240 240 240) 919.533px 2493.72px, rgb(240 240 240) 1172.56px 191.172px,
    rgb(240 240 240) 1646.27px 2721.7px, rgb(240 240 240) 2711.39px 2367.98px,
    rgb(240 240 240) 2440.23px 1402.41px, rgb(240 240 240) 3189.52px 3865.68px,
    rgb(240 240 240) 2636.29px 695.631px, rgb(240 240 240) 3964.05px 1210.57px,
    rgb(240 240 240) 80.8052px 3238.58px, rgb(240 240 240) 2644.09px 395.817px,
    rgb(240 240 240) 2079.66px 2142.46px, rgb(240 240 240) 3544.72px 146.809px,
    rgb(240 240 240) 331.261px 1052.19px, rgb(240 240 240) 1456.91px 2484.98px,
    rgb(240 240 240) 2598.58px 2585.94px, rgb(240 240 240) 1823.86px 3201.79px,
    rgb(240 240 240) 1736.77px 3190.31px, rgb(240 240 240) 1807.68px 2112.85px,
    rgb(240 240 240) 1712.21px 3383.2px, rgb(240 240 240) 1545.37px 2294.44px,
    rgb(240 240 240) 2923.14px 3856.03px, rgb(240 240 240) 2223.47px 3469.16px,
    rgb(240 240 240) 1953.54px 2920.33px, rgb(240 240 240) 2903.55px 2539.88px,
    rgb(240 240 240) 2182.95px 2225.82px, rgb(240 240 240) 3013.53px 1113.13px,
    rgb(240 240 240) 154.921px 2082.67px, rgb(240 240 240) 3848.47px 1284.31px,
    rgb(240 240 240) 3222.23px 3813.36px, rgb(240 240 240) 2051.33px 819.5px,
    rgb(240 240 240) 2180.85px 3555.92px, rgb(240 240 240) 1560.09px 959.439px,
    rgb(240 240 240) 2286.1px 1880.66px, rgb(240 240 240) 2976.83px 3951.9px,
    rgb(240 240 240) 2618.65px 3652.34px, rgb(240 240 240) 259.45px 2697.17px,
    rgb(240 240 240) 3984.77px 470.265px, rgb(240 240 240) 203.438px 1518.78px,
    rgb(240 240 240) 860.815px 3341.81px, rgb(240 240 240) 303.622px 3336.38px,
    rgb(240 240 240) 3236.26px 1865.28px, rgb(240 240 240) 3289.35px 230.866px,
    rgb(240 240 240) 3635.43px 1606.01px, rgb(240 240 240) 1026.68px 3165.61px,
    rgb(240 240 240) 2559.28px 2899.1px, rgb(240 240 240) 2468.49px 2079.08px,
    rgb(240 240 240) 862.176px 1836.01px, rgb(240 240 240) 865.152px 834.964px,
    rgb(240 240 240) 1705.48px 913.169px, rgb(240 240 240) 1257.21px 3701.44px,
    rgb(240 240 240) 836.713px 1182.99px, rgb(240 240 240) 2773.89px 2363.84px,
    rgb(240 240 240) 2931.54px 964.364px, rgb(240 240 240) 2970.27px 2758.8px,
    rgb(240 240 240) 3828.77px 2422.93px, rgb(240 240 240) 3425.25px 3870.34px,
    rgb(240 240 240) 597.506px 2523.49px, rgb(240 240 240) 1364.67px 3154.51px,
    rgb(240 240 240) 620.767px 809.361px, rgb(240 240 240) 2116.09px 2803.19px,
    rgb(240 240 240) 3209.04px 3431.92px, rgb(240 240 240) 585.487px 266.772px,
    rgb(240 240 240) 1283.32px 1590.01px, rgb(240 240 240) 1308.9px 3727.74px,
    rgb(240 240 240) 1136.25px 651.269px, rgb(240 240 240) 1.32729px 160.848px,
    rgb(240 240 240) 1838.75px 638.25px, rgb(240 240 240) 1866.88px 2764.7px,
    rgb(240 240 240) 3443.07px 3477.84px, rgb(240 240 240) 1051.13px 3514.1px,
    rgb(240 240 240) 1577.35px 718.614px, rgb(240 240 240) 1937.71px 1602.48px,
    rgb(240 240 240) 962.603px 3679.55px, rgb(240 240 240) 3536.82px 502.218px,
    rgb(240 240 240) 30.5991px 3595.42px, rgb(240 240 240) 2390.13px 1445.66px,
    rgb(240 240 240) 3754.62px 202.629px, rgb(240 240 240) 3820.2px 3287.74px,
    rgb(240 240 240) 3287.01px 1111.95px, rgb(240 240 240) 3346.63px 3400.84px,
    rgb(240 240 240) 1845.76px 2291.27px, rgb(240 240 240) 1379.78px 2916.57px,
    rgb(240 240 240) 3613.75px 1817.32px, rgb(240 240 240) 2819.33px 3038.45px,
    rgb(240 240 240) 2156.01px 1701.08px, rgb(240 240 240) 586.702px 1084px,
    rgb(240 240 240) 183.733px 2434.48px, rgb(240 240 240) 1495.26px 2583.73px,
    rgb(240 240 240) 1234.7px 1113.43px, rgb(240 240 240) 1759.41px 2711.11px,
    rgb(240 240 240) 2998.07px 3341.17px, rgb(240 240 240) 1142.69px 236.943px,
    rgb(240 240 240) 3766.87px 2471.67px, rgb(240 240 240) 2983.74px 1013.44px,
    rgb(240 240 240) 1322.93px 3099.53px, rgb(240 240 240) 1294.27px 1994.57px,
    rgb(240 240 240) 3987.82px 222.463px, rgb(240 240 240) 3300.81px 3029.8px,
    rgb(240 240 240) 798.326px 2873.87px, rgb(240 240 240) 1899.42px 1315.49px,
    rgb(240 240 240) 1121.95px 3538.08px, rgb(240 240 240) 1078.28px 3858.37px,
    rgb(240 240 240) 3390.95px 814.257px, rgb(240 240 240) 3132.28px 1414.12px,
    rgb(240 240 240) 2712.31px 3644.25px, rgb(240 240 240) 3568.49px 411.966px,
    rgb(240 240 240) 448.492px 585.991px, rgb(240 240 240) 2204.28px 2970.36px,
    rgb(240 240 240) 3034.36px 3249.69px, rgb(240 240 240) 1842.81px 402.225px,
    rgb(240 240 240) 838.272px 3855.23px, rgb(240 240 240) 1336.97px 2043.86px,
    rgb(240 240 240) 3118.06px 1952.73px, rgb(240 240 240) 3771.44px 2073.17px,
    rgb(240 240 240) 529.347px 2488.03px, rgb(240 240 240) 3037.29px 2190.18px,
    rgb(240 240 240) 3849.69px 375.519px, rgb(240 240 240) 3306.86px 3271.55px,
    rgb(240 240 240) 3424.21px 3452.84px, rgb(240 240 240) 932.248px 3687.6px,
    rgb(240 240 240) 2202.19px 2093px, rgb(240 240 240) 918.174px 3591.64px,
    rgb(240 240 240) 3601.25px 2777.93px, rgb(240 240 240) 113.904px 1214.99px,
    rgb(240 240 240) 2265.49px 1548.8px, rgb(240 240 240) 37.8633px 403.754px,
    rgb(240 240 240) 1168.57px 2487.53px, rgb(240 240 240) 1631.5px 1788.91px,
    rgb(240 240 240) 2816.4px 1526.1px, rgb(240 240 240) 1104.69px 1732.95px,
    rgb(240 240 240) 685.226px 1845.8px, rgb(240 240 240) 1222.99px 1361.18px,
    rgb(240 240 240) 1792.74px 1553.39px, rgb(240 240 240) 146.929px 1045.49px,
    rgb(240 240 240) 740.153px 2309.84px, rgb(240 240 240) 3996.12px 1929.25px,
    rgb(240 240 240) 594.545px 3857.07px, rgb(240 240 240) 2839.23px 1838.32px,
    rgb(240 240 240) 2954.03px 3386.09px, rgb(240 240 240) 3159.99px 944.026px,
    rgb(240 240 240) 577.492px 425.89px, rgb(240 240 240) 2982.98px 2602.11px,
    rgb(240 240 240) 2431.62px 3450.74px, rgb(240 240 240) 2785.84px 2316.49px,
    rgb(240 240 240) 784.693px 602.319px, rgb(240 240 240) 2108.05px 321.118px,
    rgb(240 240 240) 3560.56px 657.743px, rgb(240 240 240) 512.871px 550.474px,
    rgb(240 240 240) 1693.66px 3414.74px, rgb(240 240 240) 3092.41px 1443.09px,
    rgb(240 240 240) 686.101px 3584.4px, rgb(240 240 240) 236.034px 1241.39px,
    rgb(240 240 240) 1783.27px 415.69px, rgb(240 240 240) 2290.65px 3825.6px,
    rgb(240 240 240) 3935.69px 2417.9px, rgb(240 240 240) 3636.08px 1641.65px,
    rgb(240 240 240) 2700.99px 3112.89px, rgb(240 240 240) 216.562px 3328.2px,
    rgb(240 240 240) 1026.71px 3421.03px, rgb(240 240 240) 3987.99px 2449.72px,
    rgb(240 240 240) 2649.12px 3904.76px, rgb(240 240 240) 1707.13px 216.043px,
    rgb(240 240 240) 2456.83px 3896.11px, rgb(240 240 240) 490.411px 1313.66px,
    rgb(240 240 240) 714.328px 317.148px, rgb(240 240 240) 165.59px 2062.32px,
    rgb(240 240 240) 2621.12px 1958.97px, rgb(240 240 240) 2024.84px 695.625px,
    rgb(240 240 240) 1498.18px 3365.24px, rgb(240 240 240) 1977.24px 3411.17px,
    rgb(240 240 240) 1983.06px 3541.12px, rgb(240 240 240) 3829.38px 1972.9px,
    rgb(240 240 240) 318.217px 1629.49px, rgb(240 240 240) 2738.7px 116.577px,
    rgb(240 240 240) 578.274px 1973.37px, rgb(240 240 240) 2049.27px 109.992px,
    rgb(240 240 240) 1823.7px 1393.72px, rgb(240 240 240) 3406.49px 626.735px,
    rgb(240 240 240) 3567.13px 2753.33px, rgb(240 240 240) 2219.46px 2871.58px,
    rgb(240 240 240) 54.6047px 3545.36px, rgb(240 240 240) 1379.26px 1437.53px,
    rgb(240 240 240) 1631.78px 699.058px, rgb(240 240 240) 319.542px 1565.49px,
    rgb(240 240 240) 1245.03px 1364.46px, rgb(240 240 240) 1195.76px 2807.91px,
    rgb(240 240 240) 2795.72px 3914.74px, rgb(240 240 240) 3527.3px 3501.3px,
    rgb(240 240 240) 564.379px 3179.27px, rgb(240 240 240) 3343.57px 3544.04px,
    rgb(240 240 240) 2611.28px 3287.6px, rgb(240 240 240) 2407.93px 936.456px,
    rgb(240 240 240) 471.277px 1630.31px, rgb(240 240 240) 500.431px 1373.19px,
    rgb(240 240 240) 2853.39px 3879.89px, rgb(240 240 240) 1882.78px 1821.11px,
    rgb(240 240 240) 465.436px 321.271px, rgb(240 240 240) 3204.71px 1286.67px,
    rgb(240 240 240) 2546.3px 3812.8px, rgb(240 240 240) 3762.66px 2377.64px,
    rgb(240 240 240) 2670.22px 1850.57px, rgb(240 240 240) 1419.1px 301.153px,
    rgb(240 240 240) 2199.43px 3699.5px, rgb(240 240 240) 1812.64px 2304.98px,
    rgb(240 240 240) 3300.64px 3858.3px, rgb(240 240 240) 97.0246px 2128.74px,
    rgb(240 240 240) 2047.47px 680.011px, rgb(240 240 240) 3050.83px 3596.99px,
    rgb(240 240 240) 2500.94px 260.218px, rgb(240 240 240) 978.947px 1600.01px,
    rgb(240 240 240) 3186.02px 2555.75px, rgb(240 240 240) 3692.88px 1427.44px;
  ${({ duration, flowDown }) => css`
    animation: ${duration}s linear 0s infinite normal none running
      ${flowDown ? flowDownAnim : flowUpAnim};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: rgb(240 240 240) 2939.2px 3335.79px,
      rgb(240 240 240) 226.42px 2710.98px, rgb(240 240 240) 1976.59px 2846.26px,
      rgb(240 240 240) 1134.29px 493.15px, rgb(240 240 240) 1397.04px 258.579px,
      rgb(240 240 240) 2626.12px 2853.15px, rgb(240 240 240) 3009.3px 2794.55px,
      rgb(240 240 240) 2521.85px 2802.89px, rgb(240 240 240) 977.34px 901.595px,
      rgb(240 240 240) 2581.54px 2644.35px, rgb(240 240 240) 1438.34px 2871.83px,
      rgb(240 240 240) 3218.46px 1783.64px, rgb(240 240 240) 881.802px 2947.66px,
      rgb(240 240 240) 1261.57px 2504.13px, rgb(240 240 240) 2064.21px 2842.06px,
      rgb(240 240 240) 2901.42px 3582.09px, rgb(240 240 240) 3590.43px 1722.03px,
      rgb(240 240 240) 3154.4px 2186.3px, rgb(240 240 240) 770.419px 3358.23px,
      rgb(240 240 240) 637.309px 588.979px, rgb(240 240 240) 494.19px 2022.97px,
      rgb(240 240 240) 3825.73px 2169.32px, rgb(240 240 240) 1709.52px 3772.47px,
      rgb(240 240 240) 3069.04px 727.636px, rgb(240 240 240) 975.166px 2139.74px,
      rgb(240 240 240) 2150.54px 2275.95px, rgb(240 240 240) 804.751px 794.617px,
      rgb(240 240 240) 3013.03px 3161.77px, rgb(240 240 240) 2962.87px 936.301px,
      rgb(240 240 240) 3519.22px 1898.67px, rgb(240 240 240) 2830.47px 395.754px,
      rgb(240 240 240) 293.076px 611.346px, rgb(240 240 240) 3824.46px 3739.4px,
      rgb(240 240 240) 1595.29px 1055.47px, rgb(240 240 240) 139.355px 1763.74px,
      rgb(240 240 240) 1666.33px 1496.42px, rgb(240 240 240) 3291.24px 2888.99px,
      rgb(240 240 240) 1538.82px 3653.9px, rgb(240 240 240) 590.72px 748.522px,
      rgb(240 240 240) 930.302px 3786.88px, rgb(240 240 240) 2067.96px 3475.94px,
      rgb(240 240 240) 2306.38px 3944.26px, rgb(240 240 240) 1925.08px 568.56px,
      rgb(240 240 240) 2734.51px 2389.29px, rgb(240 240 240) 2320.89px 3808.25px,
      rgb(240 240 240) 910.145px 1087.88px, rgb(240 240 240) 3520.07px 2579.38px,
      rgb(240 240 240) 3170.94px 3807.6px, rgb(240 240 240) 601.459px 2334.07px,
      rgb(240 240 240) 2453.29px 3386.43px, rgb(240 240 240) 3889.97px 870.814px,
      rgb(240 240 240) 2892.77px 3497.81px, rgb(240 240 240) 6.4142px 3698.27px,
      rgb(240 240 240) 832.488px 3579.41px, rgb(240 240 240) 2932.45px 685.111px,
      rgb(240 240 240) 678.971px 1424.77px, rgb(240 240 240) 3437.13px 1238.44px,
      rgb(240 240 240) 2032.62px 2735.15px, rgb(240 240 240) 2955.97px 3764.54px,
      rgb(240 240 240) 3288.07px 3421.94px, rgb(240 240 240) 639.272px 1805.38px,
      rgb(240 240 240) 1910.41px 2159.9px, rgb(240 240 240) 2144.43px 3049.69px,
      rgb(240 240 240) 3158.6px 3065.47px, rgb(240 240 240) 1373.94px 2048.04px,
      rgb(240 240 240) 3437.76px 3172.22px, rgb(240 240 240) 1929.83px 107.925px,
      rgb(240 240 240) 3731.08px 2419.73px, rgb(240 240 240) 2119.02px 395.926px,
      rgb(240 240 240) 3710.29px 2097.82px, rgb(240 240 240) 300.237px 251.799px,
      rgb(240 240 240) 2785.84px 2944.91px, rgb(240 240 240) 1899.33px 3625.9px,
      rgb(240 240 240) 1510.84px 1640.81px, rgb(240 240 240) 3400.1px 2787.36px,
      rgb(240 240 240) 3062.95px 679.696px, rgb(240 240 240) 1525.84px 1738.06px,
      rgb(240 240 240) 1604.66px 405.728px, rgb(240 240 240) 617.602px 2606.75px,
      rgb(240 240 240) 2739.09px 2981.46px, rgb(240 240 240) 1055.65px 2085.33px,
      rgb(240 240 240) 3435.24px 3476.11px, rgb(240 240 240) 800.945px 3698.96px,
      rgb(240 240 240) 1077.19px 891.277px, rgb(240 240 240) 343.731px 2036px,
      rgb(240 240 240) 305.877px 1257.19px, rgb(240 240 240) 3631.34px 2985.74px,
      rgb(240 240 240) 3500.66px 2924.8px, rgb(240 240 240) 3818.53px 3128.25px,
      rgb(240 240 240) 3210.31px 1490.48px, rgb(240 240 240) 3022.06px 1068.92px,
      rgb(240 240 240) 2449.46px 693.407px, rgb(240 240 240) 2019.63px 921.271px,
      rgb(240 240 240) 2147.75px 3514.68px, rgb(240 240 240) 2646.12px 980.774px,
      rgb(240 240 240) 2067.4px 3718.12px, rgb(240 240 240) 1959.47px 274.615px,
      rgb(240 240 240) 752.239px 3940.03px, rgb(240 240 240) 2844.93px 1572.43px,
      rgb(240 240 240) 3034.8px 2885.24px, rgb(240 240 240) 941.117px 2646.55px,
      rgb(240 240 240) 2653.67px 1057.29px, rgb(240 240 240) 3938.79px 469.474px,
      rgb(240 240 240) 1477.91px 376.495px, rgb(240 240 240) 870.905px 1550.71px,
      rgb(240 240 240) 2022.83px 3159.53px, rgb(240 240 240) 780.477px 723.629px,
      rgb(240 240 240) 3847.48px 3604.31px, rgb(240 240 240) 2266.18px 2903.25px,
      rgb(240 240 240) 21.0702px 1712.54px, rgb(240 240 240) 930.477px 508.43px,
      rgb(240 240 240) 2327.45px 2104.88px, rgb(240 240 240) 1654.81px 53.1865px,
      rgb(240 240 240) 3134.07px 1059.71px, rgb(240 240 240) 775.805px 1546.48px,
      rgb(240 240 240) 2153.69px 3779.49px, rgb(240 240 240) 1543.76px 2190.63px,
      rgb(240 240 240) 2222.88px 3167.49px, rgb(240 240 240) 1100px 1275.73px,
      rgb(240 240 240) 3397.22px 2704.81px, rgb(240 240 240) 2082.79px 135.203px,
      rgb(240 240 240) 4000.95px 1406.95px, rgb(240 240 240) 3733.08px 2982.83px,
      rgb(240 240 240) 439.65px 657.091px, rgb(240 240 240) 2576.56px 3584.02px,
      rgb(240 240 240) 2645.06px 556.868px, rgb(240 240 240) 3409.33px 3949.49px,
      rgb(240 240 240) 1418.68px 2143.69px, rgb(240 240 240) 420.868px 1071.11px,
      rgb(240 240 240) 1775.56px 1235.86px, rgb(240 240 240) 1927.19px 3869.99px,
      rgb(240 240 240) 1199.34px 2563.16px, rgb(240 240 240) 3209.94px 3241.84px,
      rgb(240 240 240) 1809.9px 199.804px, rgb(240 240 240) 3356.4px 2081.29px,
      rgb(240 240 240) 2899.27px 1910.54px, rgb(240 240 240) 3459.16px 137.533px,
      rgb(240 240 240) 2662.18px 1475.72px, rgb(240 240 240) 2271.82px 1946.05px,
      rgb(240 240 240) 3882.25px 298.53px, rgb(240 240 240) 1157.48px 3989.77px,
      rgb(240 240 240) 3586.19px 1446.08px, rgb(240 240 240) 1854.89px 2937.47px,
      rgb(240 240 240) 1936.23px 304.532px, rgb(240 240 240) 325.324px 2634.09px,
      rgb(240 240 240) 3083.59px 3535.09px, rgb(240 240 240) 644.263px 1485.79px,
      rgb(240 240 240) 2576.58px 3153.95px, rgb(240 240 240) 1123.92px 3301.95px,
      rgb(240 240 240) 1029.04px 2214.57px, rgb(240 240 240) 3496.08px 2642.8px,
      rgb(240 240 240) 2642.86px 2853.2px, rgb(240 240 240) 1799.14px 272.804px,
      rgb(240 240 240) 2949.56px 3079.46px, rgb(240 240 240) 3190.81px 2283.34px,
      rgb(240 240 240) 1125.47px 326.972px, rgb(240 240 240) 1091.82px 3700.16px,
      rgb(240 240 240) 1651.95px 1704.17px, rgb(240 240 240) 3078.31px 3883.73px,
      rgb(240 240 240) 3967.19px 2924.79px, rgb(240 240 240) 2186.6px 3912.12px,
      rgb(240 240 240) 3409.28px 1169.68px, rgb(240 240 240) 2659px 607.752px,
      rgb(240 240 240) 3334.07px 1791.64px, rgb(240 240 240) 3432.22px 2426.29px,
      rgb(240 240 240) 2791.9px 3776.49px, rgb(240 240 240) 2893.85px 343.944px,
      rgb(240 240 240) 3087.68px 3754.68px, rgb(240 240 240) 3097.76px 178.65px,
      rgb(240 240 240) 273.214px 3914.41px, rgb(240 240 240) 1919.99px 849.316px,
      rgb(240 240 240) 3271.46px 554.825px, rgb(240 240 240) 3697.24px 1856.55px,
      rgb(240 240 240) 3674.15px 2081.03px, rgb(240 240 240) 823.997px 952.979px,
      rgb(240 240 240) 140.436px 2446.42px, rgb(240 240 240) 3933.57px 843.035px,
      rgb(240 240 240) 1041.49px 2312.39px, rgb(240 240 240) 3111.36px 22.0425px,
      rgb(240 240 240) 3973.49px 2504.49px, rgb(240 240 240) 1815.88px 417.661px,
      rgb(240 240 240) 1302.44px 2591.27px, rgb(240 240 240) 1819.59px 3455.2px,
      rgb(240 240 240) 490.039px 459.28px, rgb(240 240 240) 2179.65px 1586.01px,
      rgb(240 240 240) 383.75px 1761.23px, rgb(240 240 240) 79.5877px 3541.12px,
      rgb(240 240 240) 3034.19px 3573.18px, rgb(240 240 240) 904.145px 1464.95px,
      rgb(240 240 240) 365.149px 3014.48px, rgb(240 240 240) 339.898px 1484.81px,
      rgb(240 240 240) 2541.54px 3622.4px, rgb(240 240 240) 3471.27px 877.049px,
      rgb(240 240 240) 3243.55px 3430.25px, rgb(240 240 240) 3238.66px 2268.57px,
      rgb(240 240 240) 124.277px 2065.6px, rgb(240 240 240) 2971.13px 2264.47px,
      rgb(240 240 240) 3693.53px 3640.56px, rgb(240 240 240) 1284.92px 3568.08px,
      rgb(240 240 240) 3638.71px 836.07px, rgb(240 240 240) 3508px 3118.21px,
      rgb(240 240 240) 1312.52px 1960.34px, rgb(240 240 240) 3239.85px 3480.15px,
      rgb(240 240 240) 2017.61px 1325.1px, rgb(240 240 240) 893.078px 2046.51px,
      rgb(240 240 240) 3798.9px 1900.26px, rgb(240 240 240) 2947.22px 1396.91px,
      rgb(240 240 240) 3785.58px 1132.42px, rgb(240 240 240) 2101.65px 3625.78px,
      rgb(240 240 240) 2521.47px 2331.52px, rgb(240 240 240) 1574.76px 794.502px,
      rgb(240 240 240) 2277.18px 476.463px, rgb(240 240 240) 975.758px 2835.43px,
      rgb(240 240 240) 3501.96px 1368.82px, rgb(240 240 240) 234.985px 1073.45px,
      rgb(240 240 240) 484.409px 567.287px, rgb(240 240 240) 1403.69px 278.054px,
      rgb(240 240 240) 3014.91px 2728.42px, rgb(240 240 240) 718.348px 160.508px,
      rgb(240 240 240) 2394.32px 2970.86px, rgb(240 240 240) 3662.51px 1559.29px,
      rgb(240 240 240) 2718.01px 1658.91px, rgb(240 240 240) 1291.04px 2201.43px,
      rgb(240 240 240) 1301.15px 3861.57px, rgb(240 240 240) 1007.3px 1053.13px,
      rgb(240 240 240) 109.252px 2373.74px, rgb(240 240 240) 2554.72px 3900.72px,
      rgb(240 240 240) 1219.34px 422.332px, rgb(240 240 240) 3546.42px 436.909px,
      rgb(240 240 240) 2946.6px 2897.4px, rgb(240 240 240) 929.161px 3621.12px,
      rgb(240 240 240) 3693.77px 1637.04px, rgb(240 240 240) 37.8273px 2114.8px,
      rgb(240 240 240) 802.164px 144.281px, rgb(240 240 240) 3470.29px 3072.07px,
      rgb(240 240 240) 1110.21px 3697.92px, rgb(240 240 240) 32.8983px 1627.08px,
      rgb(240 240 240) 225.358px 1434.44px, rgb(240 240 240) 11.2975px 3324.77px,
      rgb(240 240 240) 1065.01px 2548.48px, rgb(240 240 240) 2191.15px 880.412px,
      rgb(240 240 240) 421.495px 3542.94px, rgb(240 240 240) 3188.27px 1764.93px,
      rgb(240 240 240) 534.76px 231.077px, rgb(240 240 240) 2404.24px 3187.99px,
      rgb(240 240 240) 3810.49px 846.836px, rgb(240 240 240) 1746.54px 1890.32px,
      rgb(240 240 240) 958.9px 880.33px, rgb(240 240 240) 3801.1px 3278.47px,
      rgb(240 240 240) 3270.99px 28.42px, rgb(240 240 240) 2983.52px 430.291px,
      rgb(240 240 240) 1624.25px 1180.38px, rgb(240 240 240) 3058.4px 2657.47px,
      rgb(240 240 240) 3663.56px 674.321px, rgb(240 240 240) 3959.89px 3746.95px,
      rgb(240 240 240) 1735.32px 3654.24px, rgb(240 240 240) 3866.15px 1363.04px,
      rgb(240 240 240) 1560.25px 408.354px, rgb(240 240 240) 309.884px 2959.93px,
      rgb(240 240 240) 3314.98px 2071.46px, rgb(240 240 240) 93.3072px 2272.98px,
      rgb(240 240 240) 257.671px 1723.83px, rgb(240 240 240) 1078.5px 1525.88px,
      rgb(240 240 240) 1310.18px 1151.86px, rgb(240 240 240) 2970.81px 413.036px,
      rgb(240 240 240) 3933.8px 2238.07px, rgb(240 240 240) 394.773px 1552.34px,
      rgb(240 240 240) 1396.27px 917.015px, rgb(240 240 240) 69.4516px 721.858px,
      rgb(240 240 240) 2722.35px 3580.74px, rgb(240 240 240) 1689.91px 2691.07px,
      rgb(240 240 240) 2322.85px 206.181px, rgb(240 240 240) 219.994px 887.547px,
      rgb(240 240 240) 2546.8px 1770.37px, rgb(240 240 240) 3223.19px 3186.78px,
      rgb(240 240 240) 2160.75px 2592.98px, rgb(240 240 240) 3860.31px 2851.46px,
      rgb(240 240 240) 1670.1px 3372.23px, rgb(240 240 240) 860.351px 2544.46px,
      rgb(240 240 240) 1574.01px 2697.9px, rgb(240 240 240) 3813.95px 96.411px,
      rgb(240 240 240) 354.502px 3672.42px, rgb(240 240 240) 527.222px 3819.62px,
      rgb(240 240 240) 800.449px 3441.14px, rgb(240 240 240) 72.0115px 3960.93px,
      rgb(240 240 240) 867.889px 2515.81px, rgb(240 240 240) 1164.18px 2555.22px,
      rgb(240 240 240) 919.533px 2493.72px, rgb(240 240 240) 1172.56px 191.172px,
      rgb(240 240 240) 1646.27px 2721.7px, rgb(240 240 240) 2711.39px 2367.98px,
      rgb(240 240 240) 2440.23px 1402.41px, rgb(240 240 240) 3189.52px 3865.68px,
      rgb(240 240 240) 2636.29px 695.631px, rgb(240 240 240) 3964.05px 1210.57px,
      rgb(240 240 240) 80.8052px 3238.58px, rgb(240 240 240) 2644.09px 395.817px,
      rgb(240 240 240) 2079.66px 2142.46px, rgb(240 240 240) 3544.72px 146.809px,
      rgb(240 240 240) 331.261px 1052.19px, rgb(240 240 240) 1456.91px 2484.98px,
      rgb(240 240 240) 2598.58px 2585.94px, rgb(240 240 240) 1823.86px 3201.79px,
      rgb(240 240 240) 1736.77px 3190.31px, rgb(240 240 240) 1807.68px 2112.85px,
      rgb(240 240 240) 1712.21px 3383.2px, rgb(240 240 240) 1545.37px 2294.44px,
      rgb(240 240 240) 2923.14px 3856.03px, rgb(240 240 240) 2223.47px 3469.16px,
      rgb(240 240 240) 1953.54px 2920.33px, rgb(240 240 240) 2903.55px 2539.88px,
      rgb(240 240 240) 2182.95px 2225.82px, rgb(240 240 240) 3013.53px 1113.13px,
      rgb(240 240 240) 154.921px 2082.67px, rgb(240 240 240) 3848.47px 1284.31px,
      rgb(240 240 240) 3222.23px 3813.36px, rgb(240 240 240) 2051.33px 819.5px,
      rgb(240 240 240) 2180.85px 3555.92px, rgb(240 240 240) 1560.09px 959.439px,
      rgb(240 240 240) 2286.1px 1880.66px, rgb(240 240 240) 2976.83px 3951.9px,
      rgb(240 240 240) 2618.65px 3652.34px, rgb(240 240 240) 259.45px 2697.17px,
      rgb(240 240 240) 3984.77px 470.265px, rgb(240 240 240) 203.438px 1518.78px,
      rgb(240 240 240) 860.815px 3341.81px, rgb(240 240 240) 303.622px 3336.38px,
      rgb(240 240 240) 3236.26px 1865.28px, rgb(240 240 240) 3289.35px 230.866px,
      rgb(240 240 240) 3635.43px 1606.01px, rgb(240 240 240) 1026.68px 3165.61px,
      rgb(240 240 240) 2559.28px 2899.1px, rgb(240 240 240) 2468.49px 2079.08px,
      rgb(240 240 240) 862.176px 1836.01px, rgb(240 240 240) 865.152px 834.964px,
      rgb(240 240 240) 1705.48px 913.169px, rgb(240 240 240) 1257.21px 3701.44px,
      rgb(240 240 240) 836.713px 1182.99px, rgb(240 240 240) 2773.89px 2363.84px,
      rgb(240 240 240) 2931.54px 964.364px, rgb(240 240 240) 2970.27px 2758.8px,
      rgb(240 240 240) 3828.77px 2422.93px, rgb(240 240 240) 3425.25px 3870.34px,
      rgb(240 240 240) 597.506px 2523.49px, rgb(240 240 240) 1364.67px 3154.51px,
      rgb(240 240 240) 620.767px 809.361px, rgb(240 240 240) 2116.09px 2803.19px,
      rgb(240 240 240) 3209.04px 3431.92px, rgb(240 240 240) 585.487px 266.772px,
      rgb(240 240 240) 1283.32px 1590.01px, rgb(240 240 240) 1308.9px 3727.74px,
      rgb(240 240 240) 1136.25px 651.269px, rgb(240 240 240) 1.32729px 160.848px,
      rgb(240 240 240) 1838.75px 638.25px, rgb(240 240 240) 1866.88px 2764.7px,
      rgb(240 240 240) 3443.07px 3477.84px, rgb(240 240 240) 1051.13px 3514.1px,
      rgb(240 240 240) 1577.35px 718.614px, rgb(240 240 240) 1937.71px 1602.48px,
      rgb(240 240 240) 962.603px 3679.55px, rgb(240 240 240) 3536.82px 502.218px,
      rgb(240 240 240) 30.5991px 3595.42px, rgb(240 240 240) 2390.13px 1445.66px,
      rgb(240 240 240) 3754.62px 202.629px, rgb(240 240 240) 3820.2px 3287.74px,
      rgb(240 240 240) 3287.01px 1111.95px, rgb(240 240 240) 3346.63px 3400.84px,
      rgb(240 240 240) 1845.76px 2291.27px, rgb(240 240 240) 1379.78px 2916.57px,
      rgb(240 240 240) 3613.75px 1817.32px, rgb(240 240 240) 2819.33px 3038.45px,
      rgb(240 240 240) 2156.01px 1701.08px, rgb(240 240 240) 586.702px 1084px,
      rgb(240 240 240) 183.733px 2434.48px, rgb(240 240 240) 1495.26px 2583.73px,
      rgb(240 240 240) 1234.7px 1113.43px, rgb(240 240 240) 1759.41px 2711.11px,
      rgb(240 240 240) 2998.07px 3341.17px, rgb(240 240 240) 1142.69px 236.943px,
      rgb(240 240 240) 3766.87px 2471.67px, rgb(240 240 240) 2983.74px 1013.44px,
      rgb(240 240 240) 1322.93px 3099.53px, rgb(240 240 240) 1294.27px 1994.57px,
      rgb(240 240 240) 3987.82px 222.463px, rgb(240 240 240) 3300.81px 3029.8px,
      rgb(240 240 240) 798.326px 2873.87px, rgb(240 240 240) 1899.42px 1315.49px,
      rgb(240 240 240) 1121.95px 3538.08px, rgb(240 240 240) 1078.28px 3858.37px,
      rgb(240 240 240) 3390.95px 814.257px, rgb(240 240 240) 3132.28px 1414.12px,
      rgb(240 240 240) 2712.31px 3644.25px, rgb(240 240 240) 3568.49px 411.966px,
      rgb(240 240 240) 448.492px 585.991px, rgb(240 240 240) 2204.28px 2970.36px,
      rgb(240 240 240) 3034.36px 3249.69px, rgb(240 240 240) 1842.81px 402.225px,
      rgb(240 240 240) 838.272px 3855.23px, rgb(240 240 240) 1336.97px 2043.86px,
      rgb(240 240 240) 3118.06px 1952.73px, rgb(240 240 240) 3771.44px 2073.17px,
      rgb(240 240 240) 529.347px 2488.03px, rgb(240 240 240) 3037.29px 2190.18px,
      rgb(240 240 240) 3849.69px 375.519px, rgb(240 240 240) 3306.86px 3271.55px,
      rgb(240 240 240) 3424.21px 3452.84px, rgb(240 240 240) 932.248px 3687.6px,
      rgb(240 240 240) 2202.19px 2093px, rgb(240 240 240) 918.174px 3591.64px,
      rgb(240 240 240) 3601.25px 2777.93px, rgb(240 240 240) 113.904px 1214.99px,
      rgb(240 240 240) 2265.49px 1548.8px, rgb(240 240 240) 37.8633px 403.754px,
      rgb(240 240 240) 1168.57px 2487.53px, rgb(240 240 240) 1631.5px 1788.91px,
      rgb(240 240 240) 2816.4px 1526.1px, rgb(240 240 240) 1104.69px 1732.95px,
      rgb(240 240 240) 685.226px 1845.8px, rgb(240 240 240) 1222.99px 1361.18px,
      rgb(240 240 240) 1792.74px 1553.39px, rgb(240 240 240) 146.929px 1045.49px,
      rgb(240 240 240) 740.153px 2309.84px, rgb(240 240 240) 3996.12px 1929.25px,
      rgb(240 240 240) 594.545px 3857.07px, rgb(240 240 240) 2839.23px 1838.32px,
      rgb(240 240 240) 2954.03px 3386.09px, rgb(240 240 240) 3159.99px 944.026px,
      rgb(240 240 240) 577.492px 425.89px, rgb(240 240 240) 2982.98px 2602.11px,
      rgb(240 240 240) 2431.62px 3450.74px, rgb(240 240 240) 2785.84px 2316.49px,
      rgb(240 240 240) 784.693px 602.319px, rgb(240 240 240) 2108.05px 321.118px,
      rgb(240 240 240) 3560.56px 657.743px, rgb(240 240 240) 512.871px 550.474px,
      rgb(240 240 240) 1693.66px 3414.74px, rgb(240 240 240) 3092.41px 1443.09px,
      rgb(240 240 240) 686.101px 3584.4px, rgb(240 240 240) 236.034px 1241.39px,
      rgb(240 240 240) 1783.27px 415.69px, rgb(240 240 240) 2290.65px 3825.6px,
      rgb(240 240 240) 3935.69px 2417.9px, rgb(240 240 240) 3636.08px 1641.65px,
      rgb(240 240 240) 2700.99px 3112.89px, rgb(240 240 240) 216.562px 3328.2px,
      rgb(240 240 240) 1026.71px 3421.03px, rgb(240 240 240) 3987.99px 2449.72px,
      rgb(240 240 240) 2649.12px 3904.76px, rgb(240 240 240) 1707.13px 216.043px,
      rgb(240 240 240) 2456.83px 3896.11px, rgb(240 240 240) 490.411px 1313.66px,
      rgb(240 240 240) 714.328px 317.148px, rgb(240 240 240) 165.59px 2062.32px,
      rgb(240 240 240) 2621.12px 1958.97px, rgb(240 240 240) 2024.84px 695.625px,
      rgb(240 240 240) 1498.18px 3365.24px, rgb(240 240 240) 1977.24px 3411.17px,
      rgb(240 240 240) 1983.06px 3541.12px, rgb(240 240 240) 3829.38px 1972.9px,
      rgb(240 240 240) 318.217px 1629.49px, rgb(240 240 240) 2738.7px 116.577px,
      rgb(240 240 240) 578.274px 1973.37px, rgb(240 240 240) 2049.27px 109.992px,
      rgb(240 240 240) 1823.7px 1393.72px, rgb(240 240 240) 3406.49px 626.735px,
      rgb(240 240 240) 3567.13px 2753.33px, rgb(240 240 240) 2219.46px 2871.58px,
      rgb(240 240 240) 54.6047px 3545.36px, rgb(240 240 240) 1379.26px 1437.53px,
      rgb(240 240 240) 1631.78px 699.058px, rgb(240 240 240) 319.542px 1565.49px,
      rgb(240 240 240) 1245.03px 1364.46px, rgb(240 240 240) 1195.76px 2807.91px,
      rgb(240 240 240) 2795.72px 3914.74px, rgb(240 240 240) 3527.3px 3501.3px,
      rgb(240 240 240) 564.379px 3179.27px, rgb(240 240 240) 3343.57px 3544.04px,
      rgb(240 240 240) 2611.28px 3287.6px, rgb(240 240 240) 2407.93px 936.456px,
      rgb(240 240 240) 471.277px 1630.31px, rgb(240 240 240) 500.431px 1373.19px,
      rgb(240 240 240) 2853.39px 3879.89px, rgb(240 240 240) 1882.78px 1821.11px,
      rgb(240 240 240) 465.436px 321.271px, rgb(240 240 240) 3204.71px 1286.67px,
      rgb(240 240 240) 2546.3px 3812.8px, rgb(240 240 240) 3762.66px 2377.64px,
      rgb(240 240 240) 2670.22px 1850.57px, rgb(240 240 240) 1419.1px 301.153px,
      rgb(240 240 240) 2199.43px 3699.5px, rgb(240 240 240) 1812.64px 2304.98px,
      rgb(240 240 240) 3300.64px 3858.3px, rgb(240 240 240) 97.0246px 2128.74px,
      rgb(240 240 240) 2047.47px 680.011px, rgb(240 240 240) 3050.83px 3596.99px,
      rgb(240 240 240) 2500.94px 260.218px, rgb(240 240 240) 978.947px 1600.01px,
      rgb(240 240 240) 3186.02px 2555.75px, rgb(240 240 240) 3692.88px 1427.44px;
  }
`;

export default StarContainerLg;
