import styled, { css } from 'styled-components';
import { flowDownAnim, flowUpAnim } from './starsKeyframes';

const StarContainerSm = styled.div<{ duration: number; flowDown: boolean }>`
  position: relative;
  top: -300px;
  left: -50vw;
  z-index: -1;
  will-change: transform;
  width: 0.5px;
  height: 0.5px;
  background: transparent;
  box-shadow: rgb(240 240 240) 2587.12px 1295.03px,
    rgb(240 240 240) 3501.95px 3818.53px, rgb(240 240 240) 827.149px 1631.98px,
    rgb(240 240 240) 2232.54px 3981px, rgb(240 240 240) 2175.85px 1787.49px,
    rgb(240 240 240) 3260.2px 2155.74px, rgb(240 240 240) 1393.79px 1988.9px,
    rgb(240 240 240) 3310.92px 799.93px, rgb(240 240 240) 1215.03px 3259.48px,
    rgb(240 240 240) 3532.21px 2830.04px, rgb(240 240 240) 662.699px 3616.84px,
    rgb(240 240 240) 3378.12px 1767.32px, rgb(240 240 240) 856.393px 2998.29px,
    rgb(240 240 240) 688.552px 3342.09px, rgb(240 240 240) 3518.82px 3419px,
    rgb(240 240 240) 1604.08px 31.0397px, rgb(240 240 240) 3027.51px 1498.39px,
    rgb(240 240 240) 2154.63px 3457.04px, rgb(240 240 240) 2614.16px 3228.02px,
    rgb(240 240 240) 2555.23px 3843.45px, rgb(240 240 240) 2154.7px 1298.49px,
    rgb(240 240 240) 2303.48px 1361.85px, rgb(240 240 240) 297.961px 1798.69px,
    rgb(240 240 240) 2095.87px 1809.52px, rgb(240 240 240) 1354.03px 2211.66px,
    rgb(240 240 240) 578.475px 3124.76px, rgb(240 240 240) 3131.18px 2455.53px,
    rgb(240 240 240) 635.547px 530.443px, rgb(240 240 240) 2251.87px 2259.17px,
    rgb(240 240 240) 1422.62px 3700.57px, rgb(240 240 240) 1775.96px 3595.51px,
    rgb(240 240 240) 3967.99px 1358.11px, rgb(240 240 240) 3772.25px 1442.7px,
    rgb(240 240 240) 1745.64px 1088.1px, rgb(240 240 240) 1219.09px 154.521px,
    rgb(240 240 240) 1669.46px 3668.07px, rgb(240 240 240) 2287.57px 3098.09px,
    rgb(240 240 240) 2058.34px 3489.25px, rgb(240 240 240) 704.208px 3929.98px,
    rgb(240 240 240) 3495.92px 3523.91px, rgb(240 240 240) 329.549px 3198.46px,
    rgb(240 240 240) 93.7419px 1882.17px, rgb(240 240 240) 2739px 99.7405px,
    rgb(240 240 240) 401.464px 2457.04px, rgb(240 240 240) 55.2605px 2896.48px,
    rgb(240 240 240) 1184.98px 1302.53px, rgb(240 240 240) 307.678px 1806.6px,
    rgb(240 240 240) 542.553px 1849.28px, rgb(240 240 240) 3549.79px 183.161px,
    rgb(240 240 240) 1430.02px 2132.6px, rgb(240 240 240) 3552.48px 1559.27px,
    rgb(240 240 240) 3585.29px 1985.19px, rgb(240 240 240) 548.568px 1297.8px,
    rgb(240 240 240) 1517.73px 1009.59px, rgb(240 240 240) 1607.2px 3383.87px,
    rgb(240 240 240) 2608.52px 232.004px, rgb(240 240 240) 1772.98px 2872.69px,
    rgb(240 240 240) 2107.16px 261.023px, rgb(240 240 240) 378.502px 201.457px,
    rgb(240 240 240) 3368.66px 1937.17px, rgb(240 240 240) 543.664px 3882.65px,
    rgb(240 240 240) 3191.86px 1824.74px, rgb(240 240 240) 3227.66px 1771.02px,
    rgb(240 240 240) 234.45px 2388.45px, rgb(240 240 240) 381.646px 3256.85px,
    rgb(240 240 240) 3782.99px 1583.1px, rgb(240 240 240) 970.477px 2770.75px,
    rgb(240 240 240) 1354.37px 2921.67px, rgb(240 240 240) 847.631px 2479.91px,
    rgb(240 240 240) 731.178px 2410.64px, rgb(240 240 240) 389.563px 1690.46px,
    rgb(240 240 240) 1987.26px 44.7961px, rgb(240 240 240) 419.926px 3806.22px,
    rgb(240 240 240) 1740.96px 183.591px, rgb(240 240 240) 2917.11px 3942.51px,
    rgb(240 240 240) 1954.79px 1545.58px, rgb(240 240 240) 1621.78px 2482.56px,
    rgb(240 240 240) 3933.57px 2697.29px, rgb(240 240 240) 1906.86px 2373.44px,
    rgb(240 240 240) 3047.78px 1194.84px, rgb(240 240 240) 2285.37px 2951.62px,
    rgb(240 240 240) 895.091px 1248.33px, rgb(240 240 240) 3127.68px 3858.93px,
    rgb(240 240 240) 3953.15px 2786.17px, rgb(240 240 240) 2102.03px 3988.91px,
    rgb(240 240 240) 3053.97px 1858.08px, rgb(240 240 240) 2702.52px 2586.83px,
    rgb(240 240 240) 1490.95px 2039.37px, rgb(240 240 240) 3385.98px 3134.46px,
    rgb(240 240 240) 360.605px 1978.43px, rgb(240 240 240) 3544.83px 3459.78px,
    rgb(240 240 240) 1776.35px 1920.05px, rgb(240 240 240) 852.834px 1759.67px,
    rgb(240 240 240) 2835.27px 2626.62px, rgb(240 240 240) 399.676px 858.102px,
    rgb(240 240 240) 1159.07px 413.734px, rgb(240 240 240) 3948.24px 1375.9px,
    rgb(240 240 240) 678.209px 2167.19px, rgb(240 240 240) 3099.3px 2993.39px,
    rgb(240 240 240) 3448.96px 2042.94px, rgb(240 240 240) 3703.17px 3192.84px,
    rgb(240 240 240) 3014.28px 2007.1px, rgb(240 240 240) 2332.91px 1312.87px,
    rgb(240 240 240) 3744.28px 3138.14px, rgb(240 240 240) 1230.02px 243.438px,
    rgb(240 240 240) 924.305px 3619.93px, rgb(240 240 240) 2663.26px 2137.25px,
    rgb(240 240 240) 222.652px 985.116px, rgb(240 240 240) 3378.23px 632.987px,
    rgb(240 240 240) 2475.16px 3397.4px, rgb(240 240 240) 159.109px 1654.23px,
    rgb(240 240 240) 2211.45px 424.692px, rgb(240 240 240) 2322.8px 298.991px,
    rgb(240 240 240) 3357.63px 2458.47px, rgb(240 240 240) 2717.75px 1417.4px,
    rgb(240 240 240) 1575.96px 1203.68px, rgb(240 240 240) 3783.66px 130.722px,
    rgb(240 240 240) 2304.12px 1770.2px, rgb(240 240 240) 306.195px 2193.98px,
    rgb(240 240 240) 1877.7px 2852.21px, rgb(240 240 240) 800.945px 2309.89px,
    rgb(240 240 240) 634.125px 1792.25px, rgb(240 240 240) 1870.76px 1925.05px,
    rgb(240 240 240) 178.982px 3622.84px, rgb(240 240 240) 2456.18px 3503.43px,
    rgb(240 240 240) 1749.38px 3812.98px, rgb(240 240 240) 2077.1px 1191.94px,
    rgb(240 240 240) 386.31px 2188.85px, rgb(240 240 240) 2956.78px 1100.09px,
    rgb(240 240 240) 1235.6px 454.292px, rgb(240 240 240) 3866.43px 2708.78px,
    rgb(240 240 240) 3299.32px 3246.79px, rgb(240 240 240) 1889.84px 531.592px,
    rgb(240 240 240) 1121.39px 3680.7px, rgb(240 240 240) 2196.29px 1042.66px,
    rgb(240 240 240) 1473.7px 3208.72px, rgb(240 240 240) 1290.91px 2847.86px,
    rgb(240 240 240) 2711.15px 1155.01px, rgb(240 240 240) 1112.44px 1556.85px,
    rgb(240 240 240) 1975.08px 3868.63px, rgb(240 240 240) 2218.77px 3461.84px,
    rgb(240 240 240) 2461.99px 3660.05px, rgb(240 240 240) 3976.6px 1864.19px,
    rgb(240 240 240) 328.536px 702.639px, rgb(240 240 240) 834.304px 131.84px,
    rgb(240 240 240) 1380.38px 626.041px, rgb(240 240 240) 2188.78px 3179.88px,
    rgb(240 240 240) 3546.73px 863.026px, rgb(240 240 240) 1360.75px 3973.73px,
    rgb(240 240 240) 1652.05px 2028.5px, rgb(240 240 240) 1582.43px 3665.62px,
    rgb(240 240 240) 2004.63px 1368.67px, rgb(240 240 240) 2084.5px 1267.85px,
    rgb(240 240 240) 2095.68px 1216.21px, rgb(240 240 240) 760.843px 2871.35px,
    rgb(240 240 240) 2478.28px 2668.52px, rgb(240 240 240) 261.767px 2450.97px,
    rgb(240 240 240) 3271.76px 3086.3px, rgb(240 240 240) 1963.74px 3958.06px,
    rgb(240 240 240) 3474.56px 1660.02px, rgb(240 240 240) 3362.78px 1331.15px,
    rgb(240 240 240) 3348.65px 327.45px, rgb(240 240 240) 47.1882px 2467.56px,
    rgb(240 240 240) 635.659px 651.27px, rgb(240 240 240) 1405.58px 526.146px,
    rgb(240 240 240) 2717.78px 2231.82px, rgb(240 240 240) 2990.51px 1577.78px,
    rgb(240 240 240) 3791.92px 398.939px, rgb(240 240 240) 1611.33px 2428.84px,
    rgb(240 240 240) 105.899px 850.983px, rgb(240 240 240) 488.574px 380.325px,
    rgb(240 240 240) 3422.92px 2024.13px, rgb(240 240 240) 574.381px 957.016px,
    rgb(240 240 240) 1550.69px 128.773px, rgb(240 240 240) 3893.81px 402.818px,
    rgb(240 240 240) 2665.79px 3832.03px, rgb(240 240 240) 2166.26px 2601.12px,
    rgb(240 240 240) 986.47px 1044.96px, rgb(240 240 240) 1790.33px 2019.83px,
    rgb(240 240 240) 1202.98px 3908.07px, rgb(240 240 240) 3274.45px 3317.9px,
    rgb(240 240 240) 3447.02px 3939.37px, rgb(240 240 240) 1554.89px 3014.46px,
    rgb(240 240 240) 3802.77px 3014.39px, rgb(240 240 240) 2357.78px 1188.5px,
    rgb(240 240 240) 1470.66px 3592.8px, rgb(240 240 240) 2476.5px 1201.2px,
    rgb(240 240 240) 2929.27px 3063.75px, rgb(240 240 240) 964.277px 1285.08px,
    rgb(240 240 240) 853.292px 778.218px, rgb(240 240 240) 3767.17px 9.58343px,
    rgb(240 240 240) 3047.95px 2395.51px, rgb(240 240 240) 360.242px 3958.18px,
    rgb(240 240 240) 2678.56px 2375.66px, rgb(240 240 240) 2936.91px 2883.49px,
    rgb(240 240 240) 1789.51px 736.296px, rgb(240 240 240) 732.934px 1861.22px,
    rgb(240 240 240) 3443.87px 3947.19px, rgb(240 240 240) 136.984px 717.566px,
    rgb(240 240 240) 1430.17px 3823.94px, rgb(240 240 240) 2499.11px 196.322px,
    rgb(240 240 240) 3231.85px 2771.52px, rgb(240 240 240) 1443.99px 2028.59px,
    rgb(240 240 240) 835.569px 3953.72px, rgb(240 240 240) 1245.36px 11.423px,
    rgb(240 240 240) 3765.61px 807.958px, rgb(240 240 240) 3100.36px 969.49px,
    rgb(240 240 240) 197.36px 1855.28px, rgb(240 240 240) 3064.48px 776.429px,
    rgb(240 240 240) 969.573px 2444.7px, rgb(240 240 240) 878.274px 1498.65px,
    rgb(240 240 240) 2618.12px 914.027px, rgb(240 240 240) 1722.53px 3867.24px,
    rgb(240 240 240) 3179.72px 2032.34px, rgb(240 240 240) 240.638px 1416.11px,
    rgb(240 240 240) 3095.91px 559.663px, rgb(240 240 240) 186.497px 999.895px,
    rgb(240 240 240) 3434.37px 217.906px, rgb(240 240 240) 3617.73px 3449.23px,
    rgb(240 240 240) 1871.72px 3741.08px, rgb(240 240 240) 2777.5px 2851.59px,
    rgb(240 240 240) 2813.4px 1287px, rgb(240 240 240) 3270.28px 300.903px,
    rgb(240 240 240) 3364.53px 1563.15px, rgb(240 240 240) 2313.02px 3173.9px,
    rgb(240 240 240) 2760.6px 2919.5px, rgb(240 240 240) 1666.48px 528.132px,
    rgb(240 240 240) 3861.97px 812.827px, rgb(240 240 240) 218.654px 1187.13px,
    rgb(240 240 240) 3394.79px 1590.81px, rgb(240 240 240) 1655.09px 3939.94px,
    rgb(240 240 240) 3155.8px 3613.31px, rgb(240 240 240) 1958.69px 3925.19px,
    rgb(240 240 240) 2890.36px 2602.11px, rgb(240 240 240) 1937.31px 238.837px,
    rgb(240 240 240) 2393.06px 3484.2px, rgb(240 240 240) 3219.82px 117.489px,
    rgb(240 240 240) 648.089px 1704.35px, rgb(240 240 240) 2037.1px 216.022px,
    rgb(240 240 240) 2331.72px 786.483px, rgb(240 240 240) 2774.33px 3834.39px,
    rgb(240 240 240) 23.1411px 3209.23px, rgb(240 240 240) 1102.71px 1158.76px,
    rgb(240 240 240) 1988.06px 2027.52px, rgb(240 240 240) 343.871px 326.941px,
    rgb(240 240 240) 2080.17px 2413.44px, rgb(240 240 240) 106.566px 155.987px,
    rgb(240 240 240) 2252.03px 1419.3px, rgb(240 240 240) 103.841px 574.221px,
    rgb(240 240 240) 2006.79px 1749.86px, rgb(240 240 240) 2525.49px 1779.29px,
    rgb(240 240 240) 1969.88px 304.633px, rgb(240 240 240) 1498.53px 2530.16px,
    rgb(240 240 240) 1726.92px 2005.77px, rgb(240 240 240) 444.111px 1072.05px,
    rgb(240 240 240) 2263.28px 125.802px, rgb(240 240 240) 1500.17px 3526.45px,
    rgb(240 240 240) 2119.1px 1225.46px, rgb(240 240 240) 2830.53px 2546.05px,
    rgb(240 240 240) 823.636px 96.7582px, rgb(240 240 240) 1297.02px 616.092px,
    rgb(240 240 240) 1817.65px 2671.86px, rgb(240 240 240) 3879.67px 742.154px,
    rgb(240 240 240) 2483.23px 1893.65px, rgb(240 240 240) 907.194px 3884.1px,
    rgb(240 240 240) 232.418px 2075.05px, rgb(240 240 240) 2371.71px 169.96px,
    rgb(240 240 240) 1087.86px 2890.61px, rgb(240 240 240) 3993.52px 872.77px,
    rgb(240 240 240) 1713.94px 229.463px, rgb(240 240 240) 1702.6px 1497.99px,
    rgb(240 240 240) 2054.87px 3177.2px, rgb(240 240 240) 1672.8px 985.286px,
    rgb(240 240 240) 2754.52px 262.529px, rgb(240 240 240) 3800.7px 356.917px,
    rgb(240 240 240) 3792.39px 3159.11px, rgb(240 240 240) 126.579px 3737.16px,
    rgb(240 240 240) 438.749px 3039.14px, rgb(240 240 240) 198.354px 2681.34px,
    rgb(240 240 240) 2163.27px 1076.08px, rgb(240 240 240) 391.899px 3772.91px,
    rgb(240 240 240) 3647.97px 1662.2px, rgb(240 240 240) 3789.35px 1550.22px,
    rgb(240 240 240) 917.922px 236.283px, rgb(240 240 240) 2334.16px 2992.22px,
    rgb(240 240 240) 779.052px 191.333px, rgb(240 240 240) 393.006px 3345.46px,
    rgb(240 240 240) 2990.4px 2388.99px, rgb(240 240 240) 1088.38px 87.9893px,
    rgb(240 240 240) 3424.61px 2347.24px, rgb(240 240 240) 2198.33px 793.148px,
    rgb(240 240 240) 2211.11px 2481.79px, rgb(240 240 240) 2051.14px 3651.23px,
    rgb(240 240 240) 2621.6px 2711.99px, rgb(240 240 240) 158.857px 231.192px,
    rgb(240 240 240) 3225.16px 2010.56px, rgb(240 240 240) 3045.48px 3422.64px,
    rgb(240 240 240) 1600.83px 634.441px, rgb(240 240 240) 2622.3px 3650.93px,
    rgb(240 240 240) 3573.04px 1898.57px, rgb(240 240 240) 2153.85px 308.281px,
    rgb(240 240 240) 1499.87px 2891.86px, rgb(240 240 240) 1467.17px 1959.89px,
    rgb(240 240 240) 107.367px 2523.3px, rgb(240 240 240) 126.174px 3342.55px,
    rgb(240 240 240) 1779.69px 1139.39px, rgb(240 240 240) 2188.09px 1655.43px,
    rgb(240 240 240) 59.1097px 2264.49px, rgb(240 240 240) 2812.66px 381.745px,
    rgb(240 240 240) 2153.59px 2570.07px, rgb(240 240 240) 2173.16px 673.119px,
    rgb(240 240 240) 3697.97px 392.966px, rgb(240 240 240) 1466.88px 1964.65px,
    rgb(240 240 240) 2997.67px 2699.66px, rgb(240 240 240) 892.014px 3593.67px,
    rgb(240 240 240) 3553.9px 3737.94px, rgb(240 240 240) 3668.07px 3413.4px,
    rgb(240 240 240) 3550.31px 2531.02px, rgb(240 240 240) 3896.33px 1904.33px,
    rgb(240 240 240) 303.538px 2468.55px, rgb(240 240 240) 3481.62px 349.182px,
    rgb(240 240 240) 3460.55px 2372.18px, rgb(240 240 240) 3843.1px 1462.34px,
    rgb(240 240 240) 2706.01px 1390.77px, rgb(240 240 240) 3036.74px 3032.75px,
    rgb(240 240 240) 1389px 429.238px, rgb(240 240 240) 526.402px 2155.43px,
    rgb(240 240 240) 1891.01px 3000.8px, rgb(240 240 240) 402.855px 3124.13px,
    rgb(240 240 240) 1511.34px 2142.98px, rgb(240 240 240) 3257.74px 2774.37px,
    rgb(240 240 240) 2574.54px 1319.28px, rgb(240 240 240) 3688.67px 3290.42px,
    rgb(240 240 240) 3510.44px 2181px, rgb(240 240 240) 3721.3px 2021.59px,
    rgb(240 240 240) 3473.76px 3390.86px, rgb(240 240 240) 998.447px 3651.9px,
    rgb(240 240 240) 1276.17px 3391.57px, rgb(240 240 240) 1545.44px 1363.77px,
    rgb(240 240 240) 928.956px 1903.41px, rgb(240 240 240) 12.8939px 1391.44px,
    rgb(240 240 240) 3701.93px 783.032px, rgb(240 240 240) 2784.1px 2410.95px,
    rgb(240 240 240) 3210.2px 1798.61px, rgb(240 240 240) 3813.37px 2383.56px,
    rgb(240 240 240) 2025.63px 1741.08px, rgb(240 240 240) 3424.54px 55.6564px,
    rgb(240 240 240) 2473.14px 2623.9px, rgb(240 240 240) 831.424px 2278.03px,
    rgb(240 240 240) 765.08px 3455.28px, rgb(240 240 240) 1986.91px 3457.44px,
    rgb(240 240 240) 3751.37px 2620.75px, rgb(240 240 240) 2630.53px 1225.42px,
    rgb(240 240 240) 1499.24px 3370px, rgb(240 240 240) 2983.26px 349.474px,
    rgb(240 240 240) 3262.09px 2764.1px, rgb(240 240 240) 2416.82px 2251.72px,
    rgb(240 240 240) 2398.27px 1499.25px, rgb(240 240 240) 2743.47px 879.883px,
    rgb(240 240 240) 3919.76px 3435.62px, rgb(240 240 240) 401.841px 2549.38px,
    rgb(240 240 240) 3729.34px 3895.65px, rgb(240 240 240) 1487.93px 1750.34px,
    rgb(240 240 240) 498.304px 2982.62px, rgb(240 240 240) 1595.64px 242.948px,
    rgb(240 240 240) 3266.04px 520.823px, rgb(240 240 240) 3143.83px 2534.88px,
    rgb(240 240 240) 1133.59px 209.165px, rgb(240 240 240) 3392.02px 3356.12px,
    rgb(240 240 240) 3631.48px 3505.48px, rgb(240 240 240) 3410.41px 3933.97px,
    rgb(240 240 240) 1739.75px 2306.64px, rgb(240 240 240) 3395.8px 3430.86px,
    rgb(240 240 240) 493.071px 3553.14px, rgb(240 240 240) 3945.64px 3512.47px,
    rgb(240 240 240) 3822.16px 2679.31px, rgb(240 240 240) 601.399px 1026.71px,
    rgb(240 240 240) 40.8027px 2763.92px, rgb(240 240 240) 821.036px 257.989px,
    rgb(240 240 240) 3412.75px 1768.48px, rgb(240 240 240) 1897.9px 1603.33px,
    rgb(240 240 240) 3506.19px 2761.41px, rgb(240 240 240) 2442.46px 760.046px,
    rgb(240 240 240) 3564.12px 1909.77px, rgb(240 240 240) 640.926px 3760.38px,
    rgb(240 240 240) 8.99792px 873.453px, rgb(240 240 240) 3792.13px 153.388px,
    rgb(240 240 240) 914.778px 819.383px, rgb(240 240 240) 920.921px 166.905px,
    rgb(240 240 240) 2189.29px 2694.29px, rgb(240 240 240) 2627.68px 487.379px,
    rgb(240 240 240) 2669.22px 426.199px, rgb(240 240 240) 2979.07px 2691.65px,
    rgb(240 240 240) 1801.27px 1602.1px, rgb(240 240 240) 868.726px 313.562px,
    rgb(240 240 240) 451.125px 1746.91px, rgb(240 240 240) 3312.17px 3642.32px,
    rgb(240 240 240) 1399.95px 2379.59px, rgb(240 240 240) 1216.55px 3835.59px,
    rgb(240 240 240) 1981.1px 3786.9px, rgb(240 240 240) 707.31px 2155.67px,
    rgb(240 240 240) 2641.09px 1993.92px, rgb(240 240 240) 334.345px 789.988px,
    rgb(240 240 240) 1236.88px 3115.88px, rgb(240 240 240) 1831.38px 3279.26px,
    rgb(240 240 240) 2915.74px 2921.63px, rgb(240 240 240) 3960.17px 2336.72px,
    rgb(240 240 240) 1318.63px 526.331px, rgb(240 240 240) 1176.79px 2266.45px,
    rgb(240 240 240) 2175.33px 232.01px, rgb(240 240 240) 1757.79px 1749.46px,
    rgb(240 240 240) 3921.4px 2805.98px, rgb(240 240 240) 3846.48px 1150.06px,
    rgb(240 240 240) 171.578px 3321.68px, rgb(240 240 240) 3457.1px 3768.25px,
    rgb(240 240 240) 947.593px 1537.35px, rgb(240 240 240) 3404.81px 1337.98px,
    rgb(240 240 240) 1952.39px 2046.84px, rgb(240 240 240) 257.332px 426.581px,
    rgb(240 240 240) 446.805px 2181.85px, rgb(240 240 240) 3624.07px 56.7701px,
    rgb(240 240 240) 1083.55px 3975.55px, rgb(240 240 240) 2911.93px 3091.72px,
    rgb(240 240 240) 3984.59px 50.0061px, rgb(240 240 240) 3493.9px 3634.34px,
    rgb(240 240 240) 3017.83px 2396.14px, rgb(240 240 240) 117.967px 1440.11px,
    rgb(240 240 240) 251.063px 1666.43px, rgb(240 240 240) 3726.72px 2808.92px,
    rgb(240 240 240) 3727.45px 738.335px, rgb(240 240 240) 1801.56px 2693.81px,
    rgb(240 240 240) 691.598px 426.786px, rgb(240 240 240) 3009.36px 554.987px,
    rgb(240 240 240) 61.131px 1598.59px, rgb(240 240 240) 1445.89px 2385.45px,
    rgb(240 240 240) 1557.73px 494.737px, rgb(240 240 240) 2958.6px 2145.6px,
    rgb(240 240 240) 2941.11px 611.66px, rgb(240 240 240) 3789.9px 2961.01px,
    rgb(240 240 240) 3981px 2012.84px, rgb(240 240 240) 2972.77px 1051.74px,
    rgb(240 240 240) 3491.78px 2619.16px, rgb(240 240 240) 1827.16px 2846.61px,
    rgb(240 240 240) 525.29px 2388.96px, rgb(240 240 240) 720.545px 1960.66px,
    rgb(240 240 240) 1739.11px 1714.25px, rgb(240 240 240) 130.262px 3292.16px,
    rgb(240 240 240) 1529.02px 1090.74px, rgb(240 240 240) 3539.33px 3146.36px,
    rgb(240 240 240) 297.122px 230.382px, rgb(240 240 240) 1246.84px 431.306px,
    rgb(240 240 240) 3987.4px 1567.98px, rgb(240 240 240) 10.3496px 1460.82px,
    rgb(240 240 240) 3708.63px 1015.71px, rgb(240 240 240) 3868.38px 2379.53px,
    rgb(240 240 240) 173.837px 781.544px, rgb(240 240 240) 3262.66px 1890.13px,
    rgb(240 240 240) 558.275px 3729.74px, rgb(240 240 240) 2037.57px 3897.06px,
    rgb(240 240 240) 169.666px 426.055px, rgb(240 240 240) 2522.19px 2927.76px,
    rgb(240 240 240) 2808.38px 951.55px, rgb(240 240 240) 138.571px 2086.03px,
    rgb(240 240 240) 1504.84px 3432.92px, rgb(240 240 240) 1250.69px 1620.38px,
    rgb(240 240 240) 1450.37px 2653.94px, rgb(240 240 240) 3607.86px 2166.9px,
    rgb(240 240 240) 767.201px 3847.5px, rgb(240 240 240) 880.922px 1889.63px,
    rgb(240 240 240) 3880.18px 730.894px, rgb(240 240 240) 2191.8px 844.967px,
    rgb(240 240 240) 1826.77px 3780.95px, rgb(240 240 240) 1080.82px 241.345px,
    rgb(240 240 240) 1917.34px 2538.05px, rgb(240 240 240) 3098.28px 182.108px,
    rgb(240 240 240) 802.952px 2458.32px, rgb(240 240 240) 491.378px 1972.15px,
    rgb(240 240 240) 3205.68px 1574.38px, rgb(240 240 240) 3618.56px 3334.03px,
    rgb(240 240 240) 3247.67px 205.633px, rgb(240 240 240) 2502.38px 1552.95px,
    rgb(240 240 240) 3810.68px 2984.27px, rgb(240 240 240) 1647.55px 826.197px,
    rgb(240 240 240) 2950.59px 1087.43px, rgb(240 240 240) 3169.33px 3785.63px,
    rgb(240 240 240) 1287.16px 2351.88px, rgb(240 240 240) 3037.42px 732.333px,
    rgb(240 240 240) 3998.03px 1778.07px, rgb(240 240 240) 1811.53px 1215.35px,
    rgb(240 240 240) 2472.36px 385.52px, rgb(240 240 240) 2144.54px 3451.75px,
    rgb(240 240 240) 3316.22px 2903.44px, rgb(240 240 240) 483.718px 1798.84px,
    rgb(240 240 240) 314.546px 224.153px, rgb(240 240 240) 3287.65px 3255.1px,
    rgb(240 240 240) 84.1752px 1568.55px, rgb(240 240 240) 1732.37px 3512.37px,
    rgb(240 240 240) 2157.01px 2930.42px, rgb(240 240 240) 900.863px 1972.76px,
    rgb(240 240 240) 1985.19px 912.827px, rgb(240 240 240) 561.189px 3846.62px,
    rgb(240 240 240) 1355.55px 1943.57px, rgb(240 240 240) 3292.38px 1442.35px,
    rgb(240 240 240) 2970.99px 2230.22px, rgb(240 240 240) 3972.09px 3747.76px,
    rgb(240 240 240) 2937.08px 2488.29px, rgb(240 240 240) 737.852px 2204.58px,
    rgb(240 240 240) 2260.65px 1876.49px, rgb(240 240 240) 846.37px 701.753px,
    rgb(240 240 240) 3658.58px 2477.84px, rgb(240 240 240) 2978.71px 1201.45px,
    rgb(240 240 240) 439.782px 28.8495px, rgb(240 240 240) 204.18px 1527.97px,
    rgb(240 240 240) 3587.38px 3353.27px, rgb(240 240 240) 291.402px 2243.42px,
    rgb(240 240 240) 1737.08px 1583.13px, rgb(240 240 240) 1375.95px 1163.72px,
    rgb(240 240 240) 2302.24px 2236.68px, rgb(240 240 240) 2353.92px 1358.95px,
    rgb(240 240 240) 1486.25px 76.3665px, rgb(240 240 240) 1053.34px 3650.39px,
    rgb(240 240 240) 1581.69px 3069.37px, rgb(240 240 240) 2202.12px 3880.36px,
    rgb(240 240 240) 802.853px 3049.57px, rgb(240 240 240) 2623.96px 1461.98px,
    rgb(240 240 240) 1212.41px 1456.09px, rgb(240 240 240) 1517.73px 2059.63px,
    rgb(240 240 240) 2325.9px 1392.03px, rgb(240 240 240) 2466.19px 244.111px,
    rgb(240 240 240) 2074.3px 808.197px, rgb(240 240 240) 330.591px 3209.59px,
    rgb(240 240 240) 1905.66px 1166.44px, rgb(240 240 240) 267.897px 2048.51px,
    rgb(240 240 240) 1593.8px 2168.26px, rgb(240 240 240) 2148.98px 1283.44px,
    rgb(240 240 240) 1214.02px 1328.53px, rgb(240 240 240) 3628px 2161.98px,
    rgb(240 240 240) 2027.97px 1497.1px, rgb(240 240 240) 2132.26px 1881.41px,
    rgb(240 240 240) 2556.76px 1646.98px, rgb(240 240 240) 27.9574px 911.567px,
    rgb(240 240 240) 529.419px 1119.88px, rgb(240 240 240) 1209.15px 3358.86px,
    rgb(240 240 240) 2801.99px 2088.64px, rgb(240 240 240) 1803.98px 1784.18px,
    rgb(240 240 240) 2824.27px 3164.49px, rgb(240 240 240) 2790.66px 3754.76px,
    rgb(240 240 240) 3462.75px 3628.47px, rgb(240 240 240) 1324.48px 1627.48px,
    rgb(240 240 240) 2400.65px 277.473px, rgb(240 240 240) 510.381px 2504.58px,
    rgb(240 240 240) 3401.7px 956.461px, rgb(240 240 240) 2725.07px 2610.93px,
    rgb(240 240 240) 3550.07px 1050.18px, rgb(240 240 240) 1491.82px 2798.46px,
    rgb(240 240 240) 3569.87px 3021.4px, rgb(240 240 240) 3460.81px 2921.83px,
    rgb(240 240 240) 3896.49px 3703.13px, rgb(240 240 240) 324.249px 1133.09px,
    rgb(240 240 240) 3010.96px 3625.66px, rgb(240 240 240) 766.539px 2596.45px,
    rgb(240 240 240) 1470.81px 1916.74px, rgb(240 240 240) 1704.78px 2267.37px,
    rgb(240 240 240) 1924.3px 2079.13px, rgb(240 240 240) 3687.96px 1139.65px,
    rgb(240 240 240) 2884.71px 3677.65px, rgb(240 240 240) 2178.92px 1267.94px,
    rgb(240 240 240) 3978.25px 376.721px, rgb(240 240 240) 826.819px 2292.18px,
    rgb(240 240 240) 1241.31px 1847.82px, rgb(240 240 240) 3077.58px 3167.65px,
    rgb(240 240 240) 3511.87px 1893.76px, rgb(240 240 240) 2357.19px 2924.42px,
    rgb(240 240 240) 481.404px 3148.41px, rgb(240 240 240) 3433.76px 2019.4px,
    rgb(240 240 240) 1473.83px 3106.37px, rgb(240 240 240) 2207.56px 3513.6px,
    rgb(240 240 240) 537.28px 2518.3px, rgb(240 240 240) 1178.28px 3512.05px,
    rgb(240 240 240) 1572.26px 2834.55px, rgb(240 240 240) 1505.56px 216.858px,
    rgb(240 240 240) 964.65px 3935.25px, rgb(240 240 240) 813.548px 2908.76px,
    rgb(240 240 240) 500.934px 74.417px, rgb(240 240 240) 1481.78px 3096.46px,
    rgb(240 240 240) 3149.16px 749.234px, rgb(240 240 240) 712.734px 3798.32px,
    rgb(240 240 240) 2404.3px 2447.7px, rgb(240 240 240) 376.114px 835.358px,
    rgb(240 240 240) 1045.04px 3961.8px, rgb(240 240 240) 3625.62px 3877.72px,
    rgb(240 240 240) 3279.26px 2465.17px, rgb(240 240 240) 2665.35px 1268.46px,
    rgb(240 240 240) 640.765px 2729.48px, rgb(240 240 240) 3130.04px 1508.34px,
    rgb(240 240 240) 2309.81px 3020.01px, rgb(240 240 240) 1061.86px 2171.96px,
    rgb(240 240 240) 216.484px 3480.97px, rgb(240 240 240) 1696.17px 1502.29px,
    rgb(240 240 240) 3470.78px 2439.73px, rgb(240 240 240) 3543.5px 2950.79px,
    rgb(240 240 240) 2433.13px 1692.9px, rgb(240 240 240) 2865.56px 508.28px,
    rgb(240 240 240) 1222.22px 3481.75px, rgb(240 240 240) 820.098px 716.943px,
    rgb(240 240 240) 3186.98px 3899.5px, rgb(240 240 240) 1857.84px 2044.73px,
    rgb(240 240 240) 247.133px 2331.61px, rgb(240 240 240) 2743.02px 443.463px,
    rgb(240 240 240) 1778.83px 2866.84px, rgb(240 240 240) 3232.57px 3206.79px,
    rgb(240 240 240) 934.163px 1852.6px, rgb(240 240 240) 223.095px 2388.76px,
    rgb(240 240 240) 3797.1px 430.923px, rgb(240 240 240) 3442.41px 2732.01px,
    rgb(240 240 240) 3381.39px 1588.52px, rgb(240 240 240) 2270.61px 1626.82px,
    rgb(240 240 240) 3430.02px 1072.61px, rgb(240 240 240) 321.606px 1093.43px,
    rgb(240 240 240) 486.579px 1987.63px, rgb(240 240 240) 823.652px 2152.21px,
    rgb(240 240 240) 3995.35px 1689.17px, rgb(240 240 240) 3878.16px 1304.19px,
    rgb(240 240 240) 1772.59px 1861.81px, rgb(240 240 240) 3714.29px 3269.08px,
    rgb(240 240 240) 1146.38px 312.797px, rgb(240 240 240) 24.9817px 2873.62px,
    rgb(240 240 240) 3559.35px 3175.15px, rgb(240 240 240) 3187.85px 1590.19px,
    rgb(240 240 240) 270.331px 428.313px, rgb(240 240 240) 2846.08px 2988.32px,
    rgb(240 240 240) 1179.98px 323.09px, rgb(240 240 240) 1807.67px 3287.22px,
    rgb(240 240 240) 1101.18px 315.443px, rgb(240 240 240) 296.682px 2978.81px,
    rgb(240 240 240) 935.753px 3222.6px, rgb(240 240 240) 3369.4px 3077.99px,
    rgb(240 240 240) 148.817px 3960.21px, rgb(240 240 240) 3611.78px 589.924px,
    rgb(240 240 240) 2418.85px 1749.49px, rgb(240 240 240) 352.97px 814.271px,
    rgb(240 240 240) 2648.84px 912.397px, rgb(240 240 240) 658.053px 1464.87px,
    rgb(240 240 240) 2387.85px 2704.2px, rgb(240 240 240) 3930.72px 3361.75px,
    rgb(240 240 240) 435.7px 2877.5px, rgb(240 240 240) 3841.41px 1128.94px,
    rgb(240 240 240) 3224.4px 3539.03px, rgb(240 240 240) 628.529px 2152.91px,
    rgb(240 240 240) 117.916px 1513.31px, rgb(240 240 240) 3546.35px 1147.69px,
    rgb(240 240 240) 1440.46px 1107.11px, rgb(240 240 240) 2835.88px 114.962px,
    rgb(240 240 240) 3220.79px 2432.01px, rgb(240 240 240) 1287.67px 1695.78px,
    rgb(240 240 240) 2385.63px 1136.34px, rgb(240 240 240) 1493.7px 1524.85px,
    rgb(240 240 240) 823.066px 904.742px, rgb(240 240 240) 1556.93px 838.147px,
    rgb(240 240 240) 3566.39px 1457.31px, rgb(240 240 240) 2474.99px 3128.16px,
    rgb(240 240 240) 3135.05px 757.582px, rgb(240 240 240) 2965.9px 190.644px,
    rgb(240 240 240) 3292.45px 945.884px, rgb(240 240 240) 1283.72px 2268.6px,
    rgb(240 240 240) 3834.25px 1143.92px, rgb(240 240 240) 2487.41px 3836.05px,
    rgb(240 240 240) 2838.62px 735.843px, rgb(240 240 240) 1894.37px 75.1922px,
    rgb(240 240 240) 1274.55px 30.6108px, rgb(240 240 240) 2472.31px 1025.44px,
    rgb(240 240 240) 3766.38px 2800.39px, rgb(240 240 240) 977.198px 2225.67px,
    rgb(240 240 240) 744.157px 2794.22px, rgb(240 240 240) 2805.49px 2097.61px,
    rgb(240 240 240) 1336.8px 3914.93px, rgb(240 240 240) 1357.56px 2771.58px,
    rgb(240 240 240) 3441.05px 321.614px, rgb(240 240 240) 2413.51px 1926.21px,
    rgb(240 240 240) 3366.58px 190.089px, rgb(240 240 240) 1036.37px 2796.28px,
    rgb(240 240 240) 2127.33px 2093.35px, rgb(240 240 240) 2740.23px 2414.01px,
    rgb(240 240 240) 1148.98px 3922.78px, rgb(240 240 240) 2475.37px 3669.56px,
    rgb(240 240 240) 3185.01px 1238.54px, rgb(240 240 240) 3882.79px 2452.15px,
    rgb(240 240 240) 1132.14px 94.6584px, rgb(240 240 240) 1998.9px 536.773px,
    rgb(240 240 240) 3659.14px 1894.2px, rgb(240 240 240) 368.284px 2538.96px,
    rgb(240 240 240) 1142.71px 1068.12px, rgb(240 240 240) 1265.41px 1685.48px,
    rgb(240 240 240) 1055.83px 1573.53px, rgb(240 240 240) 1085.48px 3440.69px,
    rgb(240 240 240) 2408.4px 2848.98px, rgb(240 240 240) 3593.88px 927.911px,
    rgb(240 240 240) 3957.19px 1417.14px, rgb(240 240 240) 2989.85px 3878.23px,
    rgb(240 240 240) 2039.17px 3353.96px, rgb(240 240 240) 80.4837px 1622.55px,
    rgb(240 240 240) 3860.83px 2927.56px, rgb(240 240 240) 174.119px 3880.49px,
    rgb(240 240 240) 3112.73px 2113.17px, rgb(240 240 240) 2124.2px 3655.72px,
    rgb(240 240 240) 2513.28px 3776.37px, rgb(240 240 240) 442.544px 2104.27px,
    rgb(240 240 240) 3803.5px 1035.39px, rgb(240 240 240) 2314.66px 1830.06px,
    rgb(240 240 240) 1381.54px 2049.14px, rgb(240 240 240) 2902.57px 3563.22px,
    rgb(240 240 240) 3975.89px 91.1046px, rgb(240 240 240) 1915.48px 2004.18px,
    rgb(240 240 240) 842.776px 534.497px, rgb(240 240 240) 3989.29px 3092.88px,
    rgb(240 240 240) 3574.43px 1199.56px, rgb(240 240 240) 456.38px 3310.16px,
    rgb(240 240 240) 1524.41px 2883.01px, rgb(240 240 240) 3257.81px 1349.83px,
    rgb(240 240 240) 953.385px 2854.37px, rgb(240 240 240) 2714.07px 2557.52px,
    rgb(240 240 240) 2965.01px 2203.34px, rgb(240 240 240) 2853.68px 2770.16px,
    rgb(240 240 240) 1522.74px 3436.41px, rgb(240 240 240) 3917.31px 3507.08px,
    rgb(240 240 240) 181.549px 1032.24px, rgb(240 240 240) 1144.92px 827.301px,
    rgb(240 240 240) 1163.87px 2488.25px, rgb(240 240 240) 667.848px 264.472px,
    rgb(240 240 240) 3075.09px 3581.16px, rgb(240 240 240) 3062px 2196.84px,
    rgb(240 240 240) 3649.7px 1987.75px, rgb(240 240 240) 1094.03px 629.837px,
    rgb(240 240 240) 838.527px 1316.77px, rgb(240 240 240) 1255.39px 3938.34px,
    rgb(240 240 240) 1334.57px 3973.58px, rgb(240 240 240) 3314.26px 2999.32px,
    rgb(240 240 240) 2945.88px 827.542px, rgb(240 240 240) 1742.97px 113.86px,
    rgb(240 240 240) 2331.56px 1378.78px, rgb(240 240 240) 420.386px 3064.42px,
    rgb(240 240 240) 3252.7px 1328.93px, rgb(240 240 240) 3727.6px 1161.54px,
    rgb(240 240 240) 1380.94px 677.87px, rgb(240 240 240) 3246.14px 1742.6px,
    rgb(240 240 240) 2470.16px 3926.06px, rgb(240 240 240) 823.439px 57.4918px,
    rgb(240 240 240) 1255.83px 2487.61px, rgb(240 240 240) 3471.41px 1708.15px,
    rgb(240 240 240) 2506.86px 1129.76px, rgb(240 240 240) 666.436px 719px,
    rgb(240 240 240) 2161.39px 993.641px, rgb(240 240 240) 2680.15px 1891.7px,
    rgb(240 240 240) 1231.25px 1050.62px, rgb(240 240 240) 483.4px 1083.42px,
    rgb(240 240 240) 3551.41px 204.422px, rgb(240 240 240) 899.124px 214.673px,
    rgb(240 240 240) 1866.9px 2787.12px, rgb(240 240 240) 1858.57px 492.578px,
    rgb(240 240 240) 599.846px 2732.48px, rgb(240 240 240) 1404.02px 3167.15px,
    rgb(240 240 240) 3132.05px 2484.17px, rgb(240 240 240) 3429.22px 3984.72px,
    rgb(240 240 240) 277.812px 3043.14px, rgb(240 240 240) 3668.6px 1309.69px,
    rgb(240 240 240) 2143.06px 2714.96px, rgb(240 240 240) 234.581px 168.738px,
    rgb(240 240 240) 375.057px 2024.12px, rgb(240 240 240) 3678.86px 1520.29px,
    rgb(240 240 240) 3091.7px 3125.11px, rgb(240 240 240) 926.469px 3269.15px,
    rgb(240 240 240) 1153.38px 1015.16px, rgb(240 240 240) 465.376px 546.484px,
    rgb(240 240 240) 3636.49px 3022.1px, rgb(240 240 240) 1020.78px 610.542px,
    rgb(240 240 240) 635.293px 1135.52px, rgb(240 240 240) 3581.13px 3690.47px,
    rgb(240 240 240) 2185.23px 2472.34px, rgb(240 240 240) 3045.79px 1177.7px,
    rgb(240 240 240) 721.335px 357.532px, rgb(240 240 240) 2851.51px 2145.22px,
    rgb(240 240 240) 944.669px 2870.89px, rgb(240 240 240) 1679.79px 2942.57px,
    rgb(240 240 240) 659.178px 264.592px, rgb(240 240 240) 2286.07px 2757.47px,
    rgb(240 240 240) 1523.25px 3484.81px, rgb(240 240 240) 2568.05px 2577.69px,
    rgb(240 240 240) 3494.3px 3893.09px, rgb(240 240 240) 236.496px 2258.16px,
    rgb(240 240 240) 997.465px 2585.87px, rgb(240 240 240) 3912.32px 3836.65px,
    rgb(240 240 240) 2599.25px 3189.89px, rgb(240 240 240) 1771.08px 3422.5px,
    rgb(240 240 240) 89.6248px 796.873px, rgb(240 240 240) 215.957px 3973.21px,
    rgb(240 240 240) 110.375px 1777.1px, rgb(240 240 240) 760.185px 2273.25px,
    rgb(240 240 240) 3304.83px 831.092px, rgb(240 240 240) 3514.13px 1006.58px,
    rgb(240 240 240) 3261.39px 2928.75px, rgb(240 240 240) 2158.72px 1972.56px,
    rgb(240 240 240) 3065.68px 493.803px, rgb(240 240 240) 663.691px 3827.03px,
    rgb(240 240 240) 3956.36px 1367.63px, rgb(240 240 240) 2045.74px 2616.81px,
    rgb(240 240 240) 3784.2px 1939.71px, rgb(240 240 240) 132.378px 834.538px,
    rgb(240 240 240) 3175.61px 1240.41px, rgb(240 240 240) 1521.52px 2275.92px,
    rgb(240 240 240) 3747.68px 804.36px, rgb(240 240 240) 2079.78px 3025.56px,
    rgb(240 240 240) 1093.3px 3810.47px, rgb(240 240 240) 312.018px 2098.03px,
    rgb(240 240 240) 3077.71px 1019.07px, rgb(240 240 240) 1792.95px 2822.35px,
    rgb(240 240 240) 606.874px 1972.53px, rgb(240 240 240) 3020.25px 3421.1px,
    rgb(240 240 240) 2747.53px 491.969px, rgb(240 240 240) 1294.82px 679.145px,
    rgb(240 240 240) 1523.76px 1171.68px, rgb(240 240 240) 2744.73px 3163.3px,
    rgb(240 240 240) 3198.87px 2544.48px, rgb(240 240 240) 2197.11px 1663.76px,
    rgb(240 240 240) 1988.46px 2713.08px, rgb(240 240 240) 505.255px 1122.62px,
    rgb(240 240 240) 7.81086px 621.031px, rgb(240 240 240) 3978.07px 1195.6px,
    rgb(240 240 240) 180.462px 2671.18px, rgb(240 240 240) 1342.84px 3106.12px,
    rgb(240 240 240) 2008.96px 2329.5px, rgb(240 240 240) 3467.9px 3313.85px,
    rgb(240 240 240) 1705.26px 2234.5px, rgb(240 240 240) 3730.64px 304.665px,
    rgb(240 240 240) 1575.49px 980.542px, rgb(240 240 240) 1301.3px 1518.07px,
    rgb(240 240 240) 253.995px 3966.34px, rgb(240 240 240) 2964.79px 611.383px,
    rgb(240 240 240) 1869.4px 803.391px, rgb(240 240 240) 418.894px 3257.87px,
    rgb(240 240 240) 3873.04px 790.762px, rgb(240 240 240) 329.033px 3446.91px,
    rgb(240 240 240) 965.766px 299.755px, rgb(240 240 240) 1314.78px 3769.94px,
    rgb(240 240 240) 2288.77px 1971.38px, rgb(240 240 240) 257.074px 2352.37px,
    rgb(240 240 240) 2725.72px 490.231px, rgb(240 240 240) 2471.82px 730.483px,
    rgb(240 240 240) 2122.31px 1259.49px, rgb(240 240 240) 681.306px 1520.62px,
    rgb(240 240 240) 456.882px 278.806px, rgb(240 240 240) 3039.31px 2787.28px,
    rgb(240 240 240) 3588.24px 3609px, rgb(240 240 240) 2200.62px 1546.61px,
    rgb(240 240 240) 429.025px 3026.38px, rgb(240 240 240) 2416.09px 217.059px,
    rgb(240 240 240) 3410.48px 956.615px, rgb(240 240 240) 1084.39px 3555.21px,
    rgb(240 240 240) 1873.31px 1034.34px, rgb(240 240 240) 3217.61px 534.084px,
    rgb(240 240 240) 1490.01px 3020.78px, rgb(240 240 240) 610.989px 3143.67px,
    rgb(240 240 240) 254.346px 2394.95px, rgb(240 240 240) 2145.97px 2027.22px,
    rgb(240 240 240) 2305.11px 3036.04px, rgb(240 240 240) 1930.92px 3247.97px,
    rgb(240 240 240) 873.246px 179.278px, rgb(240 240 240) 200.531px 3425.26px,
    rgb(240 240 240) 2428.85px 3915.84px, rgb(240 240 240) 3431.28px 2698.74px,
    rgb(240 240 240) 1022.77px 3009.51px, rgb(240 240 240) 3776.75px 1859.26px,
    rgb(240 240 240) 2825.75px 1900.3px, rgb(240 240 240) 3225.55px 1709.86px,
    rgb(240 240 240) 809.742px 2430.55px, rgb(240 240 240) 3388.9px 1854.15px,
    rgb(240 240 240) 1662.86px 2017.76px, rgb(240 240 240) 95.5935px 3742.21px,
    rgb(240 240 240) 527.261px 3814.22px, rgb(240 240 240) 2226.01px 2214.93px,
    rgb(240 240 240) 593.194px 354.961px, rgb(240 240 240) 1102.98px 347.224px,
    rgb(240 240 240) 3830.43px 650.69px, rgb(240 240 240) 1935.28px 2974.7px,
    rgb(240 240 240) 1334.82px 1595.38px, rgb(240 240 240) 1625.48px 250.732px,
    rgb(240 240 240) 2998.14px 15.4094px, rgb(240 240 240) 2733.58px 661.726px,
    rgb(240 240 240) 3126.3px 1182.59px, rgb(240 240 240) 2884px 554.297px,
    rgb(240 240 240) 3245.48px 1091.72px, rgb(240 240 240) 283.374px 3976.28px,
    rgb(240 240 240) 3038.91px 2740.2px, rgb(240 240 240) 2026.85px 1463.09px,
    rgb(240 240 240) 2223.33px 1537.09px, rgb(240 240 240) 2403.75px 738.833px,
    rgb(240 240 240) 1072.3px 3319.7px, rgb(240 240 240) 763.456px 499.006px,
    rgb(240 240 240) 216.835px 1467.83px, rgb(240 240 240) 1437.41px 2635.74px,
    rgb(240 240 240) 3022.61px 2913.19px, rgb(240 240 240) 2577.93px 824.72px,
    rgb(240 240 240) 1111.78px 3461.18px, rgb(240 240 240) 859.767px 80.9412px,
    rgb(240 240 240) 3466.49px 2590.26px, rgb(240 240 240) 2426.8px 2829.89px,
    rgb(240 240 240) 3132.49px 1626.33px, rgb(240 240 240) 1310.98px 600.313px,
    rgb(240 240 240) 3127.26px 3402.95px, rgb(240 240 240) 1006.65px 3072.2px,
    rgb(240 240 240) 2145.18px 3621.42px, rgb(240 240 240) 2016.53px 3460.86px,
    rgb(240 240 240) 316.714px 2777.79px, rgb(240 240 240) 1711.73px 1318.23px,
    rgb(240 240 240) 647.537px 3256.2px, rgb(240 240 240) 3423.96px 500.966px,
    rgb(240 240 240) 3061.15px 1308.76px, rgb(240 240 240) 806.354px 1192.91px,
    rgb(240 240 240) 2252.45px 2520.95px, rgb(240 240 240) 1617.2px 2418.19px,
    rgb(240 240 240) 3399.19px 896.877px, rgb(240 240 240) 996.246px 941.687px,
    rgb(240 240 240) 3926.73px 1649.45px, rgb(240 240 240) 3695.53px 121.579px,
    rgb(240 240 240) 941.538px 3787.55px, rgb(240 240 240) 3524.68px 3086.77px,
    rgb(240 240 240) 3908.83px 1472.44px, rgb(240 240 240) 3465.21px 1109.91px,
    rgb(240 240 240) 2813.59px 975.114px, rgb(240 240 240) 3076.82px 34.9618px,
    rgb(240 240 240) 370.852px 2558.62px, rgb(240 240 240) 1152.55px 3024.83px,
    rgb(240 240 240) 1065.12px 793.653px, rgb(240 240 240) 3839.25px 57.1355px,
    rgb(240 240 240) 118.404px 3137.74px, rgb(240 240 240) 1013.82px 2112.72px,
    rgb(240 240 240) 2509.94px 3513.77px, rgb(240 240 240) 1495.33px 665.91px,
    rgb(240 240 240) 2341.93px 3562.22px, rgb(240 240 240) 2018.15px 5.90207px,
    rgb(240 240 240) 416.92px 3300.06px, rgb(240 240 240) 112.853px 1795.59px,
    rgb(240 240 240) 1431.62px 3636.51px, rgb(240 240 240) 1553.31px 900.788px,
    rgb(240 240 240) 1575.81px 3277.87px, rgb(240 240 240) 3710.44px 2434.22px,
    rgb(240 240 240) 1122.44px 3166.73px, rgb(240 240 240) 3418.3px 2267.59px,
    rgb(240 240 240) 1658.77px 3143.83px, rgb(240 240 240) 338.423px 1055.87px,
    rgb(240 240 240) 2330.45px 3519.59px, rgb(240 240 240) 916.619px 3449.41px,
    rgb(240 240 240) 794.54px 1350.2px, rgb(240 240 240) 1227.57px 3603.96px,
    rgb(240 240 240) 3180.59px 3402.21px, rgb(240 240 240) 3083.38px 3269.35px,
    rgb(240 240 240) 1937.67px 2117.86px, rgb(240 240 240) 1829.22px 2244.14px,
    rgb(240 240 240) 1117.69px 11.8351px, rgb(240 240 240) 1272.64px 1946.61px,
    rgb(240 240 240) 3623.71px 3130.18px, rgb(240 240 240) 2413.01px 646.36px,
    rgb(240 240 240) 1703.94px 3675.25px, rgb(240 240 240) 254.998px 806.657px,
    rgb(240 240 240) 488.595px 504.669px, rgb(240 240 240) 3902.39px 3290.8px,
    rgb(240 240 240) 520.086px 3775.73px, rgb(240 240 240) 720.091px 3661.52px,
    rgb(240 240 240) 2036.35px 2513.43px, rgb(240 240 240) 858.277px 2397.13px,
    rgb(240 240 240) 3169.47px 2097.31px, rgb(240 240 240) 3335.86px 2930.24px,
    rgb(240 240 240) 2579.3px 3219.67px, rgb(240 240 240) 783.812px 2551.87px,
    rgb(240 240 240) 1772.03px 1745.36px, rgb(240 240 240) 1686.42px 982.825px,
    rgb(240 240 240) 139.127px 3572.41px, rgb(240 240 240) 657.166px 1916.28px,
    rgb(240 240 240) 673.415px 1945.24px, rgb(240 240 240) 2004.11px 2139.81px,
    rgb(240 240 240) 163.285px 2596.13px, rgb(240 240 240) 3137.17px 1555.82px,
    rgb(240 240 240) 72.5679px 26.8648px, rgb(240 240 240) 2846.68px 200.544px,
    rgb(240 240 240) 1149.3px 2992.48px, rgb(240 240 240) 3818.24px 837.509px,
    rgb(240 240 240) 340.115px 966.929px, rgb(240 240 240) 3217.85px 1671.41px,
    rgb(240 240 240) 147.481px 3284.86px, rgb(240 240 240) 320.417px 3279.36px,
    rgb(240 240 240) 805.325px 2291.6px, rgb(240 240 240) 410.962px 2086.35px,
    rgb(240 240 240) 2401.62px 3027.97px, rgb(240 240 240) 2506.31px 1544.15px,
    rgb(240 240 240) 3491.66px 2815.71px, rgb(240 240 240) 2003.66px 2573.74px,
    rgb(240 240 240) 1800.1px 2080.17px, rgb(240 240 240) 2439.57px 3956.05px,
    rgb(240 240 240) 3394.08px 1019.95px, rgb(240 240 240) 1715.94px 1791.3px,
    rgb(240 240 240) 1467.8px 2902.04px, rgb(240 240 240) 2265.79px 677.166px,
    rgb(240 240 240) 1804.69px 3183.02px, rgb(240 240 240) 3569.52px 325.283px,
    rgb(240 240 240) 492.011px 54.0276px, rgb(240 240 240) 3310.88px 2617.29px,
    rgb(240 240 240) 2249.16px 3604.84px, rgb(240 240 240) 1033.5px 3335.74px,
    rgb(240 240 240) 2103.49px 233.981px, rgb(240 240 240) 527.553px 209.301px,
    rgb(240 240 240) 1552.8px 1220.98px, rgb(240 240 240) 2807.9px 1534.91px,
    rgb(240 240 240) 187.747px 1626.35px, rgb(240 240 240) 1085.86px 2578.49px,
    rgb(240 240 240) 1543.27px 191.229px, rgb(240 240 240) 1660.85px 2295.35px,
    rgb(240 240 240) 183.644px 1802.77px, rgb(240 240 240) 1795.26px 1409.83px,
    rgb(240 240 240) 3814.35px 2015.09px, rgb(240 240 240) 343.77px 3900.16px,
    rgb(240 240 240) 898.502px 892.958px, rgb(240 240 240) 1180.55px 2385.96px,
    rgb(240 240 240) 606.046px 974.82px, rgb(240 240 240) 3825.68px 642.196px,
    rgb(240 240 240) 3329.2px 2596.81px, rgb(240 240 240) 2404.76px 3842.26px,
    rgb(240 240 240) 2219.71px 2098.03px, rgb(240 240 240) 2490.33px 3977px,
    rgb(240 240 240) 926.598px 2972.29px, rgb(240 240 240) 3827.55px 664.31px,
    rgb(240 240 240) 1392.44px 2688.62px, rgb(240 240 240) 775.067px 3229.93px,
    rgb(240 240 240) 2621.16px 2438.94px, rgb(240 240 240) 3981.16px 2249.64px,
    rgb(240 240 240) 2570.11px 1235.26px, rgb(240 240 240) 1203.14px 2780.84px,
    rgb(240 240 240) 859.724px 398.599px, rgb(240 240 240) 3903.94px 1447.29px,
    rgb(240 240 240) 2094.21px 2020.23px, rgb(240 240 240) 1385.74px 1865.79px,
    rgb(240 240 240) 2945.83px 1339.21px, rgb(240 240 240) 3490px 1172.45px,
    rgb(240 240 240) 1738.52px 3681.22px, rgb(240 240 240) 3545.53px 1558.97px,
    rgb(240 240 240) 3617.78px 771.762px, rgb(240 240 240) 704.773px 3510.66px,
    rgb(240 240 240) 995.29px 2730.77px, rgb(240 240 240) 2595.46px 1577.49px,
    rgb(240 240 240) 706.665px 815.767px, rgb(240 240 240) 3683.01px 613.623px,
    rgb(240 240 240) 2468.52px 382.393px, rgb(240 240 240) 828.533px 3992.15px,
    rgb(240 240 240) 1552.17px 1587.02px, rgb(240 240 240) 1037.82px 1447.62px,
    rgb(240 240 240) 2820.64px 630.743px, rgb(240 240 240) 2490.12px 3301.52px,
    rgb(240 240 240) 634.107px 2175.55px, rgb(240 240 240) 1308.88px 2101.44px,
    rgb(240 240 240) 2115.08px 3262.72px, rgb(240 240 240) 542.499px 2765.85px,
    rgb(240 240 240) 2932.13px 1211.48px, rgb(240 240 240) 1426.82px 3110.09px,
    rgb(240 240 240) 858.402px 1391.02px, rgb(240 240 240) 3361.35px 2836.47px,
    rgb(240 240 240) 549.513px 1276.94px, rgb(240 240 240) 399.106px 1580.54px,
    rgb(240 240 240) 2010.18px 1975px, rgb(240 240 240) 3588.54px 2998.29px,
    rgb(240 240 240) 1943.11px 386.36px, rgb(240 240 240) 444.39px 1572.62px,
    rgb(240 240 240) 760.036px 3495.33px, rgb(240 240 240) 2828.38px 3725.41px,
    rgb(240 240 240) 626.609px 1436.58px, rgb(240 240 240) 1780.48px 1411.89px,
    rgb(240 240 240) 906.081px 2260.77px, rgb(240 240 240) 1835.17px 2913.35px,
    rgb(240 240 240) 2909.58px 2049.29px, rgb(240 240 240) 1908.41px 3751.05px,
    rgb(240 240 240) 3594.42px 2340.5px, rgb(240 240 240) 3989.94px 1811.23px,
    rgb(240 240 240) 2356.67px 1831.07px, rgb(240 240 240) 1251.57px 1567.03px,
    rgb(240 240 240) 1815.47px 1482.59px, rgb(240 240 240) 2656.73px 1044.34px,
    rgb(240 240 240) 1730.93px 2778.46px, rgb(240 240 240) 955.804px 3738.17px,
    rgb(240 240 240) 1734.09px 1502.82px, rgb(240 240 240) 484.743px 3203.09px,
    rgb(240 240 240) 1417.94px 161.665px, rgb(240 240 240) 3409px 3395.13px,
    rgb(240 240 240) 1975.05px 2191.05px, rgb(240 240 240) 299.622px 2817.98px,
    rgb(240 240 240) 3514.31px 2567.03px, rgb(240 240 240) 2945.97px 3909.5px,
    rgb(240 240 240) 1019.86px 1608.95px, rgb(240 240 240) 1171.56px 3149.24px,
    rgb(240 240 240) 2687.63px 860.741px, rgb(240 240 240) 3007.94px 1597.24px,
    rgb(240 240 240) 2270.74px 3596.1px, rgb(240 240 240) 2388.26px 1317.27px,
    rgb(240 240 240) 1594.32px 3268.91px, rgb(240 240 240) 3614.04px 3809.2px,
    rgb(240 240 240) 683.743px 3985.37px, rgb(240 240 240) 3865.52px 668.146px,
    rgb(240 240 240) 2470.3px 548.726px, rgb(240 240 240) 2277.92px 478.217px,
    rgb(240 240 240) 1327.93px 2887.26px, rgb(240 240 240) 2353.27px 816.02px,
    rgb(240 240 240) 665.311px 1363.16px, rgb(240 240 240) 1204.57px 1195.72px,
    rgb(240 240 240) 208.928px 2477.29px, rgb(240 240 240) 671.602px 1069.87px,
    rgb(240 240 240) 1701.31px 400.62px, rgb(240 240 240) 674.334px 1547.3px,
    rgb(240 240 240) 1373.33px 608.383px, rgb(240 240 240) 1832.48px 3007.31px,
    rgb(240 240 240) 1279.3px 2867.61px, rgb(240 240 240) 1115.87px 3471.83px,
    rgb(240 240 240) 143.524px 2028.45px, rgb(240 240 240) 3381.52px 2457.91px,
    rgb(240 240 240) 3096.2px 777.223px, rgb(240 240 240) 2828.44px 510.706px,
    rgb(240 240 240) 3545.99px 585.2px, rgb(240 240 240) 2368.98px 1976.02px,
    rgb(240 240 240) 381.423px 215.253px, rgb(240 240 240) 268.463px 1865.4px,
    rgb(240 240 240) 746.029px 675.977px, rgb(240 240 240) 3842.5px 1289.72px,
    rgb(240 240 240) 1980.55px 738.838px, rgb(240 240 240) 1622.5px 1395.69px,
    rgb(240 240 240) 3920.54px 1253.56px, rgb(240 240 240) 900.005px 3733.92px,
    rgb(240 240 240) 1120.41px 1534.7px, rgb(240 240 240) 3269.76px 122.508px,
    rgb(240 240 240) 1758.61px 3236.77px, rgb(240 240 240) 3360.77px 106.974px,
    rgb(240 240 240) 2707.29px 2430px, rgb(240 240 240) 2166.03px 2909.94px,
    rgb(240 240 240) 2965.23px 738.583px, rgb(240 240 240) 630.515px 2266.69px,
    rgb(240 240 240) 3714.16px 2386.23px, rgb(240 240 240) 3505.48px 2079.01px,
    rgb(240 240 240) 3321.69px 3240.8px, rgb(240 240 240) 1156.83px 3296.29px,
    rgb(240 240 240) 2611.22px 3095.52px, rgb(240 240 240) 2641.98px 364.247px,
    rgb(240 240 240) 1444.66px 3406.22px, rgb(240 240 240) 1408.74px 1681.75px,
    rgb(240 240 240) 50.0166px 1197.04px, rgb(240 240 240) 1170.55px 2467.16px,
    rgb(240 240 240) 735.92px 1120.64px, rgb(240 240 240) 119.948px 3486.99px,
    rgb(240 240 240) 1358.52px 3689.28px, rgb(240 240 240) 1506.2px 2946.98px,
    rgb(240 240 240) 1278.42px 2302.61px, rgb(240 240 240) 2611.8px 738.396px,
    rgb(240 240 240) 2443.84px 981.075px, rgb(240 240 240) 55.2462px 3592.79px,
    rgb(240 240 240) 688.249px 2521.52px, rgb(240 240 240) 326.347px 1069.35px,
    rgb(240 240 240) 1885.05px 3885.34px, rgb(240 240 240) 1919.3px 1491.07px,
    rgb(240 240 240) 701.161px 713.094px, rgb(240 240 240) 1099.88px 871.549px,
    rgb(240 240 240) 3771.02px 103.115px, rgb(240 240 240) 1194.56px 1318.12px,
    rgb(240 240 240) 140.371px 2165.8px, rgb(240 240 240) 238.322px 2343.12px,
    rgb(240 240 240) 442.378px 2102.45px, rgb(240 240 240) 3987.24px 1315.68px,
    rgb(240 240 240) 2978.62px 816.585px, rgb(240 240 240) 3033.8px 746.85px,
    rgb(240 240 240) 2496.24px 1263.98px, rgb(240 240 240) 3518.52px 2495.12px,
    rgb(240 240 240) 1357.11px 1094.65px, rgb(240 240 240) 3320.95px 210.183px,
    rgb(240 240 240) 3754.88px 1140.17px, rgb(240 240 240) 1530.15px 3819.92px,
    rgb(240 240 240) 2640.54px 2523.4px, rgb(240 240 240) 340.766px 2118px,
    rgb(240 240 240) 2632.06px 2889.58px, rgb(240 240 240) 3051.82px 1183.56px,
    rgb(240 240 240) 2010.83px 3199.41px, rgb(240 240 240) 3190.33px 3074.96px,
    rgb(240 240 240) 2709.89px 678.247px, rgb(240 240 240) 634.006px 2297.52px,
    rgb(240 240 240) 204.672px 1886.54px, rgb(240 240 240) 1196.1px 3230.92px,
    rgb(240 240 240) 2335.56px 378.025px, rgb(240 240 240) 697.042px 1582.19px,
    rgb(240 240 240) 1129.13px 639.386px, rgb(240 240 240) 66.4456px 3147.8px,
    rgb(240 240 240) 3785.07px 2446.85px, rgb(240 240 240) 421.548px 999.336px,
    rgb(240 240 240) 3493.75px 3969.13px, rgb(240 240 240) 3693.49px 348.835px,
    rgb(240 240 240) 2643.11px 10.8928px, rgb(240 240 240) 3310.19px 3241.33px,
    rgb(240 240 240) 3152.65px 2933.57px, rgb(240 240 240) 2901.17px 920.773px,
    rgb(240 240 240) 1255.09px 1902.94px, rgb(240 240 240) 3589.34px 2960.43px,
    rgb(240 240 240) 1727.02px 768.026px, rgb(240 240 240) 256.319px 451.982px,
    rgb(240 240 240) 247.513px 282.963px, rgb(240 240 240) 3843.17px 144.341px,
    rgb(240 240 240) 476.997px 1620.56px, rgb(240 240 240) 2135.92px 328.815px,
    rgb(240 240 240) 677.849px 2353.61px, rgb(240 240 240) 2200.92px 130.232px,
    rgb(240 240 240) 603.92px 1848.83px, rgb(240 240 240) 2454.31px 1299.86px,
    rgb(240 240 240) 605.458px 2960.73px, rgb(240 240 240) 58.7138px 1174.38px,
    rgb(240 240 240) 771.691px 2174.31px, rgb(240 240 240) 2979.59px 3597.88px,
    rgb(240 240 240) 1508.69px 949.307px, rgb(240 240 240) 2550.82px 2810.98px,
    rgb(240 240 240) 889.353px 3421.49px, rgb(240 240 240) 2949.75px 2707.81px,
    rgb(240 240 240) 1205.03px 1962.16px, rgb(240 240 240) 2185.25px 1232.07px,
    rgb(240 240 240) 1043.08px 860.794px, rgb(240 240 240) 1149.47px 2345.13px,
    rgb(240 240 240) 986.279px 3244.92px, rgb(240 240 240) 2460.44px 673.699px,
    rgb(240 240 240) 3102.38px 1266.63px, rgb(240 240 240) 3634.67px 1957.63px,
    rgb(240 240 240) 3507.96px 2187.36px, rgb(240 240 240) 2817.17px 1885.83px,
    rgb(240 240 240) 2648.62px 2167.04px, rgb(240 240 240) 1538.14px 1745.07px,
    rgb(240 240 240) 266.834px 298.046px, rgb(240 240 240) 460.396px 2638.47px,
    rgb(240 240 240) 2436.31px 1513.44px, rgb(240 240 240) 1285.32px 1141.13px,
    rgb(240 240 240) 2022.92px 45.3472px, rgb(240 240 240) 3909.55px 2192.12px,
    rgb(240 240 240) 382.809px 1016.01px, rgb(240 240 240) 189.081px 628.396px,
    rgb(240 240 240) 2793.31px 3969.39px, rgb(240 240 240) 2809.21px 2738.59px,
    rgb(240 240 240) 1397.72px 136.653px, rgb(240 240 240) 3898.6px 3733.03px,
    rgb(240 240 240) 3772.26px 1688.1px, rgb(240 240 240) 2998.27px 1074.86px,
    rgb(240 240 240) 1730.24px 3600.08px, rgb(240 240 240) 2618.95px 3758.23px,
    rgb(240 240 240) 1339.09px 3129.61px, rgb(240 240 240) 3561.13px 2159.41px,
    rgb(240 240 240) 2826.02px 1427.88px, rgb(240 240 240) 1441.58px 3414.02px,
    rgb(240 240 240) 1873.93px 3023.35px, rgb(240 240 240) 1903.25px 2576.49px,
    rgb(240 240 240) 1638.24px 3878.9px, rgb(240 240 240) 2775.83px 328.914px,
    rgb(240 240 240) 2370.96px 712.531px, rgb(240 240 240) 716.458px 2613.04px,
    rgb(240 240 240) 2131.32px 3250.79px, rgb(240 240 240) 1848.48px 2758.96px,
    rgb(240 240 240) 3048.16px 1707.1px, rgb(240 240 240) 264.298px 2476.03px,
    rgb(240 240 240) 2779.55px 1136.75px, rgb(240 240 240) 2055.54px 3626.84px,
    rgb(240 240 240) 1646.67px 3464.89px, rgb(240 240 240) 177.13px 3175.22px,
    rgb(240 240 240) 2473.28px 968.161px, rgb(240 240 240) 3667.16px 2633.46px,
    rgb(240 240 240) 762.209px 2352.78px, rgb(240 240 240) 2525.79px 1982.14px,
    rgb(240 240 240) 874.974px 3738.89px, rgb(240 240 240) 1059.36px 509.831px,
    rgb(240 240 240) 980px 2814.07px, rgb(240 240 240) 539.938px 2209.1px,
    rgb(240 240 240) 282.302px 250.11px, rgb(240 240 240) 2689.28px 1753.95px,
    rgb(240 240 240) 2383.37px 523.444px, rgb(240 240 240) 2803.55px 1960.55px,
    rgb(240 240 240) 1626.35px 2422.84px, rgb(240 240 240) 3082.33px 1067.47px,
    rgb(240 240 240) 2259.63px 1883.59px, rgb(240 240 240) 2962.95px 948.912px,
    rgb(240 240 240) 499.734px 3182.81px, rgb(240 240 240) 2940.1px 3671.77px,
    rgb(240 240 240) 139.31px 2653.63px, rgb(240 240 240) 2375.59px 2644.23px,
    rgb(240 240 240) 1030.99px 1035.73px, rgb(240 240 240) 3503.82px 2864.73px,
    rgb(240 240 240) 2582.76px 2596.74px, rgb(240 240 240) 3940.62px 431.361px,
    rgb(240 240 240) 159.987px 1136.63px, rgb(240 240 240) 389.945px 2139.74px,
    rgb(240 240 240) 3269.53px 1403.67px, rgb(240 240 240) 1840.1px 1765.67px,
    rgb(240 240 240) 1620.98px 1149.67px, rgb(240 240 240) 1914.4px 2733.41px,
    rgb(240 240 240) 2497.45px 193.725px, rgb(240 240 240) 733.958px 2530.09px,
    rgb(240 240 240) 2353.2px 2311.95px, rgb(240 240 240) 1795.99px 1705.19px,
    rgb(240 240 240) 1993.85px 2491.29px, rgb(240 240 240) 3135.72px 2726.17px,
    rgb(240 240 240) 1978.76px 1989.9px, rgb(240 240 240) 2780.35px 2985.75px,
    rgb(240 240 240) 2804.21px 3363.95px, rgb(240 240 240) 2597.72px 2684.55px,
    rgb(240 240 240) 279.634px 3351.69px, rgb(240 240 240) 72.6571px 1452.28px,
    rgb(240 240 240) 3896.64px 1282.37px, rgb(240 240 240) 3052.75px 2814.28px,
    rgb(240 240 240) 2712.13px 2904.58px, rgb(240 240 240) 3401.83px 918.119px,
    rgb(240 240 240) 1525.37px 2634.55px, rgb(240 240 240) 2359.33px 3979.34px,
    rgb(240 240 240) 2316.56px 3570.15px, rgb(240 240 240) 3957.35px 3940.95px,
    rgb(240 240 240) 1496.13px 754.204px, rgb(240 240 240) 2935.19px 1960.69px,
    rgb(240 240 240) 2588.38px 1369.55px, rgb(240 240 240) 1561.14px 3534.2px,
    rgb(240 240 240) 3192.47px 907.956px, rgb(240 240 240) 1331.94px 1441.57px,
    rgb(240 240 240) 1012.2px 1050.94px, rgb(240 240 240) 1157.54px 1408.93px,
    rgb(240 240 240) 3823.84px 1697.67px, rgb(240 240 240) 3115.12px 1927.04px,
    rgb(240 240 240) 3099.19px 766.166px, rgb(240 240 240) 3879.29px 2171.28px,
    rgb(240 240 240) 3751.6px 360.225px, rgb(240 240 240) 486.489px 557.207px,
    rgb(240 240 240) 2265.35px 1136.92px, rgb(240 240 240) 3770.23px 1417.33px,
    rgb(240 240 240) 999.308px 3827.54px, rgb(240 240 240) 1292.53px 555.705px,
    rgb(240 240 240) 1524.33px 2999.63px, rgb(240 240 240) 3797.36px 1003.16px,
    rgb(240 240 240) 2612.19px 2110.21px, rgb(240 240 240) 3016.88px 866.398px,
    rgb(240 240 240) 868.266px 3799.83px, rgb(240 240 240) 244.833px 3169.48px,
    rgb(240 240 240) 2868.41px 900.03px, rgb(240 240 240) 956.741px 153.255px,
    rgb(240 240 240) 1440.7px 1367.12px, rgb(240 240 240) 1553.32px 914.857px,
    rgb(240 240 240) 2951.21px 3927.23px, rgb(240 240 240) 1700.4px 1977.15px,
    rgb(240 240 240) 382.423px 343.802px, rgb(240 240 240) 2374.03px 392.771px,
    rgb(240 240 240) 1042.6px 3990.27px, rgb(240 240 240) 2021.27px 1667.32px,
    rgb(240 240 240) 615.767px 1728.58px, rgb(240 240 240) 3031.51px 2607.49px,
    rgb(240 240 240) 2587.54px 2317.97px, rgb(240 240 240) 1863.84px 2421.63px,
    rgb(240 240 240) 1175.54px 549.462px, rgb(240 240 240) 1346.44px 3132.02px,
    rgb(240 240 240) 3763.61px 2285.81px, rgb(240 240 240) 2709.61px 2392.15px,
    rgb(240 240 240) 2134.16px 2644.7px, rgb(240 240 240) 598.091px 651.131px,
    rgb(240 240 240) 3630.72px 338.953px, rgb(240 240 240) 297.143px 713.459px,
    rgb(240 240 240) 184.064px 1498.97px, rgb(240 240 240) 2168.8px 668.384px,
    rgb(240 240 240) 247.117px 2951.47px, rgb(240 240 240) 2247.45px 1944.45px,
    rgb(240 240 240) 3709.59px 611.636px, rgb(240 240 240) 1586.63px 1356.27px,
    rgb(240 240 240) 1819.64px 1123.75px, rgb(240 240 240) 2988.13px 2868.77px,
    rgb(240 240 240) 2823.68px 3881.89px, rgb(240 240 240) 2889.07px 424.433px,
    rgb(240 240 240) 2205px 1566.1px, rgb(240 240 240) 437.486px 1825.06px,
    rgb(240 240 240) 1856.18px 747.734px, rgb(240 240 240) 956.49px 345.89px,
    rgb(240 240 240) 3448.03px 3453.81px, rgb(240 240 240) 2069.34px 2593.12px,
    rgb(240 240 240) 2315.91px 2325.02px, rgb(240 240 240) 2016.48px 3988.64px,
    rgb(240 240 240) 3985.85px 944.925px, rgb(240 240 240) 2242.78px 1928.24px,
    rgb(240 240 240) 2235.81px 1803.56px, rgb(240 240 240) 1828.14px 1043.72px,
    rgb(240 240 240) 309.337px 2726.87px, rgb(240 240 240) 265.657px 2224.73px,
    rgb(240 240 240) 923.531px 286.327px, rgb(240 240 240) 3786.47px 3492.45px,
    rgb(240 240 240) 2117.97px 221.528px, rgb(240 240 240) 2353.18px 3793.8px,
    rgb(240 240 240) 3787.93px 1831.41px, rgb(240 240 240) 909.456px 3374.45px,
    rgb(240 240 240) 3818.67px 2225.53px, rgb(240 240 240) 1133.59px 472.712px,
    rgb(240 240 240) 538.914px 1026.04px, rgb(240 240 240) 2911.3px 2696.85px,
    rgb(240 240 240) 1364.25px 3842.44px, rgb(240 240 240) 3073.14px 254.382px,
    rgb(240 240 240) 1462.67px 83.9021px, rgb(240 240 240) 2067.12px 3938.9px,
    rgb(240 240 240) 273.354px 954.861px, rgb(240 240 240) 2811.43px 3936.07px,
    rgb(240 240 240) 2559.57px 2384.59px, rgb(240 240 240) 2993.74px 807.301px,
    rgb(240 240 240) 1198.3px 2052.22px, rgb(240 240 240) 3662.56px 3816.89px,
    rgb(240 240 240) 2467.59px 1404.75px, rgb(240 240 240) 3757.87px 2919.2px,
    rgb(240 240 240) 1268.6px 1847.92px, rgb(240 240 240) 3602.33px 3242.32px,
    rgb(240 240 240) 3474.09px 2058px, rgb(240 240 240) 3882.03px 2071.82px,
    rgb(240 240 240) 3505.12px 419.55px, rgb(240 240 240) 3469.28px 2295.43px,
    rgb(240 240 240) 2910.65px 1867.06px, rgb(240 240 240) 1014.43px 946.46px,
    rgb(240 240 240) 3421.78px 575.036px, rgb(240 240 240) 876.132px 1888.53px,
    rgb(240 240 240) 1291.62px 817.606px, rgb(240 240 240) 3485.24px 1640.38px,
    rgb(240 240 240) 883.471px 2267.59px, rgb(240 240 240) 3738.68px 2992.11px,
    rgb(240 240 240) 2622.08px 1956.52px, rgb(240 240 240) 1217.48px 3043.29px,
    rgb(240 240 240) 483.937px 1756.22px, rgb(240 240 240) 870.815px 2236.33px,
    rgb(240 240 240) 2071.97px 993.824px, rgb(240 240 240) 3194.72px 2287.39px,
    rgb(240 240 240) 1405.41px 2659.65px, rgb(240 240 240) 1979.94px 45.1798px,
    rgb(240 240 240) 3235.18px 920.913px, rgb(240 240 240) 2669.19px 87.3769px,
    rgb(240 240 240) 53.9292px 977.978px, rgb(240 240 240) 1578.07px 818.489px,
    rgb(240 240 240) 330.568px 3986.06px, rgb(240 240 240) 3514.41px 520.397px,
    rgb(240 240 240) 96.1299px 3553.57px, rgb(240 240 240) 635.96px 1319.06px,
    rgb(240 240 240) 2326.9px 3293.92px, rgb(240 240 240) 1492.3px 1313.98px,
    rgb(240 240 240) 3064.42px 559.641px, rgb(240 240 240) 858.182px 3796.12px,
    rgb(240 240 240) 2395px 860.402px, rgb(240 240 240) 3214.61px 3257.9px,
    rgb(240 240 240) 3308.58px 257.49px, rgb(240 240 240) 3391.78px 3301.92px,
    rgb(240 240 240) 3648.57px 3122.79px, rgb(240 240 240) 1575.65px 8.65593px,
    rgb(240 240 240) 2179.33px 1015.71px, rgb(240 240 240) 57.5542px 2200.03px,
    rgb(240 240 240) 217.517px 826.718px, rgb(240 240 240) 3677.82px 2007.18px,
    rgb(240 240 240) 1970.86px 2318.45px, rgb(240 240 240) 1910.99px 643.395px,
    rgb(240 240 240) 1610.81px 119.367px, rgb(240 240 240) 3992.3px 2642.61px,
    rgb(240 240 240) 3512.78px 1931.99px, rgb(240 240 240) 2311.7px 1189.44px,
    rgb(240 240 240) 1890.66px 3266.83px, rgb(240 240 240) 1465.04px 3420.43px,
    rgb(240 240 240) 1287.76px 872.182px, rgb(240 240 240) 1180.91px 2607.15px,
    rgb(240 240 240) 246.92px 2343.01px, rgb(240 240 240) 363.771px 2927.14px,
    rgb(240 240 240) 1934.74px 3615.28px, rgb(240 240 240) 428.952px 3542.24px,
    rgb(240 240 240) 1397.31px 3566.37px, rgb(240 240 240) 1534.72px 3838.11px,
    rgb(240 240 240) 2446.23px 1929.87px, rgb(240 240 240) 2698.32px 3781.48px,
    rgb(240 240 240) 3658.16px 111.917px, rgb(240 240 240) 1841.6px 2873.69px,
    rgb(240 240 240) 3910.2px 480.712px, rgb(240 240 240) 1207.35px 3084.02px,
    rgb(240 240 240) 1454.39px 2311.72px, rgb(240 240 240) 2513.73px 2696.69px,
    rgb(240 240 240) 3894.49px 2097.95px, rgb(240 240 240) 2966.3px 2092.75px,
    rgb(240 240 240) 1679.61px 628.409px, rgb(240 240 240) 2043.54px 2589.85px,
    rgb(240 240 240) 292.662px 942.388px, rgb(240 240 240) 633.993px 1504.4px,
    rgb(240 240 240) 680.903px 3222.6px, rgb(240 240 240) 590.332px 1041.8px,
    rgb(240 240 240) 1205.94px 873.986px, rgb(240 240 240) 105.359px 2405.46px,
    rgb(240 240 240) 2340.01px 971.333px, rgb(240 240 240) 1803.32px 3083.8px,
    rgb(240 240 240) 3871.2px 2129.52px, rgb(240 240 240) 2643.37px 1185.29px,
    rgb(240 240 240) 3288.12px 1685.99px, rgb(240 240 240) 2172.9px 2226.05px,
    rgb(240 240 240) 3537.98px 2694.8px, rgb(240 240 240) 256.041px 2634.36px,
    rgb(240 240 240) 119.528px 3306.33px, rgb(240 240 240) 874.712px 461.025px,
    rgb(240 240 240) 2039.81px 3552.81px, rgb(240 240 240) 54.0512px 575.815px,
    rgb(240 240 240) 583.098px 2980.63px, rgb(240 240 240) 2377.52px 2792.93px;
  ${({ duration, flowDown }) => css`
    animation: ${duration}s linear 0s infinite normal none running
      ${flowDown ? flowDownAnim : flowUpAnim};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 0.5px;
    height: 0.5px;
    background: transparent;
    box-shadow: rgb(240 240 240) 2587.12px 1295.03px,
      rgb(240 240 240) 3501.95px 3818.53px, rgb(240 240 240) 827.149px 1631.98px,
      rgb(240 240 240) 2232.54px 3981px, rgb(240 240 240) 2175.85px 1787.49px,
      rgb(240 240 240) 3260.2px 2155.74px, rgb(240 240 240) 1393.79px 1988.9px,
      rgb(240 240 240) 3310.92px 799.93px, rgb(240 240 240) 1215.03px 3259.48px,
      rgb(240 240 240) 3532.21px 2830.04px, rgb(240 240 240) 662.699px 3616.84px,
      rgb(240 240 240) 3378.12px 1767.32px, rgb(240 240 240) 856.393px 2998.29px,
      rgb(240 240 240) 688.552px 3342.09px, rgb(240 240 240) 3518.82px 3419px,
      rgb(240 240 240) 1604.08px 31.0397px, rgb(240 240 240) 3027.51px 1498.39px,
      rgb(240 240 240) 2154.63px 3457.04px, rgb(240 240 240) 2614.16px 3228.02px,
      rgb(240 240 240) 2555.23px 3843.45px, rgb(240 240 240) 2154.7px 1298.49px,
      rgb(240 240 240) 2303.48px 1361.85px, rgb(240 240 240) 297.961px 1798.69px,
      rgb(240 240 240) 2095.87px 1809.52px, rgb(240 240 240) 1354.03px 2211.66px,
      rgb(240 240 240) 578.475px 3124.76px, rgb(240 240 240) 3131.18px 2455.53px,
      rgb(240 240 240) 635.547px 530.443px, rgb(240 240 240) 2251.87px 2259.17px,
      rgb(240 240 240) 1422.62px 3700.57px, rgb(240 240 240) 1775.96px 3595.51px,
      rgb(240 240 240) 3967.99px 1358.11px, rgb(240 240 240) 3772.25px 1442.7px,
      rgb(240 240 240) 1745.64px 1088.1px, rgb(240 240 240) 1219.09px 154.521px,
      rgb(240 240 240) 1669.46px 3668.07px, rgb(240 240 240) 2287.57px 3098.09px,
      rgb(240 240 240) 2058.34px 3489.25px, rgb(240 240 240) 704.208px 3929.98px,
      rgb(240 240 240) 3495.92px 3523.91px, rgb(240 240 240) 329.549px 3198.46px,
      rgb(240 240 240) 93.7419px 1882.17px, rgb(240 240 240) 2739px 99.7405px,
      rgb(240 240 240) 401.464px 2457.04px, rgb(240 240 240) 55.2605px 2896.48px,
      rgb(240 240 240) 1184.98px 1302.53px, rgb(240 240 240) 307.678px 1806.6px,
      rgb(240 240 240) 542.553px 1849.28px, rgb(240 240 240) 3549.79px 183.161px,
      rgb(240 240 240) 1430.02px 2132.6px, rgb(240 240 240) 3552.48px 1559.27px,
      rgb(240 240 240) 3585.29px 1985.19px, rgb(240 240 240) 548.568px 1297.8px,
      rgb(240 240 240) 1517.73px 1009.59px, rgb(240 240 240) 1607.2px 3383.87px,
      rgb(240 240 240) 2608.52px 232.004px, rgb(240 240 240) 1772.98px 2872.69px,
      rgb(240 240 240) 2107.16px 261.023px, rgb(240 240 240) 378.502px 201.457px,
      rgb(240 240 240) 3368.66px 1937.17px, rgb(240 240 240) 543.664px 3882.65px,
      rgb(240 240 240) 3191.86px 1824.74px, rgb(240 240 240) 3227.66px 1771.02px,
      rgb(240 240 240) 234.45px 2388.45px, rgb(240 240 240) 381.646px 3256.85px,
      rgb(240 240 240) 3782.99px 1583.1px, rgb(240 240 240) 970.477px 2770.75px,
      rgb(240 240 240) 1354.37px 2921.67px, rgb(240 240 240) 847.631px 2479.91px,
      rgb(240 240 240) 731.178px 2410.64px, rgb(240 240 240) 389.563px 1690.46px,
      rgb(240 240 240) 1987.26px 44.7961px, rgb(240 240 240) 419.926px 3806.22px,
      rgb(240 240 240) 1740.96px 183.591px, rgb(240 240 240) 2917.11px 3942.51px,
      rgb(240 240 240) 1954.79px 1545.58px, rgb(240 240 240) 1621.78px 2482.56px,
      rgb(240 240 240) 3933.57px 2697.29px, rgb(240 240 240) 1906.86px 2373.44px,
      rgb(240 240 240) 3047.78px 1194.84px, rgb(240 240 240) 2285.37px 2951.62px,
      rgb(240 240 240) 895.091px 1248.33px, rgb(240 240 240) 3127.68px 3858.93px,
      rgb(240 240 240) 3953.15px 2786.17px, rgb(240 240 240) 2102.03px 3988.91px,
      rgb(240 240 240) 3053.97px 1858.08px, rgb(240 240 240) 2702.52px 2586.83px,
      rgb(240 240 240) 1490.95px 2039.37px, rgb(240 240 240) 3385.98px 3134.46px,
      rgb(240 240 240) 360.605px 1978.43px, rgb(240 240 240) 3544.83px 3459.78px,
      rgb(240 240 240) 1776.35px 1920.05px, rgb(240 240 240) 852.834px 1759.67px,
      rgb(240 240 240) 2835.27px 2626.62px, rgb(240 240 240) 399.676px 858.102px,
      rgb(240 240 240) 1159.07px 413.734px, rgb(240 240 240) 3948.24px 1375.9px,
      rgb(240 240 240) 678.209px 2167.19px, rgb(240 240 240) 3099.3px 2993.39px,
      rgb(240 240 240) 3448.96px 2042.94px, rgb(240 240 240) 3703.17px 3192.84px,
      rgb(240 240 240) 3014.28px 2007.1px, rgb(240 240 240) 2332.91px 1312.87px,
      rgb(240 240 240) 3744.28px 3138.14px, rgb(240 240 240) 1230.02px 243.438px,
      rgb(240 240 240) 924.305px 3619.93px, rgb(240 240 240) 2663.26px 2137.25px,
      rgb(240 240 240) 222.652px 985.116px, rgb(240 240 240) 3378.23px 632.987px,
      rgb(240 240 240) 2475.16px 3397.4px, rgb(240 240 240) 159.109px 1654.23px,
      rgb(240 240 240) 2211.45px 424.692px, rgb(240 240 240) 2322.8px 298.991px,
      rgb(240 240 240) 3357.63px 2458.47px, rgb(240 240 240) 2717.75px 1417.4px,
      rgb(240 240 240) 1575.96px 1203.68px, rgb(240 240 240) 3783.66px 130.722px,
      rgb(240 240 240) 2304.12px 1770.2px, rgb(240 240 240) 306.195px 2193.98px,
      rgb(240 240 240) 1877.7px 2852.21px, rgb(240 240 240) 800.945px 2309.89px,
      rgb(240 240 240) 634.125px 1792.25px, rgb(240 240 240) 1870.76px 1925.05px,
      rgb(240 240 240) 178.982px 3622.84px, rgb(240 240 240) 2456.18px 3503.43px,
      rgb(240 240 240) 1749.38px 3812.98px, rgb(240 240 240) 2077.1px 1191.94px,
      rgb(240 240 240) 386.31px 2188.85px, rgb(240 240 240) 2956.78px 1100.09px,
      rgb(240 240 240) 1235.6px 454.292px, rgb(240 240 240) 3866.43px 2708.78px,
      rgb(240 240 240) 3299.32px 3246.79px, rgb(240 240 240) 1889.84px 531.592px,
      rgb(240 240 240) 1121.39px 3680.7px, rgb(240 240 240) 2196.29px 1042.66px,
      rgb(240 240 240) 1473.7px 3208.72px, rgb(240 240 240) 1290.91px 2847.86px,
      rgb(240 240 240) 2711.15px 1155.01px, rgb(240 240 240) 1112.44px 1556.85px,
      rgb(240 240 240) 1975.08px 3868.63px, rgb(240 240 240) 2218.77px 3461.84px,
      rgb(240 240 240) 2461.99px 3660.05px, rgb(240 240 240) 3976.6px 1864.19px,
      rgb(240 240 240) 328.536px 702.639px, rgb(240 240 240) 834.304px 131.84px,
      rgb(240 240 240) 1380.38px 626.041px, rgb(240 240 240) 2188.78px 3179.88px,
      rgb(240 240 240) 3546.73px 863.026px, rgb(240 240 240) 1360.75px 3973.73px,
      rgb(240 240 240) 1652.05px 2028.5px, rgb(240 240 240) 1582.43px 3665.62px,
      rgb(240 240 240) 2004.63px 1368.67px, rgb(240 240 240) 2084.5px 1267.85px,
      rgb(240 240 240) 2095.68px 1216.21px, rgb(240 240 240) 760.843px 2871.35px,
      rgb(240 240 240) 2478.28px 2668.52px, rgb(240 240 240) 261.767px 2450.97px,
      rgb(240 240 240) 3271.76px 3086.3px, rgb(240 240 240) 1963.74px 3958.06px,
      rgb(240 240 240) 3474.56px 1660.02px, rgb(240 240 240) 3362.78px 1331.15px,
      rgb(240 240 240) 3348.65px 327.45px, rgb(240 240 240) 47.1882px 2467.56px,
      rgb(240 240 240) 635.659px 651.27px, rgb(240 240 240) 1405.58px 526.146px,
      rgb(240 240 240) 2717.78px 2231.82px, rgb(240 240 240) 2990.51px 1577.78px,
      rgb(240 240 240) 3791.92px 398.939px, rgb(240 240 240) 1611.33px 2428.84px,
      rgb(240 240 240) 105.899px 850.983px, rgb(240 240 240) 488.574px 380.325px,
      rgb(240 240 240) 3422.92px 2024.13px, rgb(240 240 240) 574.381px 957.016px,
      rgb(240 240 240) 1550.69px 128.773px, rgb(240 240 240) 3893.81px 402.818px,
      rgb(240 240 240) 2665.79px 3832.03px, rgb(240 240 240) 2166.26px 2601.12px,
      rgb(240 240 240) 986.47px 1044.96px, rgb(240 240 240) 1790.33px 2019.83px,
      rgb(240 240 240) 1202.98px 3908.07px, rgb(240 240 240) 3274.45px 3317.9px,
      rgb(240 240 240) 3447.02px 3939.37px, rgb(240 240 240) 1554.89px 3014.46px,
      rgb(240 240 240) 3802.77px 3014.39px, rgb(240 240 240) 2357.78px 1188.5px,
      rgb(240 240 240) 1470.66px 3592.8px, rgb(240 240 240) 2476.5px 1201.2px,
      rgb(240 240 240) 2929.27px 3063.75px, rgb(240 240 240) 964.277px 1285.08px,
      rgb(240 240 240) 853.292px 778.218px, rgb(240 240 240) 3767.17px 9.58343px,
      rgb(240 240 240) 3047.95px 2395.51px, rgb(240 240 240) 360.242px 3958.18px,
      rgb(240 240 240) 2678.56px 2375.66px, rgb(240 240 240) 2936.91px 2883.49px,
      rgb(240 240 240) 1789.51px 736.296px, rgb(240 240 240) 732.934px 1861.22px,
      rgb(240 240 240) 3443.87px 3947.19px, rgb(240 240 240) 136.984px 717.566px,
      rgb(240 240 240) 1430.17px 3823.94px, rgb(240 240 240) 2499.11px 196.322px,
      rgb(240 240 240) 3231.85px 2771.52px, rgb(240 240 240) 1443.99px 2028.59px,
      rgb(240 240 240) 835.569px 3953.72px, rgb(240 240 240) 1245.36px 11.423px,
      rgb(240 240 240) 3765.61px 807.958px, rgb(240 240 240) 3100.36px 969.49px,
      rgb(240 240 240) 197.36px 1855.28px, rgb(240 240 240) 3064.48px 776.429px,
      rgb(240 240 240) 969.573px 2444.7px, rgb(240 240 240) 878.274px 1498.65px,
      rgb(240 240 240) 2618.12px 914.027px, rgb(240 240 240) 1722.53px 3867.24px,
      rgb(240 240 240) 3179.72px 2032.34px, rgb(240 240 240) 240.638px 1416.11px,
      rgb(240 240 240) 3095.91px 559.663px, rgb(240 240 240) 186.497px 999.895px,
      rgb(240 240 240) 3434.37px 217.906px, rgb(240 240 240) 3617.73px 3449.23px,
      rgb(240 240 240) 1871.72px 3741.08px, rgb(240 240 240) 2777.5px 2851.59px,
      rgb(240 240 240) 2813.4px 1287px, rgb(240 240 240) 3270.28px 300.903px,
      rgb(240 240 240) 3364.53px 1563.15px, rgb(240 240 240) 2313.02px 3173.9px,
      rgb(240 240 240) 2760.6px 2919.5px, rgb(240 240 240) 1666.48px 528.132px,
      rgb(240 240 240) 3861.97px 812.827px, rgb(240 240 240) 218.654px 1187.13px,
      rgb(240 240 240) 3394.79px 1590.81px, rgb(240 240 240) 1655.09px 3939.94px,
      rgb(240 240 240) 3155.8px 3613.31px, rgb(240 240 240) 1958.69px 3925.19px,
      rgb(240 240 240) 2890.36px 2602.11px, rgb(240 240 240) 1937.31px 238.837px,
      rgb(240 240 240) 2393.06px 3484.2px, rgb(240 240 240) 3219.82px 117.489px,
      rgb(240 240 240) 648.089px 1704.35px, rgb(240 240 240) 2037.1px 216.022px,
      rgb(240 240 240) 2331.72px 786.483px, rgb(240 240 240) 2774.33px 3834.39px,
      rgb(240 240 240) 23.1411px 3209.23px, rgb(240 240 240) 1102.71px 1158.76px,
      rgb(240 240 240) 1988.06px 2027.52px, rgb(240 240 240) 343.871px 326.941px,
      rgb(240 240 240) 2080.17px 2413.44px, rgb(240 240 240) 106.566px 155.987px,
      rgb(240 240 240) 2252.03px 1419.3px, rgb(240 240 240) 103.841px 574.221px,
      rgb(240 240 240) 2006.79px 1749.86px, rgb(240 240 240) 2525.49px 1779.29px,
      rgb(240 240 240) 1969.88px 304.633px, rgb(240 240 240) 1498.53px 2530.16px,
      rgb(240 240 240) 1726.92px 2005.77px, rgb(240 240 240) 444.111px 1072.05px,
      rgb(240 240 240) 2263.28px 125.802px, rgb(240 240 240) 1500.17px 3526.45px,
      rgb(240 240 240) 2119.1px 1225.46px, rgb(240 240 240) 2830.53px 2546.05px,
      rgb(240 240 240) 823.636px 96.7582px, rgb(240 240 240) 1297.02px 616.092px,
      rgb(240 240 240) 1817.65px 2671.86px, rgb(240 240 240) 3879.67px 742.154px,
      rgb(240 240 240) 2483.23px 1893.65px, rgb(240 240 240) 907.194px 3884.1px,
      rgb(240 240 240) 232.418px 2075.05px, rgb(240 240 240) 2371.71px 169.96px,
      rgb(240 240 240) 1087.86px 2890.61px, rgb(240 240 240) 3993.52px 872.77px,
      rgb(240 240 240) 1713.94px 229.463px, rgb(240 240 240) 1702.6px 1497.99px,
      rgb(240 240 240) 2054.87px 3177.2px, rgb(240 240 240) 1672.8px 985.286px,
      rgb(240 240 240) 2754.52px 262.529px, rgb(240 240 240) 3800.7px 356.917px,
      rgb(240 240 240) 3792.39px 3159.11px, rgb(240 240 240) 126.579px 3737.16px,
      rgb(240 240 240) 438.749px 3039.14px, rgb(240 240 240) 198.354px 2681.34px,
      rgb(240 240 240) 2163.27px 1076.08px, rgb(240 240 240) 391.899px 3772.91px,
      rgb(240 240 240) 3647.97px 1662.2px, rgb(240 240 240) 3789.35px 1550.22px,
      rgb(240 240 240) 917.922px 236.283px, rgb(240 240 240) 2334.16px 2992.22px,
      rgb(240 240 240) 779.052px 191.333px, rgb(240 240 240) 393.006px 3345.46px,
      rgb(240 240 240) 2990.4px 2388.99px, rgb(240 240 240) 1088.38px 87.9893px,
      rgb(240 240 240) 3424.61px 2347.24px, rgb(240 240 240) 2198.33px 793.148px,
      rgb(240 240 240) 2211.11px 2481.79px, rgb(240 240 240) 2051.14px 3651.23px,
      rgb(240 240 240) 2621.6px 2711.99px, rgb(240 240 240) 158.857px 231.192px,
      rgb(240 240 240) 3225.16px 2010.56px, rgb(240 240 240) 3045.48px 3422.64px,
      rgb(240 240 240) 1600.83px 634.441px, rgb(240 240 240) 2622.3px 3650.93px,
      rgb(240 240 240) 3573.04px 1898.57px, rgb(240 240 240) 2153.85px 308.281px,
      rgb(240 240 240) 1499.87px 2891.86px, rgb(240 240 240) 1467.17px 1959.89px,
      rgb(240 240 240) 107.367px 2523.3px, rgb(240 240 240) 126.174px 3342.55px,
      rgb(240 240 240) 1779.69px 1139.39px, rgb(240 240 240) 2188.09px 1655.43px,
      rgb(240 240 240) 59.1097px 2264.49px, rgb(240 240 240) 2812.66px 381.745px,
      rgb(240 240 240) 2153.59px 2570.07px, rgb(240 240 240) 2173.16px 673.119px,
      rgb(240 240 240) 3697.97px 392.966px, rgb(240 240 240) 1466.88px 1964.65px,
      rgb(240 240 240) 2997.67px 2699.66px, rgb(240 240 240) 892.014px 3593.67px,
      rgb(240 240 240) 3553.9px 3737.94px, rgb(240 240 240) 3668.07px 3413.4px,
      rgb(240 240 240) 3550.31px 2531.02px, rgb(240 240 240) 3896.33px 1904.33px,
      rgb(240 240 240) 303.538px 2468.55px, rgb(240 240 240) 3481.62px 349.182px,
      rgb(240 240 240) 3460.55px 2372.18px, rgb(240 240 240) 3843.1px 1462.34px,
      rgb(240 240 240) 2706.01px 1390.77px, rgb(240 240 240) 3036.74px 3032.75px,
      rgb(240 240 240) 1389px 429.238px, rgb(240 240 240) 526.402px 2155.43px,
      rgb(240 240 240) 1891.01px 3000.8px, rgb(240 240 240) 402.855px 3124.13px,
      rgb(240 240 240) 1511.34px 2142.98px, rgb(240 240 240) 3257.74px 2774.37px,
      rgb(240 240 240) 2574.54px 1319.28px, rgb(240 240 240) 3688.67px 3290.42px,
      rgb(240 240 240) 3510.44px 2181px, rgb(240 240 240) 3721.3px 2021.59px,
      rgb(240 240 240) 3473.76px 3390.86px, rgb(240 240 240) 998.447px 3651.9px,
      rgb(240 240 240) 1276.17px 3391.57px, rgb(240 240 240) 1545.44px 1363.77px,
      rgb(240 240 240) 928.956px 1903.41px, rgb(240 240 240) 12.8939px 1391.44px,
      rgb(240 240 240) 3701.93px 783.032px, rgb(240 240 240) 2784.1px 2410.95px,
      rgb(240 240 240) 3210.2px 1798.61px, rgb(240 240 240) 3813.37px 2383.56px,
      rgb(240 240 240) 2025.63px 1741.08px, rgb(240 240 240) 3424.54px 55.6564px,
      rgb(240 240 240) 2473.14px 2623.9px, rgb(240 240 240) 831.424px 2278.03px,
      rgb(240 240 240) 765.08px 3455.28px, rgb(240 240 240) 1986.91px 3457.44px,
      rgb(240 240 240) 3751.37px 2620.75px, rgb(240 240 240) 2630.53px 1225.42px,
      rgb(240 240 240) 1499.24px 3370px, rgb(240 240 240) 2983.26px 349.474px,
      rgb(240 240 240) 3262.09px 2764.1px, rgb(240 240 240) 2416.82px 2251.72px,
      rgb(240 240 240) 2398.27px 1499.25px, rgb(240 240 240) 2743.47px 879.883px,
      rgb(240 240 240) 3919.76px 3435.62px, rgb(240 240 240) 401.841px 2549.38px,
      rgb(240 240 240) 3729.34px 3895.65px, rgb(240 240 240) 1487.93px 1750.34px,
      rgb(240 240 240) 498.304px 2982.62px, rgb(240 240 240) 1595.64px 242.948px,
      rgb(240 240 240) 3266.04px 520.823px, rgb(240 240 240) 3143.83px 2534.88px,
      rgb(240 240 240) 1133.59px 209.165px, rgb(240 240 240) 3392.02px 3356.12px,
      rgb(240 240 240) 3631.48px 3505.48px, rgb(240 240 240) 3410.41px 3933.97px,
      rgb(240 240 240) 1739.75px 2306.64px, rgb(240 240 240) 3395.8px 3430.86px,
      rgb(240 240 240) 493.071px 3553.14px, rgb(240 240 240) 3945.64px 3512.47px,
      rgb(240 240 240) 3822.16px 2679.31px, rgb(240 240 240) 601.399px 1026.71px,
      rgb(240 240 240) 40.8027px 2763.92px, rgb(240 240 240) 821.036px 257.989px,
      rgb(240 240 240) 3412.75px 1768.48px, rgb(240 240 240) 1897.9px 1603.33px,
      rgb(240 240 240) 3506.19px 2761.41px, rgb(240 240 240) 2442.46px 760.046px,
      rgb(240 240 240) 3564.12px 1909.77px, rgb(240 240 240) 640.926px 3760.38px,
      rgb(240 240 240) 8.99792px 873.453px, rgb(240 240 240) 3792.13px 153.388px,
      rgb(240 240 240) 914.778px 819.383px, rgb(240 240 240) 920.921px 166.905px,
      rgb(240 240 240) 2189.29px 2694.29px, rgb(240 240 240) 2627.68px 487.379px,
      rgb(240 240 240) 2669.22px 426.199px, rgb(240 240 240) 2979.07px 2691.65px,
      rgb(240 240 240) 1801.27px 1602.1px, rgb(240 240 240) 868.726px 313.562px,
      rgb(240 240 240) 451.125px 1746.91px, rgb(240 240 240) 3312.17px 3642.32px,
      rgb(240 240 240) 1399.95px 2379.59px, rgb(240 240 240) 1216.55px 3835.59px,
      rgb(240 240 240) 1981.1px 3786.9px, rgb(240 240 240) 707.31px 2155.67px,
      rgb(240 240 240) 2641.09px 1993.92px, rgb(240 240 240) 334.345px 789.988px,
      rgb(240 240 240) 1236.88px 3115.88px, rgb(240 240 240) 1831.38px 3279.26px,
      rgb(240 240 240) 2915.74px 2921.63px, rgb(240 240 240) 3960.17px 2336.72px,
      rgb(240 240 240) 1318.63px 526.331px, rgb(240 240 240) 1176.79px 2266.45px,
      rgb(240 240 240) 2175.33px 232.01px, rgb(240 240 240) 1757.79px 1749.46px,
      rgb(240 240 240) 3921.4px 2805.98px, rgb(240 240 240) 3846.48px 1150.06px,
      rgb(240 240 240) 171.578px 3321.68px, rgb(240 240 240) 3457.1px 3768.25px,
      rgb(240 240 240) 947.593px 1537.35px, rgb(240 240 240) 3404.81px 1337.98px,
      rgb(240 240 240) 1952.39px 2046.84px, rgb(240 240 240) 257.332px 426.581px,
      rgb(240 240 240) 446.805px 2181.85px, rgb(240 240 240) 3624.07px 56.7701px,
      rgb(240 240 240) 1083.55px 3975.55px, rgb(240 240 240) 2911.93px 3091.72px,
      rgb(240 240 240) 3984.59px 50.0061px, rgb(240 240 240) 3493.9px 3634.34px,
      rgb(240 240 240) 3017.83px 2396.14px, rgb(240 240 240) 117.967px 1440.11px,
      rgb(240 240 240) 251.063px 1666.43px, rgb(240 240 240) 3726.72px 2808.92px,
      rgb(240 240 240) 3727.45px 738.335px, rgb(240 240 240) 1801.56px 2693.81px,
      rgb(240 240 240) 691.598px 426.786px, rgb(240 240 240) 3009.36px 554.987px,
      rgb(240 240 240) 61.131px 1598.59px, rgb(240 240 240) 1445.89px 2385.45px,
      rgb(240 240 240) 1557.73px 494.737px, rgb(240 240 240) 2958.6px 2145.6px,
      rgb(240 240 240) 2941.11px 611.66px, rgb(240 240 240) 3789.9px 2961.01px,
      rgb(240 240 240) 3981px 2012.84px, rgb(240 240 240) 2972.77px 1051.74px,
      rgb(240 240 240) 3491.78px 2619.16px, rgb(240 240 240) 1827.16px 2846.61px,
      rgb(240 240 240) 525.29px 2388.96px, rgb(240 240 240) 720.545px 1960.66px,
      rgb(240 240 240) 1739.11px 1714.25px, rgb(240 240 240) 130.262px 3292.16px,
      rgb(240 240 240) 1529.02px 1090.74px, rgb(240 240 240) 3539.33px 3146.36px,
      rgb(240 240 240) 297.122px 230.382px, rgb(240 240 240) 1246.84px 431.306px,
      rgb(240 240 240) 3987.4px 1567.98px, rgb(240 240 240) 10.3496px 1460.82px,
      rgb(240 240 240) 3708.63px 1015.71px, rgb(240 240 240) 3868.38px 2379.53px,
      rgb(240 240 240) 173.837px 781.544px, rgb(240 240 240) 3262.66px 1890.13px,
      rgb(240 240 240) 558.275px 3729.74px, rgb(240 240 240) 2037.57px 3897.06px,
      rgb(240 240 240) 169.666px 426.055px, rgb(240 240 240) 2522.19px 2927.76px,
      rgb(240 240 240) 2808.38px 951.55px, rgb(240 240 240) 138.571px 2086.03px,
      rgb(240 240 240) 1504.84px 3432.92px, rgb(240 240 240) 1250.69px 1620.38px,
      rgb(240 240 240) 1450.37px 2653.94px, rgb(240 240 240) 3607.86px 2166.9px,
      rgb(240 240 240) 767.201px 3847.5px, rgb(240 240 240) 880.922px 1889.63px,
      rgb(240 240 240) 3880.18px 730.894px, rgb(240 240 240) 2191.8px 844.967px,
      rgb(240 240 240) 1826.77px 3780.95px, rgb(240 240 240) 1080.82px 241.345px,
      rgb(240 240 240) 1917.34px 2538.05px, rgb(240 240 240) 3098.28px 182.108px,
      rgb(240 240 240) 802.952px 2458.32px, rgb(240 240 240) 491.378px 1972.15px,
      rgb(240 240 240) 3205.68px 1574.38px, rgb(240 240 240) 3618.56px 3334.03px,
      rgb(240 240 240) 3247.67px 205.633px, rgb(240 240 240) 2502.38px 1552.95px,
      rgb(240 240 240) 3810.68px 2984.27px, rgb(240 240 240) 1647.55px 826.197px,
      rgb(240 240 240) 2950.59px 1087.43px, rgb(240 240 240) 3169.33px 3785.63px,
      rgb(240 240 240) 1287.16px 2351.88px, rgb(240 240 240) 3037.42px 732.333px,
      rgb(240 240 240) 3998.03px 1778.07px, rgb(240 240 240) 1811.53px 1215.35px,
      rgb(240 240 240) 2472.36px 385.52px, rgb(240 240 240) 2144.54px 3451.75px,
      rgb(240 240 240) 3316.22px 2903.44px, rgb(240 240 240) 483.718px 1798.84px,
      rgb(240 240 240) 314.546px 224.153px, rgb(240 240 240) 3287.65px 3255.1px,
      rgb(240 240 240) 84.1752px 1568.55px, rgb(240 240 240) 1732.37px 3512.37px,
      rgb(240 240 240) 2157.01px 2930.42px, rgb(240 240 240) 900.863px 1972.76px,
      rgb(240 240 240) 1985.19px 912.827px, rgb(240 240 240) 561.189px 3846.62px,
      rgb(240 240 240) 1355.55px 1943.57px, rgb(240 240 240) 3292.38px 1442.35px,
      rgb(240 240 240) 2970.99px 2230.22px, rgb(240 240 240) 3972.09px 3747.76px,
      rgb(240 240 240) 2937.08px 2488.29px, rgb(240 240 240) 737.852px 2204.58px,
      rgb(240 240 240) 2260.65px 1876.49px, rgb(240 240 240) 846.37px 701.753px,
      rgb(240 240 240) 3658.58px 2477.84px, rgb(240 240 240) 2978.71px 1201.45px,
      rgb(240 240 240) 439.782px 28.8495px, rgb(240 240 240) 204.18px 1527.97px,
      rgb(240 240 240) 3587.38px 3353.27px, rgb(240 240 240) 291.402px 2243.42px,
      rgb(240 240 240) 1737.08px 1583.13px, rgb(240 240 240) 1375.95px 1163.72px,
      rgb(240 240 240) 2302.24px 2236.68px, rgb(240 240 240) 2353.92px 1358.95px,
      rgb(240 240 240) 1486.25px 76.3665px, rgb(240 240 240) 1053.34px 3650.39px,
      rgb(240 240 240) 1581.69px 3069.37px, rgb(240 240 240) 2202.12px 3880.36px,
      rgb(240 240 240) 802.853px 3049.57px, rgb(240 240 240) 2623.96px 1461.98px,
      rgb(240 240 240) 1212.41px 1456.09px, rgb(240 240 240) 1517.73px 2059.63px,
      rgb(240 240 240) 2325.9px 1392.03px, rgb(240 240 240) 2466.19px 244.111px,
      rgb(240 240 240) 2074.3px 808.197px, rgb(240 240 240) 330.591px 3209.59px,
      rgb(240 240 240) 1905.66px 1166.44px, rgb(240 240 240) 267.897px 2048.51px,
      rgb(240 240 240) 1593.8px 2168.26px, rgb(240 240 240) 2148.98px 1283.44px,
      rgb(240 240 240) 1214.02px 1328.53px, rgb(240 240 240) 3628px 2161.98px,
      rgb(240 240 240) 2027.97px 1497.1px, rgb(240 240 240) 2132.26px 1881.41px,
      rgb(240 240 240) 2556.76px 1646.98px, rgb(240 240 240) 27.9574px 911.567px,
      rgb(240 240 240) 529.419px 1119.88px, rgb(240 240 240) 1209.15px 3358.86px,
      rgb(240 240 240) 2801.99px 2088.64px, rgb(240 240 240) 1803.98px 1784.18px,
      rgb(240 240 240) 2824.27px 3164.49px, rgb(240 240 240) 2790.66px 3754.76px,
      rgb(240 240 240) 3462.75px 3628.47px, rgb(240 240 240) 1324.48px 1627.48px,
      rgb(240 240 240) 2400.65px 277.473px, rgb(240 240 240) 510.381px 2504.58px,
      rgb(240 240 240) 3401.7px 956.461px, rgb(240 240 240) 2725.07px 2610.93px,
      rgb(240 240 240) 3550.07px 1050.18px, rgb(240 240 240) 1491.82px 2798.46px,
      rgb(240 240 240) 3569.87px 3021.4px, rgb(240 240 240) 3460.81px 2921.83px,
      rgb(240 240 240) 3896.49px 3703.13px, rgb(240 240 240) 324.249px 1133.09px,
      rgb(240 240 240) 3010.96px 3625.66px, rgb(240 240 240) 766.539px 2596.45px,
      rgb(240 240 240) 1470.81px 1916.74px, rgb(240 240 240) 1704.78px 2267.37px,
      rgb(240 240 240) 1924.3px 2079.13px, rgb(240 240 240) 3687.96px 1139.65px,
      rgb(240 240 240) 2884.71px 3677.65px, rgb(240 240 240) 2178.92px 1267.94px,
      rgb(240 240 240) 3978.25px 376.721px, rgb(240 240 240) 826.819px 2292.18px,
      rgb(240 240 240) 1241.31px 1847.82px, rgb(240 240 240) 3077.58px 3167.65px,
      rgb(240 240 240) 3511.87px 1893.76px, rgb(240 240 240) 2357.19px 2924.42px,
      rgb(240 240 240) 481.404px 3148.41px, rgb(240 240 240) 3433.76px 2019.4px,
      rgb(240 240 240) 1473.83px 3106.37px, rgb(240 240 240) 2207.56px 3513.6px,
      rgb(240 240 240) 537.28px 2518.3px, rgb(240 240 240) 1178.28px 3512.05px,
      rgb(240 240 240) 1572.26px 2834.55px, rgb(240 240 240) 1505.56px 216.858px,
      rgb(240 240 240) 964.65px 3935.25px, rgb(240 240 240) 813.548px 2908.76px,
      rgb(240 240 240) 500.934px 74.417px, rgb(240 240 240) 1481.78px 3096.46px,
      rgb(240 240 240) 3149.16px 749.234px, rgb(240 240 240) 712.734px 3798.32px,
      rgb(240 240 240) 2404.3px 2447.7px, rgb(240 240 240) 376.114px 835.358px,
      rgb(240 240 240) 1045.04px 3961.8px, rgb(240 240 240) 3625.62px 3877.72px,
      rgb(240 240 240) 3279.26px 2465.17px, rgb(240 240 240) 2665.35px 1268.46px,
      rgb(240 240 240) 640.765px 2729.48px, rgb(240 240 240) 3130.04px 1508.34px,
      rgb(240 240 240) 2309.81px 3020.01px, rgb(240 240 240) 1061.86px 2171.96px,
      rgb(240 240 240) 216.484px 3480.97px, rgb(240 240 240) 1696.17px 1502.29px,
      rgb(240 240 240) 3470.78px 2439.73px, rgb(240 240 240) 3543.5px 2950.79px,
      rgb(240 240 240) 2433.13px 1692.9px, rgb(240 240 240) 2865.56px 508.28px,
      rgb(240 240 240) 1222.22px 3481.75px, rgb(240 240 240) 820.098px 716.943px,
      rgb(240 240 240) 3186.98px 3899.5px, rgb(240 240 240) 1857.84px 2044.73px,
      rgb(240 240 240) 247.133px 2331.61px, rgb(240 240 240) 2743.02px 443.463px,
      rgb(240 240 240) 1778.83px 2866.84px, rgb(240 240 240) 3232.57px 3206.79px,
      rgb(240 240 240) 934.163px 1852.6px, rgb(240 240 240) 223.095px 2388.76px,
      rgb(240 240 240) 3797.1px 430.923px, rgb(240 240 240) 3442.41px 2732.01px,
      rgb(240 240 240) 3381.39px 1588.52px, rgb(240 240 240) 2270.61px 1626.82px,
      rgb(240 240 240) 3430.02px 1072.61px, rgb(240 240 240) 321.606px 1093.43px,
      rgb(240 240 240) 486.579px 1987.63px, rgb(240 240 240) 823.652px 2152.21px,
      rgb(240 240 240) 3995.35px 1689.17px, rgb(240 240 240) 3878.16px 1304.19px,
      rgb(240 240 240) 1772.59px 1861.81px, rgb(240 240 240) 3714.29px 3269.08px,
      rgb(240 240 240) 1146.38px 312.797px, rgb(240 240 240) 24.9817px 2873.62px,
      rgb(240 240 240) 3559.35px 3175.15px, rgb(240 240 240) 3187.85px 1590.19px,
      rgb(240 240 240) 270.331px 428.313px, rgb(240 240 240) 2846.08px 2988.32px,
      rgb(240 240 240) 1179.98px 323.09px, rgb(240 240 240) 1807.67px 3287.22px,
      rgb(240 240 240) 1101.18px 315.443px, rgb(240 240 240) 296.682px 2978.81px,
      rgb(240 240 240) 935.753px 3222.6px, rgb(240 240 240) 3369.4px 3077.99px,
      rgb(240 240 240) 148.817px 3960.21px, rgb(240 240 240) 3611.78px 589.924px,
      rgb(240 240 240) 2418.85px 1749.49px, rgb(240 240 240) 352.97px 814.271px,
      rgb(240 240 240) 2648.84px 912.397px, rgb(240 240 240) 658.053px 1464.87px,
      rgb(240 240 240) 2387.85px 2704.2px, rgb(240 240 240) 3930.72px 3361.75px,
      rgb(240 240 240) 435.7px 2877.5px, rgb(240 240 240) 3841.41px 1128.94px,
      rgb(240 240 240) 3224.4px 3539.03px, rgb(240 240 240) 628.529px 2152.91px,
      rgb(240 240 240) 117.916px 1513.31px, rgb(240 240 240) 3546.35px 1147.69px,
      rgb(240 240 240) 1440.46px 1107.11px, rgb(240 240 240) 2835.88px 114.962px,
      rgb(240 240 240) 3220.79px 2432.01px, rgb(240 240 240) 1287.67px 1695.78px,
      rgb(240 240 240) 2385.63px 1136.34px, rgb(240 240 240) 1493.7px 1524.85px,
      rgb(240 240 240) 823.066px 904.742px, rgb(240 240 240) 1556.93px 838.147px,
      rgb(240 240 240) 3566.39px 1457.31px, rgb(240 240 240) 2474.99px 3128.16px,
      rgb(240 240 240) 3135.05px 757.582px, rgb(240 240 240) 2965.9px 190.644px,
      rgb(240 240 240) 3292.45px 945.884px, rgb(240 240 240) 1283.72px 2268.6px,
      rgb(240 240 240) 3834.25px 1143.92px, rgb(240 240 240) 2487.41px 3836.05px,
      rgb(240 240 240) 2838.62px 735.843px, rgb(240 240 240) 1894.37px 75.1922px,
      rgb(240 240 240) 1274.55px 30.6108px, rgb(240 240 240) 2472.31px 1025.44px,
      rgb(240 240 240) 3766.38px 2800.39px, rgb(240 240 240) 977.198px 2225.67px,
      rgb(240 240 240) 744.157px 2794.22px, rgb(240 240 240) 2805.49px 2097.61px,
      rgb(240 240 240) 1336.8px 3914.93px, rgb(240 240 240) 1357.56px 2771.58px,
      rgb(240 240 240) 3441.05px 321.614px, rgb(240 240 240) 2413.51px 1926.21px,
      rgb(240 240 240) 3366.58px 190.089px, rgb(240 240 240) 1036.37px 2796.28px,
      rgb(240 240 240) 2127.33px 2093.35px, rgb(240 240 240) 2740.23px 2414.01px,
      rgb(240 240 240) 1148.98px 3922.78px, rgb(240 240 240) 2475.37px 3669.56px,
      rgb(240 240 240) 3185.01px 1238.54px, rgb(240 240 240) 3882.79px 2452.15px,
      rgb(240 240 240) 1132.14px 94.6584px, rgb(240 240 240) 1998.9px 536.773px,
      rgb(240 240 240) 3659.14px 1894.2px, rgb(240 240 240) 368.284px 2538.96px,
      rgb(240 240 240) 1142.71px 1068.12px, rgb(240 240 240) 1265.41px 1685.48px,
      rgb(240 240 240) 1055.83px 1573.53px, rgb(240 240 240) 1085.48px 3440.69px,
      rgb(240 240 240) 2408.4px 2848.98px, rgb(240 240 240) 3593.88px 927.911px,
      rgb(240 240 240) 3957.19px 1417.14px, rgb(240 240 240) 2989.85px 3878.23px,
      rgb(240 240 240) 2039.17px 3353.96px, rgb(240 240 240) 80.4837px 1622.55px,
      rgb(240 240 240) 3860.83px 2927.56px, rgb(240 240 240) 174.119px 3880.49px,
      rgb(240 240 240) 3112.73px 2113.17px, rgb(240 240 240) 2124.2px 3655.72px,
      rgb(240 240 240) 2513.28px 3776.37px, rgb(240 240 240) 442.544px 2104.27px,
      rgb(240 240 240) 3803.5px 1035.39px, rgb(240 240 240) 2314.66px 1830.06px,
      rgb(240 240 240) 1381.54px 2049.14px, rgb(240 240 240) 2902.57px 3563.22px,
      rgb(240 240 240) 3975.89px 91.1046px, rgb(240 240 240) 1915.48px 2004.18px,
      rgb(240 240 240) 842.776px 534.497px, rgb(240 240 240) 3989.29px 3092.88px,
      rgb(240 240 240) 3574.43px 1199.56px, rgb(240 240 240) 456.38px 3310.16px,
      rgb(240 240 240) 1524.41px 2883.01px, rgb(240 240 240) 3257.81px 1349.83px,
      rgb(240 240 240) 953.385px 2854.37px, rgb(240 240 240) 2714.07px 2557.52px,
      rgb(240 240 240) 2965.01px 2203.34px, rgb(240 240 240) 2853.68px 2770.16px,
      rgb(240 240 240) 1522.74px 3436.41px, rgb(240 240 240) 3917.31px 3507.08px,
      rgb(240 240 240) 181.549px 1032.24px, rgb(240 240 240) 1144.92px 827.301px,
      rgb(240 240 240) 1163.87px 2488.25px, rgb(240 240 240) 667.848px 264.472px,
      rgb(240 240 240) 3075.09px 3581.16px, rgb(240 240 240) 3062px 2196.84px,
      rgb(240 240 240) 3649.7px 1987.75px, rgb(240 240 240) 1094.03px 629.837px,
      rgb(240 240 240) 838.527px 1316.77px, rgb(240 240 240) 1255.39px 3938.34px,
      rgb(240 240 240) 1334.57px 3973.58px, rgb(240 240 240) 3314.26px 2999.32px,
      rgb(240 240 240) 2945.88px 827.542px, rgb(240 240 240) 1742.97px 113.86px,
      rgb(240 240 240) 2331.56px 1378.78px, rgb(240 240 240) 420.386px 3064.42px,
      rgb(240 240 240) 3252.7px 1328.93px, rgb(240 240 240) 3727.6px 1161.54px,
      rgb(240 240 240) 1380.94px 677.87px, rgb(240 240 240) 3246.14px 1742.6px,
      rgb(240 240 240) 2470.16px 3926.06px, rgb(240 240 240) 823.439px 57.4918px,
      rgb(240 240 240) 1255.83px 2487.61px, rgb(240 240 240) 3471.41px 1708.15px,
      rgb(240 240 240) 2506.86px 1129.76px, rgb(240 240 240) 666.436px 719px,
      rgb(240 240 240) 2161.39px 993.641px, rgb(240 240 240) 2680.15px 1891.7px,
      rgb(240 240 240) 1231.25px 1050.62px, rgb(240 240 240) 483.4px 1083.42px,
      rgb(240 240 240) 3551.41px 204.422px, rgb(240 240 240) 899.124px 214.673px,
      rgb(240 240 240) 1866.9px 2787.12px, rgb(240 240 240) 1858.57px 492.578px,
      rgb(240 240 240) 599.846px 2732.48px, rgb(240 240 240) 1404.02px 3167.15px,
      rgb(240 240 240) 3132.05px 2484.17px, rgb(240 240 240) 3429.22px 3984.72px,
      rgb(240 240 240) 277.812px 3043.14px, rgb(240 240 240) 3668.6px 1309.69px,
      rgb(240 240 240) 2143.06px 2714.96px, rgb(240 240 240) 234.581px 168.738px,
      rgb(240 240 240) 375.057px 2024.12px, rgb(240 240 240) 3678.86px 1520.29px,
      rgb(240 240 240) 3091.7px 3125.11px, rgb(240 240 240) 926.469px 3269.15px,
      rgb(240 240 240) 1153.38px 1015.16px, rgb(240 240 240) 465.376px 546.484px,
      rgb(240 240 240) 3636.49px 3022.1px, rgb(240 240 240) 1020.78px 610.542px,
      rgb(240 240 240) 635.293px 1135.52px, rgb(240 240 240) 3581.13px 3690.47px,
      rgb(240 240 240) 2185.23px 2472.34px, rgb(240 240 240) 3045.79px 1177.7px,
      rgb(240 240 240) 721.335px 357.532px, rgb(240 240 240) 2851.51px 2145.22px,
      rgb(240 240 240) 944.669px 2870.89px, rgb(240 240 240) 1679.79px 2942.57px,
      rgb(240 240 240) 659.178px 264.592px, rgb(240 240 240) 2286.07px 2757.47px,
      rgb(240 240 240) 1523.25px 3484.81px, rgb(240 240 240) 2568.05px 2577.69px,
      rgb(240 240 240) 3494.3px 3893.09px, rgb(240 240 240) 236.496px 2258.16px,
      rgb(240 240 240) 997.465px 2585.87px, rgb(240 240 240) 3912.32px 3836.65px,
      rgb(240 240 240) 2599.25px 3189.89px, rgb(240 240 240) 1771.08px 3422.5px,
      rgb(240 240 240) 89.6248px 796.873px, rgb(240 240 240) 215.957px 3973.21px,
      rgb(240 240 240) 110.375px 1777.1px, rgb(240 240 240) 760.185px 2273.25px,
      rgb(240 240 240) 3304.83px 831.092px, rgb(240 240 240) 3514.13px 1006.58px,
      rgb(240 240 240) 3261.39px 2928.75px, rgb(240 240 240) 2158.72px 1972.56px,
      rgb(240 240 240) 3065.68px 493.803px, rgb(240 240 240) 663.691px 3827.03px,
      rgb(240 240 240) 3956.36px 1367.63px, rgb(240 240 240) 2045.74px 2616.81px,
      rgb(240 240 240) 3784.2px 1939.71px, rgb(240 240 240) 132.378px 834.538px,
      rgb(240 240 240) 3175.61px 1240.41px, rgb(240 240 240) 1521.52px 2275.92px,
      rgb(240 240 240) 3747.68px 804.36px, rgb(240 240 240) 2079.78px 3025.56px,
      rgb(240 240 240) 1093.3px 3810.47px, rgb(240 240 240) 312.018px 2098.03px,
      rgb(240 240 240) 3077.71px 1019.07px, rgb(240 240 240) 1792.95px 2822.35px,
      rgb(240 240 240) 606.874px 1972.53px, rgb(240 240 240) 3020.25px 3421.1px,
      rgb(240 240 240) 2747.53px 491.969px, rgb(240 240 240) 1294.82px 679.145px,
      rgb(240 240 240) 1523.76px 1171.68px, rgb(240 240 240) 2744.73px 3163.3px,
      rgb(240 240 240) 3198.87px 2544.48px, rgb(240 240 240) 2197.11px 1663.76px,
      rgb(240 240 240) 1988.46px 2713.08px, rgb(240 240 240) 505.255px 1122.62px,
      rgb(240 240 240) 7.81086px 621.031px, rgb(240 240 240) 3978.07px 1195.6px,
      rgb(240 240 240) 180.462px 2671.18px, rgb(240 240 240) 1342.84px 3106.12px,
      rgb(240 240 240) 2008.96px 2329.5px, rgb(240 240 240) 3467.9px 3313.85px,
      rgb(240 240 240) 1705.26px 2234.5px, rgb(240 240 240) 3730.64px 304.665px,
      rgb(240 240 240) 1575.49px 980.542px, rgb(240 240 240) 1301.3px 1518.07px,
      rgb(240 240 240) 253.995px 3966.34px, rgb(240 240 240) 2964.79px 611.383px,
      rgb(240 240 240) 1869.4px 803.391px, rgb(240 240 240) 418.894px 3257.87px,
      rgb(240 240 240) 3873.04px 790.762px, rgb(240 240 240) 329.033px 3446.91px,
      rgb(240 240 240) 965.766px 299.755px, rgb(240 240 240) 1314.78px 3769.94px,
      rgb(240 240 240) 2288.77px 1971.38px, rgb(240 240 240) 257.074px 2352.37px,
      rgb(240 240 240) 2725.72px 490.231px, rgb(240 240 240) 2471.82px 730.483px,
      rgb(240 240 240) 2122.31px 1259.49px, rgb(240 240 240) 681.306px 1520.62px,
      rgb(240 240 240) 456.882px 278.806px, rgb(240 240 240) 3039.31px 2787.28px,
      rgb(240 240 240) 3588.24px 3609px, rgb(240 240 240) 2200.62px 1546.61px,
      rgb(240 240 240) 429.025px 3026.38px, rgb(240 240 240) 2416.09px 217.059px,
      rgb(240 240 240) 3410.48px 956.615px, rgb(240 240 240) 1084.39px 3555.21px,
      rgb(240 240 240) 1873.31px 1034.34px, rgb(240 240 240) 3217.61px 534.084px,
      rgb(240 240 240) 1490.01px 3020.78px, rgb(240 240 240) 610.989px 3143.67px,
      rgb(240 240 240) 254.346px 2394.95px, rgb(240 240 240) 2145.97px 2027.22px,
      rgb(240 240 240) 2305.11px 3036.04px, rgb(240 240 240) 1930.92px 3247.97px,
      rgb(240 240 240) 873.246px 179.278px, rgb(240 240 240) 200.531px 3425.26px,
      rgb(240 240 240) 2428.85px 3915.84px, rgb(240 240 240) 3431.28px 2698.74px,
      rgb(240 240 240) 1022.77px 3009.51px, rgb(240 240 240) 3776.75px 1859.26px,
      rgb(240 240 240) 2825.75px 1900.3px, rgb(240 240 240) 3225.55px 1709.86px,
      rgb(240 240 240) 809.742px 2430.55px, rgb(240 240 240) 3388.9px 1854.15px,
      rgb(240 240 240) 1662.86px 2017.76px, rgb(240 240 240) 95.5935px 3742.21px,
      rgb(240 240 240) 527.261px 3814.22px, rgb(240 240 240) 2226.01px 2214.93px,
      rgb(240 240 240) 593.194px 354.961px, rgb(240 240 240) 1102.98px 347.224px,
      rgb(240 240 240) 3830.43px 650.69px, rgb(240 240 240) 1935.28px 2974.7px,
      rgb(240 240 240) 1334.82px 1595.38px, rgb(240 240 240) 1625.48px 250.732px,
      rgb(240 240 240) 2998.14px 15.4094px, rgb(240 240 240) 2733.58px 661.726px,
      rgb(240 240 240) 3126.3px 1182.59px, rgb(240 240 240) 2884px 554.297px,
      rgb(240 240 240) 3245.48px 1091.72px, rgb(240 240 240) 283.374px 3976.28px,
      rgb(240 240 240) 3038.91px 2740.2px, rgb(240 240 240) 2026.85px 1463.09px,
      rgb(240 240 240) 2223.33px 1537.09px, rgb(240 240 240) 2403.75px 738.833px,
      rgb(240 240 240) 1072.3px 3319.7px, rgb(240 240 240) 763.456px 499.006px,
      rgb(240 240 240) 216.835px 1467.83px, rgb(240 240 240) 1437.41px 2635.74px,
      rgb(240 240 240) 3022.61px 2913.19px, rgb(240 240 240) 2577.93px 824.72px,
      rgb(240 240 240) 1111.78px 3461.18px, rgb(240 240 240) 859.767px 80.9412px,
      rgb(240 240 240) 3466.49px 2590.26px, rgb(240 240 240) 2426.8px 2829.89px,
      rgb(240 240 240) 3132.49px 1626.33px, rgb(240 240 240) 1310.98px 600.313px,
      rgb(240 240 240) 3127.26px 3402.95px, rgb(240 240 240) 1006.65px 3072.2px,
      rgb(240 240 240) 2145.18px 3621.42px, rgb(240 240 240) 2016.53px 3460.86px,
      rgb(240 240 240) 316.714px 2777.79px, rgb(240 240 240) 1711.73px 1318.23px,
      rgb(240 240 240) 647.537px 3256.2px, rgb(240 240 240) 3423.96px 500.966px,
      rgb(240 240 240) 3061.15px 1308.76px, rgb(240 240 240) 806.354px 1192.91px,
      rgb(240 240 240) 2252.45px 2520.95px, rgb(240 240 240) 1617.2px 2418.19px,
      rgb(240 240 240) 3399.19px 896.877px, rgb(240 240 240) 996.246px 941.687px,
      rgb(240 240 240) 3926.73px 1649.45px, rgb(240 240 240) 3695.53px 121.579px,
      rgb(240 240 240) 941.538px 3787.55px, rgb(240 240 240) 3524.68px 3086.77px,
      rgb(240 240 240) 3908.83px 1472.44px, rgb(240 240 240) 3465.21px 1109.91px,
      rgb(240 240 240) 2813.59px 975.114px, rgb(240 240 240) 3076.82px 34.9618px,
      rgb(240 240 240) 370.852px 2558.62px, rgb(240 240 240) 1152.55px 3024.83px,
      rgb(240 240 240) 1065.12px 793.653px, rgb(240 240 240) 3839.25px 57.1355px,
      rgb(240 240 240) 118.404px 3137.74px, rgb(240 240 240) 1013.82px 2112.72px,
      rgb(240 240 240) 2509.94px 3513.77px, rgb(240 240 240) 1495.33px 665.91px,
      rgb(240 240 240) 2341.93px 3562.22px, rgb(240 240 240) 2018.15px 5.90207px,
      rgb(240 240 240) 416.92px 3300.06px, rgb(240 240 240) 112.853px 1795.59px,
      rgb(240 240 240) 1431.62px 3636.51px, rgb(240 240 240) 1553.31px 900.788px,
      rgb(240 240 240) 1575.81px 3277.87px, rgb(240 240 240) 3710.44px 2434.22px,
      rgb(240 240 240) 1122.44px 3166.73px, rgb(240 240 240) 3418.3px 2267.59px,
      rgb(240 240 240) 1658.77px 3143.83px, rgb(240 240 240) 338.423px 1055.87px,
      rgb(240 240 240) 2330.45px 3519.59px, rgb(240 240 240) 916.619px 3449.41px,
      rgb(240 240 240) 794.54px 1350.2px, rgb(240 240 240) 1227.57px 3603.96px,
      rgb(240 240 240) 3180.59px 3402.21px, rgb(240 240 240) 3083.38px 3269.35px,
      rgb(240 240 240) 1937.67px 2117.86px, rgb(240 240 240) 1829.22px 2244.14px,
      rgb(240 240 240) 1117.69px 11.8351px, rgb(240 240 240) 1272.64px 1946.61px,
      rgb(240 240 240) 3623.71px 3130.18px, rgb(240 240 240) 2413.01px 646.36px,
      rgb(240 240 240) 1703.94px 3675.25px, rgb(240 240 240) 254.998px 806.657px,
      rgb(240 240 240) 488.595px 504.669px, rgb(240 240 240) 3902.39px 3290.8px,
      rgb(240 240 240) 520.086px 3775.73px, rgb(240 240 240) 720.091px 3661.52px,
      rgb(240 240 240) 2036.35px 2513.43px, rgb(240 240 240) 858.277px 2397.13px,
      rgb(240 240 240) 3169.47px 2097.31px, rgb(240 240 240) 3335.86px 2930.24px,
      rgb(240 240 240) 2579.3px 3219.67px, rgb(240 240 240) 783.812px 2551.87px,
      rgb(240 240 240) 1772.03px 1745.36px, rgb(240 240 240) 1686.42px 982.825px,
      rgb(240 240 240) 139.127px 3572.41px, rgb(240 240 240) 657.166px 1916.28px,
      rgb(240 240 240) 673.415px 1945.24px, rgb(240 240 240) 2004.11px 2139.81px,
      rgb(240 240 240) 163.285px 2596.13px, rgb(240 240 240) 3137.17px 1555.82px,
      rgb(240 240 240) 72.5679px 26.8648px, rgb(240 240 240) 2846.68px 200.544px,
      rgb(240 240 240) 1149.3px 2992.48px, rgb(240 240 240) 3818.24px 837.509px,
      rgb(240 240 240) 340.115px 966.929px, rgb(240 240 240) 3217.85px 1671.41px,
      rgb(240 240 240) 147.481px 3284.86px, rgb(240 240 240) 320.417px 3279.36px,
      rgb(240 240 240) 805.325px 2291.6px, rgb(240 240 240) 410.962px 2086.35px,
      rgb(240 240 240) 2401.62px 3027.97px, rgb(240 240 240) 2506.31px 1544.15px,
      rgb(240 240 240) 3491.66px 2815.71px, rgb(240 240 240) 2003.66px 2573.74px,
      rgb(240 240 240) 1800.1px 2080.17px, rgb(240 240 240) 2439.57px 3956.05px,
      rgb(240 240 240) 3394.08px 1019.95px, rgb(240 240 240) 1715.94px 1791.3px,
      rgb(240 240 240) 1467.8px 2902.04px, rgb(240 240 240) 2265.79px 677.166px,
      rgb(240 240 240) 1804.69px 3183.02px, rgb(240 240 240) 3569.52px 325.283px,
      rgb(240 240 240) 492.011px 54.0276px, rgb(240 240 240) 3310.88px 2617.29px,
      rgb(240 240 240) 2249.16px 3604.84px, rgb(240 240 240) 1033.5px 3335.74px,
      rgb(240 240 240) 2103.49px 233.981px, rgb(240 240 240) 527.553px 209.301px,
      rgb(240 240 240) 1552.8px 1220.98px, rgb(240 240 240) 2807.9px 1534.91px,
      rgb(240 240 240) 187.747px 1626.35px, rgb(240 240 240) 1085.86px 2578.49px,
      rgb(240 240 240) 1543.27px 191.229px, rgb(240 240 240) 1660.85px 2295.35px,
      rgb(240 240 240) 183.644px 1802.77px, rgb(240 240 240) 1795.26px 1409.83px,
      rgb(240 240 240) 3814.35px 2015.09px, rgb(240 240 240) 343.77px 3900.16px,
      rgb(240 240 240) 898.502px 892.958px, rgb(240 240 240) 1180.55px 2385.96px,
      rgb(240 240 240) 606.046px 974.82px, rgb(240 240 240) 3825.68px 642.196px,
      rgb(240 240 240) 3329.2px 2596.81px, rgb(240 240 240) 2404.76px 3842.26px,
      rgb(240 240 240) 2219.71px 2098.03px, rgb(240 240 240) 2490.33px 3977px,
      rgb(240 240 240) 926.598px 2972.29px, rgb(240 240 240) 3827.55px 664.31px,
      rgb(240 240 240) 1392.44px 2688.62px, rgb(240 240 240) 775.067px 3229.93px,
      rgb(240 240 240) 2621.16px 2438.94px, rgb(240 240 240) 3981.16px 2249.64px,
      rgb(240 240 240) 2570.11px 1235.26px, rgb(240 240 240) 1203.14px 2780.84px,
      rgb(240 240 240) 859.724px 398.599px, rgb(240 240 240) 3903.94px 1447.29px,
      rgb(240 240 240) 2094.21px 2020.23px, rgb(240 240 240) 1385.74px 1865.79px,
      rgb(240 240 240) 2945.83px 1339.21px, rgb(240 240 240) 3490px 1172.45px,
      rgb(240 240 240) 1738.52px 3681.22px, rgb(240 240 240) 3545.53px 1558.97px,
      rgb(240 240 240) 3617.78px 771.762px, rgb(240 240 240) 704.773px 3510.66px,
      rgb(240 240 240) 995.29px 2730.77px, rgb(240 240 240) 2595.46px 1577.49px,
      rgb(240 240 240) 706.665px 815.767px, rgb(240 240 240) 3683.01px 613.623px,
      rgb(240 240 240) 2468.52px 382.393px, rgb(240 240 240) 828.533px 3992.15px,
      rgb(240 240 240) 1552.17px 1587.02px, rgb(240 240 240) 1037.82px 1447.62px,
      rgb(240 240 240) 2820.64px 630.743px, rgb(240 240 240) 2490.12px 3301.52px,
      rgb(240 240 240) 634.107px 2175.55px, rgb(240 240 240) 1308.88px 2101.44px,
      rgb(240 240 240) 2115.08px 3262.72px, rgb(240 240 240) 542.499px 2765.85px,
      rgb(240 240 240) 2932.13px 1211.48px, rgb(240 240 240) 1426.82px 3110.09px,
      rgb(240 240 240) 858.402px 1391.02px, rgb(240 240 240) 3361.35px 2836.47px,
      rgb(240 240 240) 549.513px 1276.94px, rgb(240 240 240) 399.106px 1580.54px,
      rgb(240 240 240) 2010.18px 1975px, rgb(240 240 240) 3588.54px 2998.29px,
      rgb(240 240 240) 1943.11px 386.36px, rgb(240 240 240) 444.39px 1572.62px,
      rgb(240 240 240) 760.036px 3495.33px, rgb(240 240 240) 2828.38px 3725.41px,
      rgb(240 240 240) 626.609px 1436.58px, rgb(240 240 240) 1780.48px 1411.89px,
      rgb(240 240 240) 906.081px 2260.77px, rgb(240 240 240) 1835.17px 2913.35px,
      rgb(240 240 240) 2909.58px 2049.29px, rgb(240 240 240) 1908.41px 3751.05px,
      rgb(240 240 240) 3594.42px 2340.5px, rgb(240 240 240) 3989.94px 1811.23px,
      rgb(240 240 240) 2356.67px 1831.07px, rgb(240 240 240) 1251.57px 1567.03px,
      rgb(240 240 240) 1815.47px 1482.59px, rgb(240 240 240) 2656.73px 1044.34px,
      rgb(240 240 240) 1730.93px 2778.46px, rgb(240 240 240) 955.804px 3738.17px,
      rgb(240 240 240) 1734.09px 1502.82px, rgb(240 240 240) 484.743px 3203.09px,
      rgb(240 240 240) 1417.94px 161.665px, rgb(240 240 240) 3409px 3395.13px,
      rgb(240 240 240) 1975.05px 2191.05px, rgb(240 240 240) 299.622px 2817.98px,
      rgb(240 240 240) 3514.31px 2567.03px, rgb(240 240 240) 2945.97px 3909.5px,
      rgb(240 240 240) 1019.86px 1608.95px, rgb(240 240 240) 1171.56px 3149.24px,
      rgb(240 240 240) 2687.63px 860.741px, rgb(240 240 240) 3007.94px 1597.24px,
      rgb(240 240 240) 2270.74px 3596.1px, rgb(240 240 240) 2388.26px 1317.27px,
      rgb(240 240 240) 1594.32px 3268.91px, rgb(240 240 240) 3614.04px 3809.2px,
      rgb(240 240 240) 683.743px 3985.37px, rgb(240 240 240) 3865.52px 668.146px,
      rgb(240 240 240) 2470.3px 548.726px, rgb(240 240 240) 2277.92px 478.217px,
      rgb(240 240 240) 1327.93px 2887.26px, rgb(240 240 240) 2353.27px 816.02px,
      rgb(240 240 240) 665.311px 1363.16px, rgb(240 240 240) 1204.57px 1195.72px,
      rgb(240 240 240) 208.928px 2477.29px, rgb(240 240 240) 671.602px 1069.87px,
      rgb(240 240 240) 1701.31px 400.62px, rgb(240 240 240) 674.334px 1547.3px,
      rgb(240 240 240) 1373.33px 608.383px, rgb(240 240 240) 1832.48px 3007.31px,
      rgb(240 240 240) 1279.3px 2867.61px, rgb(240 240 240) 1115.87px 3471.83px,
      rgb(240 240 240) 143.524px 2028.45px, rgb(240 240 240) 3381.52px 2457.91px,
      rgb(240 240 240) 3096.2px 777.223px, rgb(240 240 240) 2828.44px 510.706px,
      rgb(240 240 240) 3545.99px 585.2px, rgb(240 240 240) 2368.98px 1976.02px,
      rgb(240 240 240) 381.423px 215.253px, rgb(240 240 240) 268.463px 1865.4px,
      rgb(240 240 240) 746.029px 675.977px, rgb(240 240 240) 3842.5px 1289.72px,
      rgb(240 240 240) 1980.55px 738.838px, rgb(240 240 240) 1622.5px 1395.69px,
      rgb(240 240 240) 3920.54px 1253.56px, rgb(240 240 240) 900.005px 3733.92px,
      rgb(240 240 240) 1120.41px 1534.7px, rgb(240 240 240) 3269.76px 122.508px,
      rgb(240 240 240) 1758.61px 3236.77px, rgb(240 240 240) 3360.77px 106.974px,
      rgb(240 240 240) 2707.29px 2430px, rgb(240 240 240) 2166.03px 2909.94px,
      rgb(240 240 240) 2965.23px 738.583px, rgb(240 240 240) 630.515px 2266.69px,
      rgb(240 240 240) 3714.16px 2386.23px, rgb(240 240 240) 3505.48px 2079.01px,
      rgb(240 240 240) 3321.69px 3240.8px, rgb(240 240 240) 1156.83px 3296.29px,
      rgb(240 240 240) 2611.22px 3095.52px, rgb(240 240 240) 2641.98px 364.247px,
      rgb(240 240 240) 1444.66px 3406.22px, rgb(240 240 240) 1408.74px 1681.75px,
      rgb(240 240 240) 50.0166px 1197.04px, rgb(240 240 240) 1170.55px 2467.16px,
      rgb(240 240 240) 735.92px 1120.64px, rgb(240 240 240) 119.948px 3486.99px,
      rgb(240 240 240) 1358.52px 3689.28px, rgb(240 240 240) 1506.2px 2946.98px,
      rgb(240 240 240) 1278.42px 2302.61px, rgb(240 240 240) 2611.8px 738.396px,
      rgb(240 240 240) 2443.84px 981.075px, rgb(240 240 240) 55.2462px 3592.79px,
      rgb(240 240 240) 688.249px 2521.52px, rgb(240 240 240) 326.347px 1069.35px,
      rgb(240 240 240) 1885.05px 3885.34px, rgb(240 240 240) 1919.3px 1491.07px,
      rgb(240 240 240) 701.161px 713.094px, rgb(240 240 240) 1099.88px 871.549px,
      rgb(240 240 240) 3771.02px 103.115px, rgb(240 240 240) 1194.56px 1318.12px,
      rgb(240 240 240) 140.371px 2165.8px, rgb(240 240 240) 238.322px 2343.12px,
      rgb(240 240 240) 442.378px 2102.45px, rgb(240 240 240) 3987.24px 1315.68px,
      rgb(240 240 240) 2978.62px 816.585px, rgb(240 240 240) 3033.8px 746.85px,
      rgb(240 240 240) 2496.24px 1263.98px, rgb(240 240 240) 3518.52px 2495.12px,
      rgb(240 240 240) 1357.11px 1094.65px, rgb(240 240 240) 3320.95px 210.183px,
      rgb(240 240 240) 3754.88px 1140.17px, rgb(240 240 240) 1530.15px 3819.92px,
      rgb(240 240 240) 2640.54px 2523.4px, rgb(240 240 240) 340.766px 2118px,
      rgb(240 240 240) 2632.06px 2889.58px, rgb(240 240 240) 3051.82px 1183.56px,
      rgb(240 240 240) 2010.83px 3199.41px, rgb(240 240 240) 3190.33px 3074.96px,
      rgb(240 240 240) 2709.89px 678.247px, rgb(240 240 240) 634.006px 2297.52px,
      rgb(240 240 240) 204.672px 1886.54px, rgb(240 240 240) 1196.1px 3230.92px,
      rgb(240 240 240) 2335.56px 378.025px, rgb(240 240 240) 697.042px 1582.19px,
      rgb(240 240 240) 1129.13px 639.386px, rgb(240 240 240) 66.4456px 3147.8px,
      rgb(240 240 240) 3785.07px 2446.85px, rgb(240 240 240) 421.548px 999.336px,
      rgb(240 240 240) 3493.75px 3969.13px, rgb(240 240 240) 3693.49px 348.835px,
      rgb(240 240 240) 2643.11px 10.8928px, rgb(240 240 240) 3310.19px 3241.33px,
      rgb(240 240 240) 3152.65px 2933.57px, rgb(240 240 240) 2901.17px 920.773px,
      rgb(240 240 240) 1255.09px 1902.94px, rgb(240 240 240) 3589.34px 2960.43px,
      rgb(240 240 240) 1727.02px 768.026px, rgb(240 240 240) 256.319px 451.982px,
      rgb(240 240 240) 247.513px 282.963px, rgb(240 240 240) 3843.17px 144.341px,
      rgb(240 240 240) 476.997px 1620.56px, rgb(240 240 240) 2135.92px 328.815px,
      rgb(240 240 240) 677.849px 2353.61px, rgb(240 240 240) 2200.92px 130.232px,
      rgb(240 240 240) 603.92px 1848.83px, rgb(240 240 240) 2454.31px 1299.86px,
      rgb(240 240 240) 605.458px 2960.73px, rgb(240 240 240) 58.7138px 1174.38px,
      rgb(240 240 240) 771.691px 2174.31px, rgb(240 240 240) 2979.59px 3597.88px,
      rgb(240 240 240) 1508.69px 949.307px, rgb(240 240 240) 2550.82px 2810.98px,
      rgb(240 240 240) 889.353px 3421.49px, rgb(240 240 240) 2949.75px 2707.81px,
      rgb(240 240 240) 1205.03px 1962.16px, rgb(240 240 240) 2185.25px 1232.07px,
      rgb(240 240 240) 1043.08px 860.794px, rgb(240 240 240) 1149.47px 2345.13px,
      rgb(240 240 240) 986.279px 3244.92px, rgb(240 240 240) 2460.44px 673.699px,
      rgb(240 240 240) 3102.38px 1266.63px, rgb(240 240 240) 3634.67px 1957.63px,
      rgb(240 240 240) 3507.96px 2187.36px, rgb(240 240 240) 2817.17px 1885.83px,
      rgb(240 240 240) 2648.62px 2167.04px, rgb(240 240 240) 1538.14px 1745.07px,
      rgb(240 240 240) 266.834px 298.046px, rgb(240 240 240) 460.396px 2638.47px,
      rgb(240 240 240) 2436.31px 1513.44px, rgb(240 240 240) 1285.32px 1141.13px,
      rgb(240 240 240) 2022.92px 45.3472px, rgb(240 240 240) 3909.55px 2192.12px,
      rgb(240 240 240) 382.809px 1016.01px, rgb(240 240 240) 189.081px 628.396px,
      rgb(240 240 240) 2793.31px 3969.39px, rgb(240 240 240) 2809.21px 2738.59px,
      rgb(240 240 240) 1397.72px 136.653px, rgb(240 240 240) 3898.6px 3733.03px,
      rgb(240 240 240) 3772.26px 1688.1px, rgb(240 240 240) 2998.27px 1074.86px,
      rgb(240 240 240) 1730.24px 3600.08px, rgb(240 240 240) 2618.95px 3758.23px,
      rgb(240 240 240) 1339.09px 3129.61px, rgb(240 240 240) 3561.13px 2159.41px,
      rgb(240 240 240) 2826.02px 1427.88px, rgb(240 240 240) 1441.58px 3414.02px,
      rgb(240 240 240) 1873.93px 3023.35px, rgb(240 240 240) 1903.25px 2576.49px,
      rgb(240 240 240) 1638.24px 3878.9px, rgb(240 240 240) 2775.83px 328.914px,
      rgb(240 240 240) 2370.96px 712.531px, rgb(240 240 240) 716.458px 2613.04px,
      rgb(240 240 240) 2131.32px 3250.79px, rgb(240 240 240) 1848.48px 2758.96px,
      rgb(240 240 240) 3048.16px 1707.1px, rgb(240 240 240) 264.298px 2476.03px,
      rgb(240 240 240) 2779.55px 1136.75px, rgb(240 240 240) 2055.54px 3626.84px,
      rgb(240 240 240) 1646.67px 3464.89px, rgb(240 240 240) 177.13px 3175.22px,
      rgb(240 240 240) 2473.28px 968.161px, rgb(240 240 240) 3667.16px 2633.46px,
      rgb(240 240 240) 762.209px 2352.78px, rgb(240 240 240) 2525.79px 1982.14px,
      rgb(240 240 240) 874.974px 3738.89px, rgb(240 240 240) 1059.36px 509.831px,
      rgb(240 240 240) 980px 2814.07px, rgb(240 240 240) 539.938px 2209.1px,
      rgb(240 240 240) 282.302px 250.11px, rgb(240 240 240) 2689.28px 1753.95px,
      rgb(240 240 240) 2383.37px 523.444px, rgb(240 240 240) 2803.55px 1960.55px,
      rgb(240 240 240) 1626.35px 2422.84px, rgb(240 240 240) 3082.33px 1067.47px,
      rgb(240 240 240) 2259.63px 1883.59px, rgb(240 240 240) 2962.95px 948.912px,
      rgb(240 240 240) 499.734px 3182.81px, rgb(240 240 240) 2940.1px 3671.77px,
      rgb(240 240 240) 139.31px 2653.63px, rgb(240 240 240) 2375.59px 2644.23px,
      rgb(240 240 240) 1030.99px 1035.73px, rgb(240 240 240) 3503.82px 2864.73px,
      rgb(240 240 240) 2582.76px 2596.74px, rgb(240 240 240) 3940.62px 431.361px,
      rgb(240 240 240) 159.987px 1136.63px, rgb(240 240 240) 389.945px 2139.74px,
      rgb(240 240 240) 3269.53px 1403.67px, rgb(240 240 240) 1840.1px 1765.67px,
      rgb(240 240 240) 1620.98px 1149.67px, rgb(240 240 240) 1914.4px 2733.41px,
      rgb(240 240 240) 2497.45px 193.725px, rgb(240 240 240) 733.958px 2530.09px,
      rgb(240 240 240) 2353.2px 2311.95px, rgb(240 240 240) 1795.99px 1705.19px,
      rgb(240 240 240) 1993.85px 2491.29px, rgb(240 240 240) 3135.72px 2726.17px,
      rgb(240 240 240) 1978.76px 1989.9px, rgb(240 240 240) 2780.35px 2985.75px,
      rgb(240 240 240) 2804.21px 3363.95px, rgb(240 240 240) 2597.72px 2684.55px,
      rgb(240 240 240) 279.634px 3351.69px, rgb(240 240 240) 72.6571px 1452.28px,
      rgb(240 240 240) 3896.64px 1282.37px, rgb(240 240 240) 3052.75px 2814.28px,
      rgb(240 240 240) 2712.13px 2904.58px, rgb(240 240 240) 3401.83px 918.119px,
      rgb(240 240 240) 1525.37px 2634.55px, rgb(240 240 240) 2359.33px 3979.34px,
      rgb(240 240 240) 2316.56px 3570.15px, rgb(240 240 240) 3957.35px 3940.95px,
      rgb(240 240 240) 1496.13px 754.204px, rgb(240 240 240) 2935.19px 1960.69px,
      rgb(240 240 240) 2588.38px 1369.55px, rgb(240 240 240) 1561.14px 3534.2px,
      rgb(240 240 240) 3192.47px 907.956px, rgb(240 240 240) 1331.94px 1441.57px,
      rgb(240 240 240) 1012.2px 1050.94px, rgb(240 240 240) 1157.54px 1408.93px,
      rgb(240 240 240) 3823.84px 1697.67px, rgb(240 240 240) 3115.12px 1927.04px,
      rgb(240 240 240) 3099.19px 766.166px, rgb(240 240 240) 3879.29px 2171.28px,
      rgb(240 240 240) 3751.6px 360.225px, rgb(240 240 240) 486.489px 557.207px,
      rgb(240 240 240) 2265.35px 1136.92px, rgb(240 240 240) 3770.23px 1417.33px,
      rgb(240 240 240) 999.308px 3827.54px, rgb(240 240 240) 1292.53px 555.705px,
      rgb(240 240 240) 1524.33px 2999.63px, rgb(240 240 240) 3797.36px 1003.16px,
      rgb(240 240 240) 2612.19px 2110.21px, rgb(240 240 240) 3016.88px 866.398px,
      rgb(240 240 240) 868.266px 3799.83px, rgb(240 240 240) 244.833px 3169.48px,
      rgb(240 240 240) 2868.41px 900.03px, rgb(240 240 240) 956.741px 153.255px,
      rgb(240 240 240) 1440.7px 1367.12px, rgb(240 240 240) 1553.32px 914.857px,
      rgb(240 240 240) 2951.21px 3927.23px, rgb(240 240 240) 1700.4px 1977.15px,
      rgb(240 240 240) 382.423px 343.802px, rgb(240 240 240) 2374.03px 392.771px,
      rgb(240 240 240) 1042.6px 3990.27px, rgb(240 240 240) 2021.27px 1667.32px,
      rgb(240 240 240) 615.767px 1728.58px, rgb(240 240 240) 3031.51px 2607.49px,
      rgb(240 240 240) 2587.54px 2317.97px, rgb(240 240 240) 1863.84px 2421.63px,
      rgb(240 240 240) 1175.54px 549.462px, rgb(240 240 240) 1346.44px 3132.02px,
      rgb(240 240 240) 3763.61px 2285.81px, rgb(240 240 240) 2709.61px 2392.15px,
      rgb(240 240 240) 2134.16px 2644.7px, rgb(240 240 240) 598.091px 651.131px,
      rgb(240 240 240) 3630.72px 338.953px, rgb(240 240 240) 297.143px 713.459px,
      rgb(240 240 240) 184.064px 1498.97px, rgb(240 240 240) 2168.8px 668.384px,
      rgb(240 240 240) 247.117px 2951.47px, rgb(240 240 240) 2247.45px 1944.45px,
      rgb(240 240 240) 3709.59px 611.636px, rgb(240 240 240) 1586.63px 1356.27px,
      rgb(240 240 240) 1819.64px 1123.75px, rgb(240 240 240) 2988.13px 2868.77px,
      rgb(240 240 240) 2823.68px 3881.89px, rgb(240 240 240) 2889.07px 424.433px,
      rgb(240 240 240) 2205px 1566.1px, rgb(240 240 240) 437.486px 1825.06px,
      rgb(240 240 240) 1856.18px 747.734px, rgb(240 240 240) 956.49px 345.89px,
      rgb(240 240 240) 3448.03px 3453.81px, rgb(240 240 240) 2069.34px 2593.12px,
      rgb(240 240 240) 2315.91px 2325.02px, rgb(240 240 240) 2016.48px 3988.64px,
      rgb(240 240 240) 3985.85px 944.925px, rgb(240 240 240) 2242.78px 1928.24px,
      rgb(240 240 240) 2235.81px 1803.56px, rgb(240 240 240) 1828.14px 1043.72px,
      rgb(240 240 240) 309.337px 2726.87px, rgb(240 240 240) 265.657px 2224.73px,
      rgb(240 240 240) 923.531px 286.327px, rgb(240 240 240) 3786.47px 3492.45px,
      rgb(240 240 240) 2117.97px 221.528px, rgb(240 240 240) 2353.18px 3793.8px,
      rgb(240 240 240) 3787.93px 1831.41px, rgb(240 240 240) 909.456px 3374.45px,
      rgb(240 240 240) 3818.67px 2225.53px, rgb(240 240 240) 1133.59px 472.712px,
      rgb(240 240 240) 538.914px 1026.04px, rgb(240 240 240) 2911.3px 2696.85px,
      rgb(240 240 240) 1364.25px 3842.44px, rgb(240 240 240) 3073.14px 254.382px,
      rgb(240 240 240) 1462.67px 83.9021px, rgb(240 240 240) 2067.12px 3938.9px,
      rgb(240 240 240) 273.354px 954.861px, rgb(240 240 240) 2811.43px 3936.07px,
      rgb(240 240 240) 2559.57px 2384.59px, rgb(240 240 240) 2993.74px 807.301px,
      rgb(240 240 240) 1198.3px 2052.22px, rgb(240 240 240) 3662.56px 3816.89px,
      rgb(240 240 240) 2467.59px 1404.75px, rgb(240 240 240) 3757.87px 2919.2px,
      rgb(240 240 240) 1268.6px 1847.92px, rgb(240 240 240) 3602.33px 3242.32px,
      rgb(240 240 240) 3474.09px 2058px, rgb(240 240 240) 3882.03px 2071.82px,
      rgb(240 240 240) 3505.12px 419.55px, rgb(240 240 240) 3469.28px 2295.43px,
      rgb(240 240 240) 2910.65px 1867.06px, rgb(240 240 240) 1014.43px 946.46px,
      rgb(240 240 240) 3421.78px 575.036px, rgb(240 240 240) 876.132px 1888.53px,
      rgb(240 240 240) 1291.62px 817.606px, rgb(240 240 240) 3485.24px 1640.38px,
      rgb(240 240 240) 883.471px 2267.59px, rgb(240 240 240) 3738.68px 2992.11px,
      rgb(240 240 240) 2622.08px 1956.52px, rgb(240 240 240) 1217.48px 3043.29px,
      rgb(240 240 240) 483.937px 1756.22px, rgb(240 240 240) 870.815px 2236.33px,
      rgb(240 240 240) 2071.97px 993.824px, rgb(240 240 240) 3194.72px 2287.39px,
      rgb(240 240 240) 1405.41px 2659.65px, rgb(240 240 240) 1979.94px 45.1798px,
      rgb(240 240 240) 3235.18px 920.913px, rgb(240 240 240) 2669.19px 87.3769px,
      rgb(240 240 240) 53.9292px 977.978px, rgb(240 240 240) 1578.07px 818.489px,
      rgb(240 240 240) 330.568px 3986.06px, rgb(240 240 240) 3514.41px 520.397px,
      rgb(240 240 240) 96.1299px 3553.57px, rgb(240 240 240) 635.96px 1319.06px,
      rgb(240 240 240) 2326.9px 3293.92px, rgb(240 240 240) 1492.3px 1313.98px,
      rgb(240 240 240) 3064.42px 559.641px, rgb(240 240 240) 858.182px 3796.12px,
      rgb(240 240 240) 2395px 860.402px, rgb(240 240 240) 3214.61px 3257.9px,
      rgb(240 240 240) 3308.58px 257.49px, rgb(240 240 240) 3391.78px 3301.92px,
      rgb(240 240 240) 3648.57px 3122.79px, rgb(240 240 240) 1575.65px 8.65593px,
      rgb(240 240 240) 2179.33px 1015.71px, rgb(240 240 240) 57.5542px 2200.03px,
      rgb(240 240 240) 217.517px 826.718px, rgb(240 240 240) 3677.82px 2007.18px,
      rgb(240 240 240) 1970.86px 2318.45px, rgb(240 240 240) 1910.99px 643.395px,
      rgb(240 240 240) 1610.81px 119.367px, rgb(240 240 240) 3992.3px 2642.61px,
      rgb(240 240 240) 3512.78px 1931.99px, rgb(240 240 240) 2311.7px 1189.44px,
      rgb(240 240 240) 1890.66px 3266.83px, rgb(240 240 240) 1465.04px 3420.43px,
      rgb(240 240 240) 1287.76px 872.182px, rgb(240 240 240) 1180.91px 2607.15px,
      rgb(240 240 240) 246.92px 2343.01px, rgb(240 240 240) 363.771px 2927.14px,
      rgb(240 240 240) 1934.74px 3615.28px, rgb(240 240 240) 428.952px 3542.24px,
      rgb(240 240 240) 1397.31px 3566.37px, rgb(240 240 240) 1534.72px 3838.11px,
      rgb(240 240 240) 2446.23px 1929.87px, rgb(240 240 240) 2698.32px 3781.48px,
      rgb(240 240 240) 3658.16px 111.917px, rgb(240 240 240) 1841.6px 2873.69px,
      rgb(240 240 240) 3910.2px 480.712px, rgb(240 240 240) 1207.35px 3084.02px,
      rgb(240 240 240) 1454.39px 2311.72px, rgb(240 240 240) 2513.73px 2696.69px,
      rgb(240 240 240) 3894.49px 2097.95px, rgb(240 240 240) 2966.3px 2092.75px,
      rgb(240 240 240) 1679.61px 628.409px, rgb(240 240 240) 2043.54px 2589.85px,
      rgb(240 240 240) 292.662px 942.388px, rgb(240 240 240) 633.993px 1504.4px,
      rgb(240 240 240) 680.903px 3222.6px, rgb(240 240 240) 590.332px 1041.8px,
      rgb(240 240 240) 1205.94px 873.986px, rgb(240 240 240) 105.359px 2405.46px,
      rgb(240 240 240) 2340.01px 971.333px, rgb(240 240 240) 1803.32px 3083.8px,
      rgb(240 240 240) 3871.2px 2129.52px, rgb(240 240 240) 2643.37px 1185.29px,
      rgb(240 240 240) 3288.12px 1685.99px, rgb(240 240 240) 2172.9px 2226.05px,
      rgb(240 240 240) 3537.98px 2694.8px, rgb(240 240 240) 256.041px 2634.36px,
      rgb(240 240 240) 119.528px 3306.33px, rgb(240 240 240) 874.712px 461.025px,
      rgb(240 240 240) 2039.81px 3552.81px, rgb(240 240 240) 54.0512px 575.815px,
      rgb(240 240 240) 583.098px 2980.63px, rgb(240 240 240) 2377.52px 2792.93px;
  }
`;

export default StarContainerSm;
