import { css } from 'styled-components';

export const imgBase = css`
  border-radius: 10px;
  position: relative;
`;

export const transition = css`
  transition: all 0.7s cubic-bezier(0.01, 0.21, 0.35, 1);
`;

export const hoverable = css`
  ${transition}
  &:hover {
    transform: scale(1.04);
  }
`;
