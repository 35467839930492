import styled, { css } from 'styled-components';
import { flowDownAnim, flowUpAnim } from './starsKeyframes';

const StarContainerMd = styled.div<{ duration: number; flowDown: boolean }>`
  position: relative;
  top: -300px;
  left: -50vw;
  z-index: -1;
  will-change: transform;
  width: 0.75px;
  height: 0.75px;
  background: transparent;
  box-shadow: rgb(240 240 240) 968.239px 3326.56px,
    rgb(240 240 240) 626.348px 1748.03px, rgb(240 240 240) 558.184px 2014.12px,
    rgb(240 240 240) 3435.18px 270.06px, rgb(240 240 240) 123.149px 1599.83px,
    rgb(240 240 240) 3931.22px 551.361px, rgb(240 240 240) 2510.63px 1664.19px,
    rgb(240 240 240) 942.044px 1400.87px, rgb(240 240 240) 783.437px 482.234px,
    rgb(240 240 240) 86.2466px 629.667px, rgb(240 240 240) 3880.97px 2577.93px,
    rgb(240 240 240) 1838.21px 1368.21px, rgb(240 240 240) 1665.46px 3891.35px,
    rgb(240 240 240) 241.787px 8.25029px, rgb(240 240 240) 2582.76px 743.147px,
    rgb(240 240 240) 3247.65px 560.228px, rgb(240 240 240) 220.979px 3774.32px,
    rgb(240 240 240) 3850.62px 3155.42px, rgb(240 240 240) 2388.66px 265.071px,
    rgb(240 240 240) 2920.31px 1985.32px, rgb(240 240 240) 1261.22px 1416.58px,
    rgb(240 240 240) 555.964px 2014.4px, rgb(240 240 240) 702.174px 2421.33px,
    rgb(240 240 240) 2153.62px 2761.03px, rgb(240 240 240) 30.4329px 3628.17px,
    rgb(240 240 240) 1807.74px 418.61px, rgb(240 240 240) 1099.58px 2105.11px,
    rgb(240 240 240) 3237.14px 2456.77px, rgb(240 240 240) 2557.73px 242.595px,
    rgb(240 240 240) 2471.87px 3149.08px, rgb(240 240 240) 1805px 50.9996px,
    rgb(240 240 240) 51.317px 212.067px, rgb(240 240 240) 1784.39px 3872.02px,
    rgb(240 240 240) 3253.71px 2653.75px, rgb(240 240 240) 1755.8px 3931.73px,
    rgb(240 240 240) 843.456px 2861.96px, rgb(240 240 240) 834.27px 1470.86px,
    rgb(240 240 240) 1887.97px 3525.47px, rgb(240 240 240) 1142.86px 2045.69px,
    rgb(240 240 240) 1377.07px 677.079px, rgb(240 240 240) 3447.46px 3744.75px,
    rgb(240 240 240) 609.553px 501.656px, rgb(240 240 240) 3498.78px 2083.51px,
    rgb(240 240 240) 900.473px 369.476px, rgb(240 240 240) 2871.03px 2759.43px,
    rgb(240 240 240) 1805.22px 538.707px, rgb(240 240 240) 320.873px 3334.98px,
    rgb(240 240 240) 1736.09px 2601.74px, rgb(240 240 240) 554.634px 1599.12px,
    rgb(240 240 240) 537.082px 3523.71px, rgb(240 240 240) 2804.82px 324.063px,
    rgb(240 240 240) 3414.33px 3645.91px, rgb(240 240 240) 1873.44px 3100.47px,
    rgb(240 240 240) 1070.39px 2206.86px, rgb(240 240 240) 363.013px 2220.44px,
    rgb(240 240 240) 506.365px 393.393px, rgb(240 240 240) 1100.66px 2192.38px,
    rgb(240 240 240) 1950.46px 1305px, rgb(240 240 240) 3748.92px 3568.44px,
    rgb(240 240 240) 3238.37px 2340.32px, rgb(240 240 240) 1828.71px 3044.81px,
    rgb(240 240 240) 28.1133px 3162.4px, rgb(240 240 240) 3501.96px 470.781px,
    rgb(240 240 240) 30.0256px 1410.99px, rgb(240 240 240) 2871.01px 2348.24px,
    rgb(240 240 240) 3802.4px 1296.88px, rgb(240 240 240) 2316.02px 49.2272px,
    rgb(240 240 240) 3927.43px 3441.37px, rgb(240 240 240) 935.206px 2484.66px,
    rgb(240 240 240) 3081.24px 1377.3px, rgb(240 240 240) 3295.55px 3855.32px,
    rgb(240 240 240) 3302.62px 1152.74px, rgb(240 240 240) 2306.37px 1049.26px,
    rgb(240 240 240) 1181.48px 1563.75px, rgb(240 240 240) 1808.61px 39.6286px,
    rgb(240 240 240) 2332.03px 3834.14px, rgb(240 240 240) 2671.86px 3181.68px,
    rgb(240 240 240) 3029.84px 1779.64px, rgb(240 240 240) 88.7398px 1489.37px,
    rgb(240 240 240) 1366.77px 3846.6px, rgb(240 240 240) 3112.55px 526.497px,
    rgb(240 240 240) 1118.71px 3077.94px, rgb(240 240 240) 3115.72px 3930.5px,
    rgb(240 240 240) 319.792px 3245.03px, rgb(240 240 240) 494.282px 3749.3px,
    rgb(240 240 240) 394.661px 2489.58px, rgb(240 240 240) 2501.96px 1918.54px,
    rgb(240 240 240) 2157.43px 1676.42px, rgb(240 240 240) 876.713px 2315.81px,
    rgb(240 240 240) 617.34px 1693.79px, rgb(240 240 240) 2811.84px 901.458px,
    rgb(240 240 240) 3709.1px 2114.72px, rgb(240 240 240) 1161.36px 2639.92px,
    rgb(240 240 240) 259.176px 758.791px, rgb(240 240 240) 2330.88px 2803.81px,
    rgb(240 240 240) 547.072px 2065.77px, rgb(240 240 240) 2750.32px 2152.91px,
    rgb(240 240 240) 2124.47px 3099.14px, rgb(240 240 240) 3651.18px 2473.36px,
    rgb(240 240 240) 2848.38px 3566.16px, rgb(240 240 240) 1283.78px 2140.11px,
    rgb(240 240 240) 1399.56px 2478.58px, rgb(240 240 240) 475.585px 3411.57px,
    rgb(240 240 240) 3474.99px 1288.75px, rgb(240 240 240) 2051.11px 315.372px,
    rgb(240 240 240) 964.511px 2979.07px, rgb(240 240 240) 3982.46px 1636.96px,
    rgb(240 240 240) 1945.71px 272.452px, rgb(240 240 240) 1592.6px 3728.44px,
    rgb(240 240 240) 801.499px 2189.22px, rgb(240 240 240) 1516.82px 1097.14px,
    rgb(240 240 240) 277.675px 2509.72px, rgb(240 240 240) 3607.15px 770.739px,
    rgb(240 240 240) 3461.01px 2918.93px, rgb(240 240 240) 1333.43px 980.01px,
    rgb(240 240 240) 3201.27px 2097.54px, rgb(240 240 240) 2592.9px 2183.52px,
    rgb(240 240 240) 3071.53px 1633.66px, rgb(240 240 240) 1218.37px 3657.11px,
    rgb(240 240 240) 671.933px 1186.11px, rgb(240 240 240) 846.284px 2837.83px,
    rgb(240 240 240) 165.673px 3573.87px, rgb(240 240 240) 1240.54px 2808.56px,
    rgb(240 240 240) 2348.91px 597.859px, rgb(240 240 240) 547.014px 1902.6px,
    rgb(240 240 240) 1226.21px 3921.51px, rgb(240 240 240) 2624.74px 1469.85px,
    rgb(240 240 240) 3603.81px 824.367px, rgb(240 240 240) 3342.44px 2606.09px,
    rgb(240 240 240) 3031.27px 965.123px, rgb(240 240 240) 3005.55px 742.793px,
    rgb(240 240 240) 2699.92px 541.934px, rgb(240 240 240) 1367.72px 3782.59px,
    rgb(240 240 240) 1800.73px 3892.73px, rgb(240 240 240) 3666.7px 3865.91px,
    rgb(240 240 240) 2338.82px 3368.98px, rgb(240 240 240) 2359.51px 2253.43px,
    rgb(240 240 240) 2463.97px 1195.88px, rgb(240 240 240) 3001.28px 2974.12px,
    rgb(240 240 240) 2374.99px 3130.34px, rgb(240 240 240) 1512.08px 964.199px,
    rgb(240 240 240) 2561.54px 2467.03px, rgb(240 240 240) 3927.72px 188.232px,
    rgb(240 240 240) 1972.36px 2426.25px, rgb(240 240 240) 2219.81px 448.767px,
    rgb(240 240 240) 2420.99px 3506.4px, rgb(240 240 240) 28.2171px 295.232px,
    rgb(240 240 240) 1560.16px 2098.71px, rgb(240 240 240) 15.1606px 233.655px,
    rgb(240 240 240) 1276.92px 406.433px, rgb(240 240 240) 1964.19px 2559.72px,
    rgb(240 240 240) 559.586px 72.9583px, rgb(240 240 240) 3880.38px 1155.4px,
    rgb(240 240 240) 3893.86px 2881.28px, rgb(240 240 240) 1390.17px 2403.99px,
    rgb(240 240 240) 281.311px 278.147px, rgb(240 240 240) 3023.09px 1229.29px,
    rgb(240 240 240) 329.422px 2997.5px, rgb(240 240 240) 1216.69px 3330.84px,
    rgb(240 240 240) 356.538px 1560.49px, rgb(240 240 240) 892.066px 724.924px,
    rgb(240 240 240) 3386.87px 729.805px, rgb(240 240 240) 2721.11px 367.756px,
    rgb(240 240 240) 2910px 2654.95px, rgb(240 240 240) 3491.28px 1316.4px,
    rgb(240 240 240) 2556.49px 984.523px, rgb(240 240 240) 1830.62px 3108.17px,
    rgb(240 240 240) 358.311px 2384.43px, rgb(240 240 240) 3551.99px 799.498px,
    rgb(240 240 240) 1899.17px 3306.07px, rgb(240 240 240) 1668.81px 1666.09px,
    rgb(240 240 240) 107.148px 2203.88px, rgb(240 240 240) 1539.54px 3029.66px,
    rgb(240 240 240) 1131.73px 3074.92px, rgb(240 240 240) 1018.95px 3902.04px,
    rgb(240 240 240) 1985.48px 2486.52px, rgb(240 240 240) 3585.81px 1047.98px,
    rgb(240 240 240) 2408.42px 121.923px, rgb(240 240 240) 1462.24px 1729px,
    rgb(240 240 240) 1707.76px 2020.54px, rgb(240 240 240) 582.979px 2225.28px,
    rgb(240 240 240) 2255.71px 2690.8px, rgb(240 240 240) 3108.36px 2224px,
    rgb(240 240 240) 294.243px 2068.35px, rgb(240 240 240) 3504.25px 1899.49px,
    rgb(240 240 240) 2614.3px 2702.01px, rgb(240 240 240) 2819.97px 2510.64px,
    rgb(240 240 240) 3731.33px 366.816px, rgb(240 240 240) 3055.75px 3891.59px,
    rgb(240 240 240) 2585.43px 3035.07px, rgb(240 240 240) 2624.16px 339.536px,
    rgb(240 240 240) 3754.76px 461.813px, rgb(240 240 240) 2576.26px 1466.21px,
    rgb(240 240 240) 2319.34px 868.403px, rgb(240 240 240) 2493.33px 2855.29px,
    rgb(240 240 240) 2539.39px 3555.65px, rgb(240 240 240) 2018.55px 432.881px,
    rgb(240 240 240) 3471.43px 1632.79px, rgb(240 240 240) 3713.7px 1128.85px,
    rgb(240 240 240) 2415.23px 1024.46px, rgb(240 240 240) 3931.65px 2552.85px,
    rgb(240 240 240) 452.639px 832.581px, rgb(240 240 240) 3854.38px 3754.18px,
    rgb(240 240 240) 104.12px 3580.32px, rgb(240 240 240) 2266.88px 98.2421px,
    rgb(240 240 240) 1503.06px 1706.61px, rgb(240 240 240) 253.531px 3933.73px,
    rgb(240 240 240) 2781.98px 240.52px, rgb(240 240 240) 3421.88px 895.08px,
    rgb(240 240 240) 3012.95px 3564.6px, rgb(240 240 240) 3994.41px 628.93px,
    rgb(240 240 240) 1374.98px 1397.21px, rgb(240 240 240) 306.15px 1001.8px,
    rgb(240 240 240) 610.97px 975.896px, rgb(240 240 240) 1814.84px 1732.62px,
    rgb(240 240 240) 588.291px 2823.69px, rgb(240 240 240) 2246.1px 127.237px,
    rgb(240 240 240) 1967.74px 3537.46px, rgb(240 240 240) 2647.18px 1521.69px,
    rgb(240 240 240) 1855.51px 2789.04px, rgb(240 240 240) 1644.65px 755.076px,
    rgb(240 240 240) 2475.27px 2303.68px, rgb(240 240 240) 623.025px 1493.27px,
    rgb(240 240 240) 3674.07px 1765.12px, rgb(240 240 240) 2229.44px 1942.2px,
    rgb(240 240 240) 2761.32px 1777.03px, rgb(240 240 240) 3686.52px 1001.17px,
    rgb(240 240 240) 3304.27px 1215.42px, rgb(240 240 240) 65.2165px 3220.27px,
    rgb(240 240 240) 3667.08px 256.437px, rgb(240 240 240) 1265.95px 2070.59px,
    rgb(240 240 240) 3366.8px 683.89px, rgb(240 240 240) 2937.34px 13.9693px,
    rgb(240 240 240) 2676.24px 655.747px, rgb(240 240 240) 1909.26px 2648.66px,
    rgb(240 240 240) 1203.47px 1413.25px, rgb(240 240 240) 334.986px 3619.46px,
    rgb(240 240 240) 3426.63px 163.191px, rgb(240 240 240) 203.847px 1459.41px,
    rgb(240 240 240) 3790.18px 1089.61px, rgb(240 240 240) 3043.48px 3408.91px,
    rgb(240 240 240) 716.123px 2303.89px, rgb(240 240 240) 529.142px 900.309px,
    rgb(240 240 240) 686.471px 2188.32px, rgb(240 240 240) 678.717px 1110.43px,
    rgb(240 240 240) 930.362px 3498.7px, rgb(240 240 240) 630.672px 1461.93px,
    rgb(240 240 240) 3761.69px 2655.11px, rgb(240 240 240) 1996.62px 3265.41px,
    rgb(240 240 240) 3236.06px 2835.58px, rgb(240 240 240) 3963.75px 3684.01px,
    rgb(240 240 240) 269.342px 2292.81px, rgb(240 240 240) 3660.33px 1855.58px,
    rgb(240 240 240) 788.478px 1079.4px, rgb(240 240 240) 447.676px 285.631px,
    rgb(240 240 240) 651.141px 1816.59px, rgb(240 240 240) 1094.33px 2561.3px,
    rgb(240 240 240) 239.849px 2069.88px, rgb(240 240 240) 1525.19px 1907.73px,
    rgb(240 240 240) 3303.93px 3244.35px, rgb(240 240 240) 1552.77px 1624.6px,
    rgb(240 240 240) 171.332px 2100.14px, rgb(240 240 240) 1498.23px 501.716px,
    rgb(240 240 240) 2805.91px 2380.51px, rgb(240 240 240) 1810.72px 2901.55px,
    rgb(240 240 240) 2390.54px 3755.54px, rgb(240 240 240) 17.2446px 398.333px,
    rgb(240 240 240) 3827.68px 3237.25px, rgb(240 240 240) 649.475px 1083.19px,
    rgb(240 240 240) 1427.97px 2316.08px, rgb(240 240 240) 3756.57px 416.369px,
    rgb(240 240 240) 1326.5px 1385.13px, rgb(240 240 240) 508.951px 172.659px,
    rgb(240 240 240) 1705.65px 491.721px, rgb(240 240 240) 2735.31px 398.166px,
    rgb(240 240 240) 492.173px 1651.34px, rgb(240 240 240) 2127.19px 65.332px,
    rgb(240 240 240) 127.361px 3538.03px, rgb(240 240 240) 1669.26px 1770.7px,
    rgb(240 240 240) 2340.19px 2112.63px, rgb(240 240 240) 3999.07px 2863.75px,
    rgb(240 240 240) 3556.06px 311.651px, rgb(240 240 240) 335.617px 934.273px,
    rgb(240 240 240) 1289.15px 2394.12px, rgb(240 240 240) 1016.82px 594.036px,
    rgb(240 240 240) 154.028px 3900.18px, rgb(240 240 240) 690.976px 772.123px,
    rgb(240 240 240) 748.945px 1838.34px, rgb(240 240 240) 2761.73px 3409.75px,
    rgb(240 240 240) 1298.83px 3368.51px, rgb(240 240 240) 54.994px 2862.41px,
    rgb(240 240 240) 1157.38px 2003.27px, rgb(240 240 240) 2883.69px 885.783px,
    rgb(240 240 240) 2226.14px 3211.96px, rgb(240 240 240) 2324.46px 46.1438px,
    rgb(240 240 240) 1941.6px 3211.12px, rgb(240 240 240) 1510.35px 3995.52px,
    rgb(240 240 240) 3704.89px 2475.09px, rgb(240 240 240) 260.954px 3358.89px,
    rgb(240 240 240) 701.965px 956.646px, rgb(240 240 240) 3195.77px 740.501px,
    rgb(240 240 240) 1122.36px 2857.29px, rgb(240 240 240) 666.757px 2103.47px,
    rgb(240 240 240) 2258.75px 2248.6px, rgb(240 240 240) 961.137px 920.484px,
    rgb(240 240 240) 703.851px 329.361px, rgb(240 240 240) 3939.47px 107.406px,
    rgb(240 240 240) 2720.73px 3584.62px, rgb(240 240 240) 290.937px 2534.34px,
    rgb(240 240 240) 2748.63px 1397.82px, rgb(240 240 240) 1421.27px 2895.96px,
    rgb(240 240 240) 2857.88px 1314.62px, rgb(240 240 240) 3648.5px 1457.46px,
    rgb(240 240 240) 1610.02px 3947.64px, rgb(240 240 240) 3439.07px 1735.92px,
    rgb(240 240 240) 2025.59px 2720.08px, rgb(240 240 240) 268.385px 3097.97px,
    rgb(240 240 240) 1662.97px 2793.55px, rgb(240 240 240) 24.0626px 1833.27px,
    rgb(240 240 240) 2897.55px 333.176px, rgb(240 240 240) 3055.78px 1232.77px,
    rgb(240 240 240) 309.767px 2437.8px, rgb(240 240 240) 2625.94px 3075.1px,
    rgb(240 240 240) 563.603px 34.8387px, rgb(240 240 240) 1824.18px 768.263px,
    rgb(240 240 240) 1344.14px 2898.81px, rgb(240 240 240) 2446.2px 21.5975px,
    rgb(240 240 240) 2101.29px 747.977px, rgb(240 240 240) 2243.31px 3295.39px,
    rgb(240 240 240) 1013.01px 303.026px, rgb(240 240 240) 2924.84px 54.767px,
    rgb(240 240 240) 3989.96px 1370.24px, rgb(240 240 240) 874.195px 503.323px,
    rgb(240 240 240) 1631.9px 652.487px, rgb(240 240 240) 2908.67px 193.394px,
    rgb(240 240 240) 3046.71px 2206.59px, rgb(240 240 240) 504.452px 2888.62px,
    rgb(240 240 240) 93.7065px 2558.76px, rgb(240 240 240) 3565.45px 3346.91px,
    rgb(240 240 240) 2840.04px 911.805px, rgb(240 240 240) 383.166px 1318.22px,
    rgb(240 240 240) 3940.78px 3550.3px, rgb(240 240 240) 1706.62px 146.741px,
    rgb(240 240 240) 1999.23px 677.927px, rgb(240 240 240) 892.76px 2082.84px,
    rgb(240 240 240) 3555.23px 1258.5px, rgb(240 240 240) 3544.16px 1492.1px,
    rgb(240 240 240) 1962.4px 2749.74px, rgb(240 240 240) 1358.4px 1068.12px,
    rgb(240 240 240) 1870.15px 617.486px, rgb(240 240 240) 3037.48px 3169.64px,
    rgb(240 240 240) 3954.91px 617.46px, rgb(240 240 240) 1702.06px 1811.77px,
    rgb(240 240 240) 2885.67px 2981.87px, rgb(240 240 240) 708.16px 928.952px,
    rgb(240 240 240) 2876.46px 908.058px, rgb(240 240 240) 349.932px 1906.95px,
    rgb(240 240 240) 1162.74px 2739.76px, rgb(240 240 240) 3538.13px 1543.09px,
    rgb(240 240 240) 2235.81px 155.989px, rgb(240 240 240) 1018.88px 2796.52px,
    rgb(240 240 240) 885.301px 1734.22px, rgb(240 240 240) 1877.78px 2162.07px,
    rgb(240 240 240) 3491.3px 2666.23px, rgb(240 240 240) 534.733px 2618.06px,
    rgb(240 240 240) 360.649px 2124.22px, rgb(240 240 240) 2619.77px 359.249px,
    rgb(240 240 240) 560.325px 3197.27px, rgb(240 240 240) 2218.82px 301.248px,
    rgb(240 240 240) 2699.58px 3369.01px, rgb(240 240 240) 2333.74px 1480.56px,
    rgb(240 240 240) 3695px 3346.03px, rgb(240 240 240) 181.053px 246.803px,
    rgb(240 240 240) 1056.78px 1639.41px, rgb(240 240 240) 972.746px 2903.49px,
    rgb(240 240 240) 2653.73px 363.778px, rgb(240 240 240) 503.254px 210.138px,
    rgb(240 240 240) 3273.04px 1813.6px, rgb(240 240 240) 1634.51px 3116.24px,
    rgb(240 240 240) 1355.25px 2793.33px, rgb(240 240 240) 3408.5px 1311.48px,
    rgb(240 240 240) 2780.79px 1469.68px, rgb(240 240 240) 714.133px 3137.17px,
    rgb(240 240 240) 3726.46px 541.04px, rgb(240 240 240) 3660.14px 1370.05px,
    rgb(240 240 240) 3833.26px 533.357px, rgb(240 240 240) 2268.2px 2965.77px,
    rgb(240 240 240) 423.114px 3510.66px, rgb(240 240 240) 3540.53px 1380.67px,
    rgb(240 240 240) 1257.22px 1979.85px, rgb(240 240 240) 3050.26px 2625.91px,
    rgb(240 240 240) 1089.99px 3188.76px, rgb(240 240 240) 587.342px 926.991px,
    rgb(240 240 240) 3852.53px 1578.82px, rgb(240 240 240) 1715.32px 2595.8px,
    rgb(240 240 240) 2651.91px 3876.03px, rgb(240 240 240) 2720.14px 3776.92px,
    rgb(240 240 240) 2568.78px 721.122px, rgb(240 240 240) 1916.46px 361.318px,
    rgb(240 240 240) 1576.87px 1152.57px, rgb(240 240 240) 1955.06px 1624.05px,
    rgb(240 240 240) 182.64px 1075.05px, rgb(240 240 240) 3589.52px 74.5019px,
    rgb(240 240 240) 2404.4px 1167.99px, rgb(240 240 240) 2394.77px 2655.22px,
    rgb(240 240 240) 479.473px 826.549px, rgb(240 240 240) 3531.87px 44.8499px,
    rgb(240 240 240) 2165.3px 3360.6px, rgb(240 240 240) 2518.21px 3293.1px,
    rgb(240 240 240) 2787.18px 466.11px, rgb(240 240 240) 2360.98px 827.276px,
    rgb(240 240 240) 1465.56px 1663.59px, rgb(240 240 240) 2638.45px 1981.41px,
    rgb(240 240 240) 3814.14px 2893.34px, rgb(240 240 240) 467.791px 2479.05px,
    rgb(240 240 240) 2418.42px 2283.08px, rgb(240 240 240) 3604.09px 2378.72px,
    rgb(240 240 240) 1213.53px 3693.5px, rgb(240 240 240) 1093.88px 2298.96px,
    rgb(240 240 240) 1497.92px 1343.68px, rgb(240 240 240) 2717.98px 1516.02px,
    rgb(240 240 240) 2857.7px 3048.08px, rgb(240 240 240) 3403.05px 2813.43px,
    rgb(240 240 240) 995.307px 2243.33px, rgb(240 240 240) 1968.24px 3035.35px,
    rgb(240 240 240) 1166.41px 3119.19px, rgb(240 240 240) 2661.95px 3168.27px,
    rgb(240 240 240) 3540.88px 1671.77px, rgb(240 240 240) 3842.46px 3178.16px,
    rgb(240 240 240) 346.935px 3667.13px, rgb(240 240 240) 1891.83px 2355.85px,
    rgb(240 240 240) 3399.7px 2671.23px, rgb(240 240 240) 1027.83px 3632.9px,
    rgb(240 240 240) 3537.41px 607.251px, rgb(240 240 240) 644.196px 3592.49px,
    rgb(240 240 240) 3190.1px 88.0872px, rgb(240 240 240) 3625.42px 2346.09px,
    rgb(240 240 240) 3839.93px 77.9693px, rgb(240 240 240) 2584.03px 3135.48px,
    rgb(240 240 240) 669.512px 119.241px, rgb(240 240 240) 2892.03px 2526.99px,
    rgb(240 240 240) 2763.23px 2860.57px, rgb(240 240 240) 861.83px 1164.33px,
    rgb(240 240 240) 2643.71px 1024.62px, rgb(240 240 240) 369.375px 3107.43px,
    rgb(240 240 240) 3616.98px 2262.14px, rgb(240 240 240) 2804.63px 3918.07px,
    rgb(240 240 240) 297.298px 3202.21px, rgb(240 240 240) 1530.8px 1255.42px,
    rgb(240 240 240) 3791.44px 3093.52px, rgb(240 240 240) 1801.79px 2736.9px,
    rgb(240 240 240) 2872.74px 2011.34px, rgb(240 240 240) 1767.49px 1891.94px,
    rgb(240 240 240) 3000.72px 706.539px, rgb(240 240 240) 2968.35px 2040.57px,
    rgb(240 240 240) 2612.3px 3870.65px, rgb(240 240 240) 3682.39px 3420.55px,
    rgb(240 240 240) 1590.8px 1652.77px, rgb(240 240 240) 1266.47px 3296.37px,
    rgb(240 240 240) 1910.96px 3390.77px, rgb(240 240 240) 3366.25px 1341.55px,
    rgb(240 240 240) 1883.64px 3240.44px, rgb(240 240 240) 1919.75px 1945.85px,
    rgb(240 240 240) 3928.24px 147.373px, rgb(240 240 240) 3301.33px 3667.67px,
    rgb(240 240 240) 1778.29px 1828.39px, rgb(240 240 240) 1398.03px 349.161px,
    rgb(240 240 240) 2025.42px 1705.88px, rgb(240 240 240) 3876.29px 1374.67px,
    rgb(240 240 240) 621.445px 1620.51px, rgb(240 240 240) 3361.02px 3261.95px,
    rgb(240 240 240) 2518.69px 69.4988px, rgb(240 240 240) 178.044px 3227.56px,
    rgb(240 240 240) 196.582px 703.147px, rgb(240 240 240) 884.756px 2363.9px,
    rgb(240 240 240) 228.148px 3605.19px, rgb(240 240 240) 169.292px 87.6308px,
    rgb(240 240 240) 527.771px 2032.86px, rgb(240 240 240) 806.247px 3839.17px,
    rgb(240 240 240) 1142.87px 3958.62px, rgb(240 240 240) 2929.91px 255.882px,
    rgb(240 240 240) 2515.86px 543.39px, rgb(240 240 240) 3616.31px 2101.23px,
    rgb(240 240 240) 1744.38px 2394.09px, rgb(240 240 240) 3112.52px 1576.17px,
    rgb(240 240 240) 2909.85px 1341.74px, rgb(240 240 240) 527.952px 765.436px,
    rgb(240 240 240) 1254.14px 3465.37px, rgb(240 240 240) 1345.93px 1339.69px,
    rgb(240 240 240) 1291.29px 1552.77px, rgb(240 240 240) 512.256px 3426.62px,
    rgb(240 240 240) 1362.84px 1955.95px, rgb(240 240 240) 1594.13px 1948.83px,
    rgb(240 240 240) 3863.68px 1863.88px, rgb(240 240 240) 3639.48px 1482.94px,
    rgb(240 240 240) 3247.98px 3302.8px, rgb(240 240 240) 2895.55px 3189.16px,
    rgb(240 240 240) 3077.96px 213.626px, rgb(240 240 240) 16.068px 2484.36px,
    rgb(240 240 240) 2224.58px 3260.03px, rgb(240 240 240) 2189.48px 303.555px,
    rgb(240 240 240) 3788.44px 2468.59px, rgb(240 240 240) 1569.07px 1324.88px,
    rgb(240 240 240) 1004.67px 3012.84px, rgb(240 240 240) 1936.67px 2615.25px,
    rgb(240 240 240) 2179.95px 2470.04px, rgb(240 240 240) 1417.32px 908.256px,
    rgb(240 240 240) 1550.22px 2689.51px, rgb(240 240 240) 3142.55px 1956.62px,
    rgb(240 240 240) 545.821px 1210.99px, rgb(240 240 240) 1409.91px 1470.42px,
    rgb(240 240 240) 3040.39px 1725px, rgb(240 240 240) 2982.82px 910.694px,
    rgb(240 240 240) 3042.29px 3789.31px, rgb(240 240 240) 2679.7px 1602.95px,
    rgb(240 240 240) 433.254px 3797.04px, rgb(240 240 240) 813.549px 3842.04px,
    rgb(240 240 240) 738.338px 3114.76px, rgb(240 240 240) 2505.5px 939.387px,
    rgb(240 240 240) 709.812px 3122.81px, rgb(240 240 240) 1070px 1282.84px,
    rgb(240 240 240) 3086.43px 477.143px, rgb(240 240 240) 2933.09px 2533.11px,
    rgb(240 240 240) 3735.67px 3337.4px, rgb(240 240 240) 2313.89px 533.204px,
    rgb(240 240 240) 1604.73px 2663.68px, rgb(240 240 240) 3168.34px 1290.88px,
    rgb(240 240 240) 861.483px 2837.55px, rgb(240 240 240) 2476.11px 501.324px,
    rgb(240 240 240) 3398.95px 931.987px, rgb(240 240 240) 348.578px 2186.35px,
    rgb(240 240 240) 3328.49px 1189.88px, rgb(240 240 240) 3790.81px 3285.95px,
    rgb(240 240 240) 898.366px 1865.34px, rgb(240 240 240) 847.555px 2649.99px,
    rgb(240 240 240) 883.697px 3782.58px, rgb(240 240 240) 1314.08px 1096.54px,
    rgb(240 240 240) 2862.98px 3809.35px, rgb(240 240 240) 2663.23px 2330.23px,
    rgb(240 240 240) 3539.1px 683.855px, rgb(240 240 240) 3941.4px 115.653px,
    rgb(240 240 240) 752.804px 1083.22px, rgb(240 240 240) 172.682px 93.6706px,
    rgb(240 240 240) 2947.88px 471.827px, rgb(240 240 240) 1030.42px 2508.09px,
    rgb(240 240 240) 2682.01px 2331.34px, rgb(240 240 240) 24.6737px 1589.42px,
    rgb(240 240 240) 126.591px 1264.01px, rgb(240 240 240) 3898.8px 242.093px,
    rgb(240 240 240) 3986px 934.857px, rgb(240 240 240) 3331.66px 1286.27px,
    rgb(240 240 240) 2244.05px 1238.44px, rgb(240 240 240) 1053.75px 3901.51px,
    rgb(240 240 240) 2557.21px 2287.45px, rgb(240 240 240) 2564.55px 3173.82px,
    rgb(240 240 240) 959.685px 3640.83px, rgb(240 240 240) 1190.12px 3582.24px,
    rgb(240 240 240) 2344.98px 3294.54px, rgb(240 240 240) 1950.63px 3271.46px,
    rgb(240 240 240) 1968.1px 1591.93px, rgb(240 240 240) 719.408px 1648.78px,
    rgb(240 240 240) 3441.16px 1773.41px, rgb(240 240 240) 2114.47px 208.716px,
    rgb(240 240 240) 2329.72px 2070.69px, rgb(240 240 240) 1008.59px 3880.58px,
    rgb(240 240 240) 1576.59px 656.388px, rgb(240 240 240) 3817.71px 528.864px,
    rgb(240 240 240) 988.606px 2629.67px, rgb(240 240 240) 1205.21px 2903.65px,
    rgb(240 240 240) 895.125px 3969.47px, rgb(240 240 240) 1392.07px 185.029px,
    rgb(240 240 240) 1905.92px 1957.01px, rgb(240 240 240) 697.522px 1979.07px,
    rgb(240 240 240) 3765.28px 3880.43px, rgb(240 240 240) 1859.19px 1125.57px,
    rgb(240 240 240) 847.047px 2150.21px, rgb(240 240 240) 1487.77px 1511.87px,
    rgb(240 240 240) 691.185px 449.692px, rgb(240 240 240) 3727.44px 130.433px,
    rgb(240 240 240) 919.351px 2524.81px, rgb(240 240 240) 3129.67px 2745.75px,
    rgb(240 240 240) 1481.74px 785.655px, rgb(240 240 240) 2218.33px 162.067px,
    rgb(240 240 240) 3196.27px 1221.11px, rgb(240 240 240) 3714.12px 1138.22px,
    rgb(240 240 240) 918.426px 2328.03px, rgb(240 240 240) 3711.29px 3187.08px,
    rgb(240 240 240) 2384.17px 1322.44px, rgb(240 240 240) 180.678px 3627.17px,
    rgb(240 240 240) 1773.38px 2945.58px, rgb(240 240 240) 2846.07px 3762.84px,
    rgb(240 240 240) 209.716px 1640.29px, rgb(240 240 240) 170.64px 1191.96px,
    rgb(240 240 240) 3957.39px 1935.62px, rgb(240 240 240) 305.189px 81.9667px,
    rgb(240 240 240) 1567.82px 3570.11px, rgb(240 240 240) 136.43px 183.02px,
    rgb(240 240 240) 654.777px 3424.88px, rgb(240 240 240) 1867.93px 2990.13px,
    rgb(240 240 240) 1390.05px 2790.85px, rgb(240 240 240) 3044.28px 3962.94px,
    rgb(240 240 240) 3950.43px 725.015px, rgb(240 240 240) 1499.85px 1895.05px,
    rgb(240 240 240) 264.249px 2380.53px, rgb(240 240 240) 2125.61px 2010.11px,
    rgb(240 240 240) 3843.48px 2278.43px, rgb(240 240 240) 2266.7px 1357.81px,
    rgb(240 240 240) 1049.38px 3326.53px, rgb(240 240 240) 1366.4px 2985.43px,
    rgb(240 240 240) 1267.02px 2821.63px, rgb(240 240 240) 1679.69px 2219.54px,
    rgb(240 240 240) 165.937px 3282.8px, rgb(240 240 240) 3602.11px 3083.76px,
    rgb(240 240 240) 3806.68px 2290.51px, rgb(240 240 240) 2653.8px 2920.48px,
    rgb(240 240 240) 225.15px 1665.49px, rgb(240 240 240) 1730.34px 1850.89px,
    rgb(240 240 240) 2523.68px 3067.15px, rgb(240 240 240) 263.661px 2498.9px,
    rgb(240 240 240) 1353.54px 2579.65px, rgb(240 240 240) 2615.23px 2002.48px,
    rgb(240 240 240) 1995.45px 919.558px, rgb(240 240 240) 335.657px 2637.45px,
    rgb(240 240 240) 1469.27px 590.235px, rgb(240 240 240) 1567.08px 1491.81px,
    rgb(240 240 240) 3529.81px 2715.37px, rgb(240 240 240) 3609.39px 1733.62px,
    rgb(240 240 240) 521.738px 904.716px, rgb(240 240 240) 733.101px 484.965px,
    rgb(240 240 240) 3595.3px 3329.8px, rgb(240 240 240) 1435.43px 1415.76px,
    rgb(240 240 240) 120.412px 1163.26px, rgb(240 240 240) 2955.79px 3502.66px,
    rgb(240 240 240) 629.587px 2769.33px, rgb(240 240 240) 980.04px 914.694px,
    rgb(240 240 240) 1307.56px 1733.26px, rgb(240 240 240) 3625.95px 1069.12px,
    rgb(240 240 240) 3908.85px 2540.55px, rgb(240 240 240) 2258.96px 1497.56px,
    rgb(240 240 240) 3919.48px 1891.32px, rgb(240 240 240) 1347.34px 610.851px,
    rgb(240 240 240) 2281.94px 1410.56px, rgb(240 240 240) 1580.44px 112.556px,
    rgb(240 240 240) 3411.81px 3689.73px, rgb(240 240 240) 1188px 3630.59px,
    rgb(240 240 240) 2297.03px 3175.16px, rgb(240 240 240) 1706.59px 2087.01px,
    rgb(240 240 240) 325.86px 3784.88px, rgb(240 240 240) 727.449px 2407.54px,
    rgb(240 240 240) 3909.62px 2847.32px, rgb(240 240 240) 317.859px 3533.8px,
    rgb(240 240 240) 2863.2px 658.75px, rgb(240 240 240) 3959.31px 511.835px,
    rgb(240 240 240) 2162.96px 2321.74px, rgb(240 240 240) 937.295px 375.196px,
    rgb(240 240 240) 1144.62px 231.368px, rgb(240 240 240) 86.3998px 1474.15px,
    rgb(240 240 240) 1475.2px 1058.41px, rgb(240 240 240) 1878.63px 3362.17px,
    rgb(240 240 240) 821.044px 113.555px, rgb(240 240 240) 2218.77px 872.252px,
    rgb(240 240 240) 2283.41px 3249.45px, rgb(240 240 240) 3300.91px 2033.17px,
    rgb(240 240 240) 1735.37px 2566.39px, rgb(240 240 240) 818.218px 19.1215px,
    rgb(240 240 240) 3102.45px 1118.18px, rgb(240 240 240) 2193.29px 2070.51px,
    rgb(240 240 240) 1498.36px 3712.8px, rgb(240 240 240) 3243.64px 3812.03px,
    rgb(240 240 240) 37.6846px 1221.59px, rgb(240 240 240) 243.735px 1452.44px,
    rgb(240 240 240) 1016.86px 741.675px, rgb(240 240 240) 1642.08px 640.792px,
    rgb(240 240 240) 3202.24px 1738.02px, rgb(240 240 240) 2800.62px 961.97px,
    rgb(240 240 240) 2942.41px 78.7581px, rgb(240 240 240) 521.081px 1828.03px,
    rgb(240 240 240) 1590.17px 1281.49px, rgb(240 240 240) 3847.79px 3891.69px,
    rgb(240 240 240) 428.028px 2836.68px, rgb(240 240 240) 3712.78px 2496.63px,
    rgb(240 240 240) 3051.58px 3405.51px, rgb(240 240 240) 3826.7px 2320.49px,
    rgb(240 240 240) 1449.62px 3297.23px, rgb(240 240 240) 936.016px 1274.55px,
    rgb(240 240 240) 1082.01px 1369.33px, rgb(240 240 240) 3209.82px 2347.63px,
    rgb(240 240 240) 2996.78px 2566.01px, rgb(240 240 240) 2468.01px 1975.65px,
    rgb(240 240 240) 1618.63px 1375.88px, rgb(240 240 240) 1030.09px 120.173px,
    rgb(240 240 240) 712.739px 3544.26px, rgb(240 240 240) 1911.89px 3916.6px,
    rgb(240 240 240) 3909.45px 2723.67px, rgb(240 240 240) 227.75px 635.518px,
    rgb(240 240 240) 3717.8px 686.982px, rgb(240 240 240) 2062.33px 2160.44px,
    rgb(240 240 240) 1983.96px 2504.24px, rgb(240 240 240) 1340.57px 3193.14px,
    rgb(240 240 240) 3685.6px 3212.36px, rgb(240 240 240) 1934.71px 208.207px,
    rgb(240 240 240) 52.1415px 1332.6px, rgb(240 240 240) 1568.79px 3874.09px,
    rgb(240 240 240) 2245.93px 494.058px, rgb(240 240 240) 1198.13px 3195.7px,
    rgb(240 240 240) 3577.07px 2190.52px, rgb(240 240 240) 74.2252px 3931.89px,
    rgb(240 240 240) 2200.29px 1199.87px, rgb(240 240 240) 3333.07px 875.91px,
    rgb(240 240 240) 1539.74px 36.6037px, rgb(240 240 240) 607.672px 1894.14px,
    rgb(240 240 240) 1417.95px 433.629px, rgb(240 240 240) 682.781px 2229.99px,
    rgb(240 240 240) 3039.51px 3869.9px, rgb(240 240 240) 1830.44px 440.529px,
    rgb(240 240 240) 136.634px 75.4124px, rgb(240 240 240) 410.953px 398.116px,
    rgb(240 240 240) 1266.1px 642.689px, rgb(240 240 240) 3356.2px 578.606px,
    rgb(240 240 240) 530.869px 2308.41px, rgb(240 240 240) 2660.64px 3343.83px,
    rgb(240 240 240) 2233.17px 3565.36px, rgb(240 240 240) 2398.05px 1783.18px,
    rgb(240 240 240) 2887.01px 3132.91px, rgb(240 240 240) 1607.64px 2864.86px,
    rgb(240 240 240) 565.901px 1.11948px, rgb(240 240 240) 3698.98px 1026.61px,
    rgb(240 240 240) 433.887px 75.8698px, rgb(240 240 240) 182.623px 1769.44px,
    rgb(240 240 240) 683.343px 1997.46px, rgb(240 240 240) 3688.31px 3599.14px,
    rgb(240 240 240) 3502.65px 1451.69px, rgb(240 240 240) 441.879px 2991.22px,
    rgb(240 240 240) 1497.54px 802.716px, rgb(240 240 240) 638.063px 2474.49px,
    rgb(240 240 240) 3397.46px 1937.56px, rgb(240 240 240) 3524.11px 693.476px,
    rgb(240 240 240) 1863.04px 2154.21px, rgb(240 240 240) 3845.16px 3615.77px,
    rgb(240 240 240) 757.261px 2324.87px, rgb(240 240 240) 2755.16px 1280.91px,
    rgb(240 240 240) 1488.35px 3418.15px, rgb(240 240 240) 2247.65px 2233.79px,
    rgb(240 240 240) 2192.65px 1278.9px, rgb(240 240 240) 19.239px 3204.07px,
    rgb(240 240 240) 1022.37px 453.777px, rgb(240 240 240) 1708px 3587.7px,
    rgb(240 240 240) 48.159px 482.723px, rgb(240 240 240) 3789.79px 2186.1px;
  ${({ duration, flowDown }) => css`
    animation: ${duration}s linear 0s infinite normal none running
      ${flowDown ? flowDownAnim : flowUpAnim};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 0.75px;
    height: 0.75px;
    background: transparent;
    box-shadow: rgb(240 240 240) 968.239px 3326.56px,
      rgb(240 240 240) 626.348px 1748.03px, rgb(240 240 240) 558.184px 2014.12px,
      rgb(240 240 240) 3435.18px 270.06px, rgb(240 240 240) 123.149px 1599.83px,
      rgb(240 240 240) 3931.22px 551.361px, rgb(240 240 240) 2510.63px 1664.19px,
      rgb(240 240 240) 942.044px 1400.87px, rgb(240 240 240) 783.437px 482.234px,
      rgb(240 240 240) 86.2466px 629.667px, rgb(240 240 240) 3880.97px 2577.93px,
      rgb(240 240 240) 1838.21px 1368.21px, rgb(240 240 240) 1665.46px 3891.35px,
      rgb(240 240 240) 241.787px 8.25029px, rgb(240 240 240) 2582.76px 743.147px,
      rgb(240 240 240) 3247.65px 560.228px, rgb(240 240 240) 220.979px 3774.32px,
      rgb(240 240 240) 3850.62px 3155.42px, rgb(240 240 240) 2388.66px 265.071px,
      rgb(240 240 240) 2920.31px 1985.32px, rgb(240 240 240) 1261.22px 1416.58px,
      rgb(240 240 240) 555.964px 2014.4px, rgb(240 240 240) 702.174px 2421.33px,
      rgb(240 240 240) 2153.62px 2761.03px, rgb(240 240 240) 30.4329px 3628.17px,
      rgb(240 240 240) 1807.74px 418.61px, rgb(240 240 240) 1099.58px 2105.11px,
      rgb(240 240 240) 3237.14px 2456.77px, rgb(240 240 240) 2557.73px 242.595px,
      rgb(240 240 240) 2471.87px 3149.08px, rgb(240 240 240) 1805px 50.9996px,
      rgb(240 240 240) 51.317px 212.067px, rgb(240 240 240) 1784.39px 3872.02px,
      rgb(240 240 240) 3253.71px 2653.75px, rgb(240 240 240) 1755.8px 3931.73px,
      rgb(240 240 240) 843.456px 2861.96px, rgb(240 240 240) 834.27px 1470.86px,
      rgb(240 240 240) 1887.97px 3525.47px, rgb(240 240 240) 1142.86px 2045.69px,
      rgb(240 240 240) 1377.07px 677.079px, rgb(240 240 240) 3447.46px 3744.75px,
      rgb(240 240 240) 609.553px 501.656px, rgb(240 240 240) 3498.78px 2083.51px,
      rgb(240 240 240) 900.473px 369.476px, rgb(240 240 240) 2871.03px 2759.43px,
      rgb(240 240 240) 1805.22px 538.707px, rgb(240 240 240) 320.873px 3334.98px,
      rgb(240 240 240) 1736.09px 2601.74px, rgb(240 240 240) 554.634px 1599.12px,
      rgb(240 240 240) 537.082px 3523.71px, rgb(240 240 240) 2804.82px 324.063px,
      rgb(240 240 240) 3414.33px 3645.91px, rgb(240 240 240) 1873.44px 3100.47px,
      rgb(240 240 240) 1070.39px 2206.86px, rgb(240 240 240) 363.013px 2220.44px,
      rgb(240 240 240) 506.365px 393.393px, rgb(240 240 240) 1100.66px 2192.38px,
      rgb(240 240 240) 1950.46px 1305px, rgb(240 240 240) 3748.92px 3568.44px,
      rgb(240 240 240) 3238.37px 2340.32px, rgb(240 240 240) 1828.71px 3044.81px,
      rgb(240 240 240) 28.1133px 3162.4px, rgb(240 240 240) 3501.96px 470.781px,
      rgb(240 240 240) 30.0256px 1410.99px, rgb(240 240 240) 2871.01px 2348.24px,
      rgb(240 240 240) 3802.4px 1296.88px, rgb(240 240 240) 2316.02px 49.2272px,
      rgb(240 240 240) 3927.43px 3441.37px, rgb(240 240 240) 935.206px 2484.66px,
      rgb(240 240 240) 3081.24px 1377.3px, rgb(240 240 240) 3295.55px 3855.32px,
      rgb(240 240 240) 3302.62px 1152.74px, rgb(240 240 240) 2306.37px 1049.26px,
      rgb(240 240 240) 1181.48px 1563.75px, rgb(240 240 240) 1808.61px 39.6286px,
      rgb(240 240 240) 2332.03px 3834.14px, rgb(240 240 240) 2671.86px 3181.68px,
      rgb(240 240 240) 3029.84px 1779.64px, rgb(240 240 240) 88.7398px 1489.37px,
      rgb(240 240 240) 1366.77px 3846.6px, rgb(240 240 240) 3112.55px 526.497px,
      rgb(240 240 240) 1118.71px 3077.94px, rgb(240 240 240) 3115.72px 3930.5px,
      rgb(240 240 240) 319.792px 3245.03px, rgb(240 240 240) 494.282px 3749.3px,
      rgb(240 240 240) 394.661px 2489.58px, rgb(240 240 240) 2501.96px 1918.54px,
      rgb(240 240 240) 2157.43px 1676.42px, rgb(240 240 240) 876.713px 2315.81px,
      rgb(240 240 240) 617.34px 1693.79px, rgb(240 240 240) 2811.84px 901.458px,
      rgb(240 240 240) 3709.1px 2114.72px, rgb(240 240 240) 1161.36px 2639.92px,
      rgb(240 240 240) 259.176px 758.791px, rgb(240 240 240) 2330.88px 2803.81px,
      rgb(240 240 240) 547.072px 2065.77px, rgb(240 240 240) 2750.32px 2152.91px,
      rgb(240 240 240) 2124.47px 3099.14px, rgb(240 240 240) 3651.18px 2473.36px,
      rgb(240 240 240) 2848.38px 3566.16px, rgb(240 240 240) 1283.78px 2140.11px,
      rgb(240 240 240) 1399.56px 2478.58px, rgb(240 240 240) 475.585px 3411.57px,
      rgb(240 240 240) 3474.99px 1288.75px, rgb(240 240 240) 2051.11px 315.372px,
      rgb(240 240 240) 964.511px 2979.07px, rgb(240 240 240) 3982.46px 1636.96px,
      rgb(240 240 240) 1945.71px 272.452px, rgb(240 240 240) 1592.6px 3728.44px,
      rgb(240 240 240) 801.499px 2189.22px, rgb(240 240 240) 1516.82px 1097.14px,
      rgb(240 240 240) 277.675px 2509.72px, rgb(240 240 240) 3607.15px 770.739px,
      rgb(240 240 240) 3461.01px 2918.93px, rgb(240 240 240) 1333.43px 980.01px,
      rgb(240 240 240) 3201.27px 2097.54px, rgb(240 240 240) 2592.9px 2183.52px,
      rgb(240 240 240) 3071.53px 1633.66px, rgb(240 240 240) 1218.37px 3657.11px,
      rgb(240 240 240) 671.933px 1186.11px, rgb(240 240 240) 846.284px 2837.83px,
      rgb(240 240 240) 165.673px 3573.87px, rgb(240 240 240) 1240.54px 2808.56px,
      rgb(240 240 240) 2348.91px 597.859px, rgb(240 240 240) 547.014px 1902.6px,
      rgb(240 240 240) 1226.21px 3921.51px, rgb(240 240 240) 2624.74px 1469.85px,
      rgb(240 240 240) 3603.81px 824.367px, rgb(240 240 240) 3342.44px 2606.09px,
      rgb(240 240 240) 3031.27px 965.123px, rgb(240 240 240) 3005.55px 742.793px,
      rgb(240 240 240) 2699.92px 541.934px, rgb(240 240 240) 1367.72px 3782.59px,
      rgb(240 240 240) 1800.73px 3892.73px, rgb(240 240 240) 3666.7px 3865.91px,
      rgb(240 240 240) 2338.82px 3368.98px, rgb(240 240 240) 2359.51px 2253.43px,
      rgb(240 240 240) 2463.97px 1195.88px, rgb(240 240 240) 3001.28px 2974.12px,
      rgb(240 240 240) 2374.99px 3130.34px, rgb(240 240 240) 1512.08px 964.199px,
      rgb(240 240 240) 2561.54px 2467.03px, rgb(240 240 240) 3927.72px 188.232px,
      rgb(240 240 240) 1972.36px 2426.25px, rgb(240 240 240) 2219.81px 448.767px,
      rgb(240 240 240) 2420.99px 3506.4px, rgb(240 240 240) 28.2171px 295.232px,
      rgb(240 240 240) 1560.16px 2098.71px, rgb(240 240 240) 15.1606px 233.655px,
      rgb(240 240 240) 1276.92px 406.433px, rgb(240 240 240) 1964.19px 2559.72px,
      rgb(240 240 240) 559.586px 72.9583px, rgb(240 240 240) 3880.38px 1155.4px,
      rgb(240 240 240) 3893.86px 2881.28px, rgb(240 240 240) 1390.17px 2403.99px,
      rgb(240 240 240) 281.311px 278.147px, rgb(240 240 240) 3023.09px 1229.29px,
      rgb(240 240 240) 329.422px 2997.5px, rgb(240 240 240) 1216.69px 3330.84px,
      rgb(240 240 240) 356.538px 1560.49px, rgb(240 240 240) 892.066px 724.924px,
      rgb(240 240 240) 3386.87px 729.805px, rgb(240 240 240) 2721.11px 367.756px,
      rgb(240 240 240) 2910px 2654.95px, rgb(240 240 240) 3491.28px 1316.4px,
      rgb(240 240 240) 2556.49px 984.523px, rgb(240 240 240) 1830.62px 3108.17px,
      rgb(240 240 240) 358.311px 2384.43px, rgb(240 240 240) 3551.99px 799.498px,
      rgb(240 240 240) 1899.17px 3306.07px, rgb(240 240 240) 1668.81px 1666.09px,
      rgb(240 240 240) 107.148px 2203.88px, rgb(240 240 240) 1539.54px 3029.66px,
      rgb(240 240 240) 1131.73px 3074.92px, rgb(240 240 240) 1018.95px 3902.04px,
      rgb(240 240 240) 1985.48px 2486.52px, rgb(240 240 240) 3585.81px 1047.98px,
      rgb(240 240 240) 2408.42px 121.923px, rgb(240 240 240) 1462.24px 1729px,
      rgb(240 240 240) 1707.76px 2020.54px, rgb(240 240 240) 582.979px 2225.28px,
      rgb(240 240 240) 2255.71px 2690.8px, rgb(240 240 240) 3108.36px 2224px,
      rgb(240 240 240) 294.243px 2068.35px, rgb(240 240 240) 3504.25px 1899.49px,
      rgb(240 240 240) 2614.3px 2702.01px, rgb(240 240 240) 2819.97px 2510.64px,
      rgb(240 240 240) 3731.33px 366.816px, rgb(240 240 240) 3055.75px 3891.59px,
      rgb(240 240 240) 2585.43px 3035.07px, rgb(240 240 240) 2624.16px 339.536px,
      rgb(240 240 240) 3754.76px 461.813px, rgb(240 240 240) 2576.26px 1466.21px,
      rgb(240 240 240) 2319.34px 868.403px, rgb(240 240 240) 2493.33px 2855.29px,
      rgb(240 240 240) 2539.39px 3555.65px, rgb(240 240 240) 2018.55px 432.881px,
      rgb(240 240 240) 3471.43px 1632.79px, rgb(240 240 240) 3713.7px 1128.85px,
      rgb(240 240 240) 2415.23px 1024.46px, rgb(240 240 240) 3931.65px 2552.85px,
      rgb(240 240 240) 452.639px 832.581px, rgb(240 240 240) 3854.38px 3754.18px,
      rgb(240 240 240) 104.12px 3580.32px, rgb(240 240 240) 2266.88px 98.2421px,
      rgb(240 240 240) 1503.06px 1706.61px, rgb(240 240 240) 253.531px 3933.73px,
      rgb(240 240 240) 2781.98px 240.52px, rgb(240 240 240) 3421.88px 895.08px,
      rgb(240 240 240) 3012.95px 3564.6px, rgb(240 240 240) 3994.41px 628.93px,
      rgb(240 240 240) 1374.98px 1397.21px, rgb(240 240 240) 306.15px 1001.8px,
      rgb(240 240 240) 610.97px 975.896px, rgb(240 240 240) 1814.84px 1732.62px,
      rgb(240 240 240) 588.291px 2823.69px, rgb(240 240 240) 2246.1px 127.237px,
      rgb(240 240 240) 1967.74px 3537.46px, rgb(240 240 240) 2647.18px 1521.69px,
      rgb(240 240 240) 1855.51px 2789.04px, rgb(240 240 240) 1644.65px 755.076px,
      rgb(240 240 240) 2475.27px 2303.68px, rgb(240 240 240) 623.025px 1493.27px,
      rgb(240 240 240) 3674.07px 1765.12px, rgb(240 240 240) 2229.44px 1942.2px,
      rgb(240 240 240) 2761.32px 1777.03px, rgb(240 240 240) 3686.52px 1001.17px,
      rgb(240 240 240) 3304.27px 1215.42px, rgb(240 240 240) 65.2165px 3220.27px,
      rgb(240 240 240) 3667.08px 256.437px, rgb(240 240 240) 1265.95px 2070.59px,
      rgb(240 240 240) 3366.8px 683.89px, rgb(240 240 240) 2937.34px 13.9693px,
      rgb(240 240 240) 2676.24px 655.747px, rgb(240 240 240) 1909.26px 2648.66px,
      rgb(240 240 240) 1203.47px 1413.25px, rgb(240 240 240) 334.986px 3619.46px,
      rgb(240 240 240) 3426.63px 163.191px, rgb(240 240 240) 203.847px 1459.41px,
      rgb(240 240 240) 3790.18px 1089.61px, rgb(240 240 240) 3043.48px 3408.91px,
      rgb(240 240 240) 716.123px 2303.89px, rgb(240 240 240) 529.142px 900.309px,
      rgb(240 240 240) 686.471px 2188.32px, rgb(240 240 240) 678.717px 1110.43px,
      rgb(240 240 240) 930.362px 3498.7px, rgb(240 240 240) 630.672px 1461.93px,
      rgb(240 240 240) 3761.69px 2655.11px, rgb(240 240 240) 1996.62px 3265.41px,
      rgb(240 240 240) 3236.06px 2835.58px, rgb(240 240 240) 3963.75px 3684.01px,
      rgb(240 240 240) 269.342px 2292.81px, rgb(240 240 240) 3660.33px 1855.58px,
      rgb(240 240 240) 788.478px 1079.4px, rgb(240 240 240) 447.676px 285.631px,
      rgb(240 240 240) 651.141px 1816.59px, rgb(240 240 240) 1094.33px 2561.3px,
      rgb(240 240 240) 239.849px 2069.88px, rgb(240 240 240) 1525.19px 1907.73px,
      rgb(240 240 240) 3303.93px 3244.35px, rgb(240 240 240) 1552.77px 1624.6px,
      rgb(240 240 240) 171.332px 2100.14px, rgb(240 240 240) 1498.23px 501.716px,
      rgb(240 240 240) 2805.91px 2380.51px, rgb(240 240 240) 1810.72px 2901.55px,
      rgb(240 240 240) 2390.54px 3755.54px, rgb(240 240 240) 17.2446px 398.333px,
      rgb(240 240 240) 3827.68px 3237.25px, rgb(240 240 240) 649.475px 1083.19px,
      rgb(240 240 240) 1427.97px 2316.08px, rgb(240 240 240) 3756.57px 416.369px,
      rgb(240 240 240) 1326.5px 1385.13px, rgb(240 240 240) 508.951px 172.659px,
      rgb(240 240 240) 1705.65px 491.721px, rgb(240 240 240) 2735.31px 398.166px,
      rgb(240 240 240) 492.173px 1651.34px, rgb(240 240 240) 2127.19px 65.332px,
      rgb(240 240 240) 127.361px 3538.03px, rgb(240 240 240) 1669.26px 1770.7px,
      rgb(240 240 240) 2340.19px 2112.63px, rgb(240 240 240) 3999.07px 2863.75px,
      rgb(240 240 240) 3556.06px 311.651px, rgb(240 240 240) 335.617px 934.273px,
      rgb(240 240 240) 1289.15px 2394.12px, rgb(240 240 240) 1016.82px 594.036px,
      rgb(240 240 240) 154.028px 3900.18px, rgb(240 240 240) 690.976px 772.123px,
      rgb(240 240 240) 748.945px 1838.34px, rgb(240 240 240) 2761.73px 3409.75px,
      rgb(240 240 240) 1298.83px 3368.51px, rgb(240 240 240) 54.994px 2862.41px,
      rgb(240 240 240) 1157.38px 2003.27px, rgb(240 240 240) 2883.69px 885.783px,
      rgb(240 240 240) 2226.14px 3211.96px, rgb(240 240 240) 2324.46px 46.1438px,
      rgb(240 240 240) 1941.6px 3211.12px, rgb(240 240 240) 1510.35px 3995.52px,
      rgb(240 240 240) 3704.89px 2475.09px, rgb(240 240 240) 260.954px 3358.89px,
      rgb(240 240 240) 701.965px 956.646px, rgb(240 240 240) 3195.77px 740.501px,
      rgb(240 240 240) 1122.36px 2857.29px, rgb(240 240 240) 666.757px 2103.47px,
      rgb(240 240 240) 2258.75px 2248.6px, rgb(240 240 240) 961.137px 920.484px,
      rgb(240 240 240) 703.851px 329.361px, rgb(240 240 240) 3939.47px 107.406px,
      rgb(240 240 240) 2720.73px 3584.62px, rgb(240 240 240) 290.937px 2534.34px,
      rgb(240 240 240) 2748.63px 1397.82px, rgb(240 240 240) 1421.27px 2895.96px,
      rgb(240 240 240) 2857.88px 1314.62px, rgb(240 240 240) 3648.5px 1457.46px,
      rgb(240 240 240) 1610.02px 3947.64px, rgb(240 240 240) 3439.07px 1735.92px,
      rgb(240 240 240) 2025.59px 2720.08px, rgb(240 240 240) 268.385px 3097.97px,
      rgb(240 240 240) 1662.97px 2793.55px, rgb(240 240 240) 24.0626px 1833.27px,
      rgb(240 240 240) 2897.55px 333.176px, rgb(240 240 240) 3055.78px 1232.77px,
      rgb(240 240 240) 309.767px 2437.8px, rgb(240 240 240) 2625.94px 3075.1px,
      rgb(240 240 240) 563.603px 34.8387px, rgb(240 240 240) 1824.18px 768.263px,
      rgb(240 240 240) 1344.14px 2898.81px, rgb(240 240 240) 2446.2px 21.5975px,
      rgb(240 240 240) 2101.29px 747.977px, rgb(240 240 240) 2243.31px 3295.39px,
      rgb(240 240 240) 1013.01px 303.026px, rgb(240 240 240) 2924.84px 54.767px,
      rgb(240 240 240) 3989.96px 1370.24px, rgb(240 240 240) 874.195px 503.323px,
      rgb(240 240 240) 1631.9px 652.487px, rgb(240 240 240) 2908.67px 193.394px,
      rgb(240 240 240) 3046.71px 2206.59px, rgb(240 240 240) 504.452px 2888.62px,
      rgb(240 240 240) 93.7065px 2558.76px, rgb(240 240 240) 3565.45px 3346.91px,
      rgb(240 240 240) 2840.04px 911.805px, rgb(240 240 240) 383.166px 1318.22px,
      rgb(240 240 240) 3940.78px 3550.3px, rgb(240 240 240) 1706.62px 146.741px,
      rgb(240 240 240) 1999.23px 677.927px, rgb(240 240 240) 892.76px 2082.84px,
      rgb(240 240 240) 3555.23px 1258.5px, rgb(240 240 240) 3544.16px 1492.1px,
      rgb(240 240 240) 1962.4px 2749.74px, rgb(240 240 240) 1358.4px 1068.12px,
      rgb(240 240 240) 1870.15px 617.486px, rgb(240 240 240) 3037.48px 3169.64px,
      rgb(240 240 240) 3954.91px 617.46px, rgb(240 240 240) 1702.06px 1811.77px,
      rgb(240 240 240) 2885.67px 2981.87px, rgb(240 240 240) 708.16px 928.952px,
      rgb(240 240 240) 2876.46px 908.058px, rgb(240 240 240) 349.932px 1906.95px,
      rgb(240 240 240) 1162.74px 2739.76px, rgb(240 240 240) 3538.13px 1543.09px,
      rgb(240 240 240) 2235.81px 155.989px, rgb(240 240 240) 1018.88px 2796.52px,
      rgb(240 240 240) 885.301px 1734.22px, rgb(240 240 240) 1877.78px 2162.07px,
      rgb(240 240 240) 3491.3px 2666.23px, rgb(240 240 240) 534.733px 2618.06px,
      rgb(240 240 240) 360.649px 2124.22px, rgb(240 240 240) 2619.77px 359.249px,
      rgb(240 240 240) 560.325px 3197.27px, rgb(240 240 240) 2218.82px 301.248px,
      rgb(240 240 240) 2699.58px 3369.01px, rgb(240 240 240) 2333.74px 1480.56px,
      rgb(240 240 240) 3695px 3346.03px, rgb(240 240 240) 181.053px 246.803px,
      rgb(240 240 240) 1056.78px 1639.41px, rgb(240 240 240) 972.746px 2903.49px,
      rgb(240 240 240) 2653.73px 363.778px, rgb(240 240 240) 503.254px 210.138px,
      rgb(240 240 240) 3273.04px 1813.6px, rgb(240 240 240) 1634.51px 3116.24px,
      rgb(240 240 240) 1355.25px 2793.33px, rgb(240 240 240) 3408.5px 1311.48px,
      rgb(240 240 240) 2780.79px 1469.68px, rgb(240 240 240) 714.133px 3137.17px,
      rgb(240 240 240) 3726.46px 541.04px, rgb(240 240 240) 3660.14px 1370.05px,
      rgb(240 240 240) 3833.26px 533.357px, rgb(240 240 240) 2268.2px 2965.77px,
      rgb(240 240 240) 423.114px 3510.66px, rgb(240 240 240) 3540.53px 1380.67px,
      rgb(240 240 240) 1257.22px 1979.85px, rgb(240 240 240) 3050.26px 2625.91px,
      rgb(240 240 240) 1089.99px 3188.76px, rgb(240 240 240) 587.342px 926.991px,
      rgb(240 240 240) 3852.53px 1578.82px, rgb(240 240 240) 1715.32px 2595.8px,
      rgb(240 240 240) 2651.91px 3876.03px, rgb(240 240 240) 2720.14px 3776.92px,
      rgb(240 240 240) 2568.78px 721.122px, rgb(240 240 240) 1916.46px 361.318px,
      rgb(240 240 240) 1576.87px 1152.57px, rgb(240 240 240) 1955.06px 1624.05px,
      rgb(240 240 240) 182.64px 1075.05px, rgb(240 240 240) 3589.52px 74.5019px,
      rgb(240 240 240) 2404.4px 1167.99px, rgb(240 240 240) 2394.77px 2655.22px,
      rgb(240 240 240) 479.473px 826.549px, rgb(240 240 240) 3531.87px 44.8499px,
      rgb(240 240 240) 2165.3px 3360.6px, rgb(240 240 240) 2518.21px 3293.1px,
      rgb(240 240 240) 2787.18px 466.11px, rgb(240 240 240) 2360.98px 827.276px,
      rgb(240 240 240) 1465.56px 1663.59px, rgb(240 240 240) 2638.45px 1981.41px,
      rgb(240 240 240) 3814.14px 2893.34px, rgb(240 240 240) 467.791px 2479.05px,
      rgb(240 240 240) 2418.42px 2283.08px, rgb(240 240 240) 3604.09px 2378.72px,
      rgb(240 240 240) 1213.53px 3693.5px, rgb(240 240 240) 1093.88px 2298.96px,
      rgb(240 240 240) 1497.92px 1343.68px, rgb(240 240 240) 2717.98px 1516.02px,
      rgb(240 240 240) 2857.7px 3048.08px, rgb(240 240 240) 3403.05px 2813.43px,
      rgb(240 240 240) 995.307px 2243.33px, rgb(240 240 240) 1968.24px 3035.35px,
      rgb(240 240 240) 1166.41px 3119.19px, rgb(240 240 240) 2661.95px 3168.27px,
      rgb(240 240 240) 3540.88px 1671.77px, rgb(240 240 240) 3842.46px 3178.16px,
      rgb(240 240 240) 346.935px 3667.13px, rgb(240 240 240) 1891.83px 2355.85px,
      rgb(240 240 240) 3399.7px 2671.23px, rgb(240 240 240) 1027.83px 3632.9px,
      rgb(240 240 240) 3537.41px 607.251px, rgb(240 240 240) 644.196px 3592.49px,
      rgb(240 240 240) 3190.1px 88.0872px, rgb(240 240 240) 3625.42px 2346.09px,
      rgb(240 240 240) 3839.93px 77.9693px, rgb(240 240 240) 2584.03px 3135.48px,
      rgb(240 240 240) 669.512px 119.241px, rgb(240 240 240) 2892.03px 2526.99px,
      rgb(240 240 240) 2763.23px 2860.57px, rgb(240 240 240) 861.83px 1164.33px,
      rgb(240 240 240) 2643.71px 1024.62px, rgb(240 240 240) 369.375px 3107.43px,
      rgb(240 240 240) 3616.98px 2262.14px, rgb(240 240 240) 2804.63px 3918.07px,
      rgb(240 240 240) 297.298px 3202.21px, rgb(240 240 240) 1530.8px 1255.42px,
      rgb(240 240 240) 3791.44px 3093.52px, rgb(240 240 240) 1801.79px 2736.9px,
      rgb(240 240 240) 2872.74px 2011.34px, rgb(240 240 240) 1767.49px 1891.94px,
      rgb(240 240 240) 3000.72px 706.539px, rgb(240 240 240) 2968.35px 2040.57px,
      rgb(240 240 240) 2612.3px 3870.65px, rgb(240 240 240) 3682.39px 3420.55px,
      rgb(240 240 240) 1590.8px 1652.77px, rgb(240 240 240) 1266.47px 3296.37px,
      rgb(240 240 240) 1910.96px 3390.77px, rgb(240 240 240) 3366.25px 1341.55px,
      rgb(240 240 240) 1883.64px 3240.44px, rgb(240 240 240) 1919.75px 1945.85px,
      rgb(240 240 240) 3928.24px 147.373px, rgb(240 240 240) 3301.33px 3667.67px,
      rgb(240 240 240) 1778.29px 1828.39px, rgb(240 240 240) 1398.03px 349.161px,
      rgb(240 240 240) 2025.42px 1705.88px, rgb(240 240 240) 3876.29px 1374.67px,
      rgb(240 240 240) 621.445px 1620.51px, rgb(240 240 240) 3361.02px 3261.95px,
      rgb(240 240 240) 2518.69px 69.4988px, rgb(240 240 240) 178.044px 3227.56px,
      rgb(240 240 240) 196.582px 703.147px, rgb(240 240 240) 884.756px 2363.9px,
      rgb(240 240 240) 228.148px 3605.19px, rgb(240 240 240) 169.292px 87.6308px,
      rgb(240 240 240) 527.771px 2032.86px, rgb(240 240 240) 806.247px 3839.17px,
      rgb(240 240 240) 1142.87px 3958.62px, rgb(240 240 240) 2929.91px 255.882px,
      rgb(240 240 240) 2515.86px 543.39px, rgb(240 240 240) 3616.31px 2101.23px,
      rgb(240 240 240) 1744.38px 2394.09px, rgb(240 240 240) 3112.52px 1576.17px,
      rgb(240 240 240) 2909.85px 1341.74px, rgb(240 240 240) 527.952px 765.436px,
      rgb(240 240 240) 1254.14px 3465.37px, rgb(240 240 240) 1345.93px 1339.69px,
      rgb(240 240 240) 1291.29px 1552.77px, rgb(240 240 240) 512.256px 3426.62px,
      rgb(240 240 240) 1362.84px 1955.95px, rgb(240 240 240) 1594.13px 1948.83px,
      rgb(240 240 240) 3863.68px 1863.88px, rgb(240 240 240) 3639.48px 1482.94px,
      rgb(240 240 240) 3247.98px 3302.8px, rgb(240 240 240) 2895.55px 3189.16px,
      rgb(240 240 240) 3077.96px 213.626px, rgb(240 240 240) 16.068px 2484.36px,
      rgb(240 240 240) 2224.58px 3260.03px, rgb(240 240 240) 2189.48px 303.555px,
      rgb(240 240 240) 3788.44px 2468.59px, rgb(240 240 240) 1569.07px 1324.88px,
      rgb(240 240 240) 1004.67px 3012.84px, rgb(240 240 240) 1936.67px 2615.25px,
      rgb(240 240 240) 2179.95px 2470.04px, rgb(240 240 240) 1417.32px 908.256px,
      rgb(240 240 240) 1550.22px 2689.51px, rgb(240 240 240) 3142.55px 1956.62px,
      rgb(240 240 240) 545.821px 1210.99px, rgb(240 240 240) 1409.91px 1470.42px,
      rgb(240 240 240) 3040.39px 1725px, rgb(240 240 240) 2982.82px 910.694px,
      rgb(240 240 240) 3042.29px 3789.31px, rgb(240 240 240) 2679.7px 1602.95px,
      rgb(240 240 240) 433.254px 3797.04px, rgb(240 240 240) 813.549px 3842.04px,
      rgb(240 240 240) 738.338px 3114.76px, rgb(240 240 240) 2505.5px 939.387px,
      rgb(240 240 240) 709.812px 3122.81px, rgb(240 240 240) 1070px 1282.84px,
      rgb(240 240 240) 3086.43px 477.143px, rgb(240 240 240) 2933.09px 2533.11px,
      rgb(240 240 240) 3735.67px 3337.4px, rgb(240 240 240) 2313.89px 533.204px,
      rgb(240 240 240) 1604.73px 2663.68px, rgb(240 240 240) 3168.34px 1290.88px,
      rgb(240 240 240) 861.483px 2837.55px, rgb(240 240 240) 2476.11px 501.324px,
      rgb(240 240 240) 3398.95px 931.987px, rgb(240 240 240) 348.578px 2186.35px,
      rgb(240 240 240) 3328.49px 1189.88px, rgb(240 240 240) 3790.81px 3285.95px,
      rgb(240 240 240) 898.366px 1865.34px, rgb(240 240 240) 847.555px 2649.99px,
      rgb(240 240 240) 883.697px 3782.58px, rgb(240 240 240) 1314.08px 1096.54px,
      rgb(240 240 240) 2862.98px 3809.35px, rgb(240 240 240) 2663.23px 2330.23px,
      rgb(240 240 240) 3539.1px 683.855px, rgb(240 240 240) 3941.4px 115.653px,
      rgb(240 240 240) 752.804px 1083.22px, rgb(240 240 240) 172.682px 93.6706px,
      rgb(240 240 240) 2947.88px 471.827px, rgb(240 240 240) 1030.42px 2508.09px,
      rgb(240 240 240) 2682.01px 2331.34px, rgb(240 240 240) 24.6737px 1589.42px,
      rgb(240 240 240) 126.591px 1264.01px, rgb(240 240 240) 3898.8px 242.093px,
      rgb(240 240 240) 3986px 934.857px, rgb(240 240 240) 3331.66px 1286.27px,
      rgb(240 240 240) 2244.05px 1238.44px, rgb(240 240 240) 1053.75px 3901.51px,
      rgb(240 240 240) 2557.21px 2287.45px, rgb(240 240 240) 2564.55px 3173.82px,
      rgb(240 240 240) 959.685px 3640.83px, rgb(240 240 240) 1190.12px 3582.24px,
      rgb(240 240 240) 2344.98px 3294.54px, rgb(240 240 240) 1950.63px 3271.46px,
      rgb(240 240 240) 1968.1px 1591.93px, rgb(240 240 240) 719.408px 1648.78px,
      rgb(240 240 240) 3441.16px 1773.41px, rgb(240 240 240) 2114.47px 208.716px,
      rgb(240 240 240) 2329.72px 2070.69px, rgb(240 240 240) 1008.59px 3880.58px,
      rgb(240 240 240) 1576.59px 656.388px, rgb(240 240 240) 3817.71px 528.864px,
      rgb(240 240 240) 988.606px 2629.67px, rgb(240 240 240) 1205.21px 2903.65px,
      rgb(240 240 240) 895.125px 3969.47px, rgb(240 240 240) 1392.07px 185.029px,
      rgb(240 240 240) 1905.92px 1957.01px, rgb(240 240 240) 697.522px 1979.07px,
      rgb(240 240 240) 3765.28px 3880.43px, rgb(240 240 240) 1859.19px 1125.57px,
      rgb(240 240 240) 847.047px 2150.21px, rgb(240 240 240) 1487.77px 1511.87px,
      rgb(240 240 240) 691.185px 449.692px, rgb(240 240 240) 3727.44px 130.433px,
      rgb(240 240 240) 919.351px 2524.81px, rgb(240 240 240) 3129.67px 2745.75px,
      rgb(240 240 240) 1481.74px 785.655px, rgb(240 240 240) 2218.33px 162.067px,
      rgb(240 240 240) 3196.27px 1221.11px, rgb(240 240 240) 3714.12px 1138.22px,
      rgb(240 240 240) 918.426px 2328.03px, rgb(240 240 240) 3711.29px 3187.08px,
      rgb(240 240 240) 2384.17px 1322.44px, rgb(240 240 240) 180.678px 3627.17px,
      rgb(240 240 240) 1773.38px 2945.58px, rgb(240 240 240) 2846.07px 3762.84px,
      rgb(240 240 240) 209.716px 1640.29px, rgb(240 240 240) 170.64px 1191.96px,
      rgb(240 240 240) 3957.39px 1935.62px, rgb(240 240 240) 305.189px 81.9667px,
      rgb(240 240 240) 1567.82px 3570.11px, rgb(240 240 240) 136.43px 183.02px,
      rgb(240 240 240) 654.777px 3424.88px, rgb(240 240 240) 1867.93px 2990.13px,
      rgb(240 240 240) 1390.05px 2790.85px, rgb(240 240 240) 3044.28px 3962.94px,
      rgb(240 240 240) 3950.43px 725.015px, rgb(240 240 240) 1499.85px 1895.05px,
      rgb(240 240 240) 264.249px 2380.53px, rgb(240 240 240) 2125.61px 2010.11px,
      rgb(240 240 240) 3843.48px 2278.43px, rgb(240 240 240) 2266.7px 1357.81px,
      rgb(240 240 240) 1049.38px 3326.53px, rgb(240 240 240) 1366.4px 2985.43px,
      rgb(240 240 240) 1267.02px 2821.63px, rgb(240 240 240) 1679.69px 2219.54px,
      rgb(240 240 240) 165.937px 3282.8px, rgb(240 240 240) 3602.11px 3083.76px,
      rgb(240 240 240) 3806.68px 2290.51px, rgb(240 240 240) 2653.8px 2920.48px,
      rgb(240 240 240) 225.15px 1665.49px, rgb(240 240 240) 1730.34px 1850.89px,
      rgb(240 240 240) 2523.68px 3067.15px, rgb(240 240 240) 263.661px 2498.9px,
      rgb(240 240 240) 1353.54px 2579.65px, rgb(240 240 240) 2615.23px 2002.48px,
      rgb(240 240 240) 1995.45px 919.558px, rgb(240 240 240) 335.657px 2637.45px,
      rgb(240 240 240) 1469.27px 590.235px, rgb(240 240 240) 1567.08px 1491.81px,
      rgb(240 240 240) 3529.81px 2715.37px, rgb(240 240 240) 3609.39px 1733.62px,
      rgb(240 240 240) 521.738px 904.716px, rgb(240 240 240) 733.101px 484.965px,
      rgb(240 240 240) 3595.3px 3329.8px, rgb(240 240 240) 1435.43px 1415.76px,
      rgb(240 240 240) 120.412px 1163.26px, rgb(240 240 240) 2955.79px 3502.66px,
      rgb(240 240 240) 629.587px 2769.33px, rgb(240 240 240) 980.04px 914.694px,
      rgb(240 240 240) 1307.56px 1733.26px, rgb(240 240 240) 3625.95px 1069.12px,
      rgb(240 240 240) 3908.85px 2540.55px, rgb(240 240 240) 2258.96px 1497.56px,
      rgb(240 240 240) 3919.48px 1891.32px, rgb(240 240 240) 1347.34px 610.851px,
      rgb(240 240 240) 2281.94px 1410.56px, rgb(240 240 240) 1580.44px 112.556px,
      rgb(240 240 240) 3411.81px 3689.73px, rgb(240 240 240) 1188px 3630.59px,
      rgb(240 240 240) 2297.03px 3175.16px, rgb(240 240 240) 1706.59px 2087.01px,
      rgb(240 240 240) 325.86px 3784.88px, rgb(240 240 240) 727.449px 2407.54px,
      rgb(240 240 240) 3909.62px 2847.32px, rgb(240 240 240) 317.859px 3533.8px,
      rgb(240 240 240) 2863.2px 658.75px, rgb(240 240 240) 3959.31px 511.835px,
      rgb(240 240 240) 2162.96px 2321.74px, rgb(240 240 240) 937.295px 375.196px,
      rgb(240 240 240) 1144.62px 231.368px, rgb(240 240 240) 86.3998px 1474.15px,
      rgb(240 240 240) 1475.2px 1058.41px, rgb(240 240 240) 1878.63px 3362.17px,
      rgb(240 240 240) 821.044px 113.555px, rgb(240 240 240) 2218.77px 872.252px,
      rgb(240 240 240) 2283.41px 3249.45px, rgb(240 240 240) 3300.91px 2033.17px,
      rgb(240 240 240) 1735.37px 2566.39px, rgb(240 240 240) 818.218px 19.1215px,
      rgb(240 240 240) 3102.45px 1118.18px, rgb(240 240 240) 2193.29px 2070.51px,
      rgb(240 240 240) 1498.36px 3712.8px, rgb(240 240 240) 3243.64px 3812.03px,
      rgb(240 240 240) 37.6846px 1221.59px, rgb(240 240 240) 243.735px 1452.44px,
      rgb(240 240 240) 1016.86px 741.675px, rgb(240 240 240) 1642.08px 640.792px,
      rgb(240 240 240) 3202.24px 1738.02px, rgb(240 240 240) 2800.62px 961.97px,
      rgb(240 240 240) 2942.41px 78.7581px, rgb(240 240 240) 521.081px 1828.03px,
      rgb(240 240 240) 1590.17px 1281.49px, rgb(240 240 240) 3847.79px 3891.69px,
      rgb(240 240 240) 428.028px 2836.68px, rgb(240 240 240) 3712.78px 2496.63px,
      rgb(240 240 240) 3051.58px 3405.51px, rgb(240 240 240) 3826.7px 2320.49px,
      rgb(240 240 240) 1449.62px 3297.23px, rgb(240 240 240) 936.016px 1274.55px,
      rgb(240 240 240) 1082.01px 1369.33px, rgb(240 240 240) 3209.82px 2347.63px,
      rgb(240 240 240) 2996.78px 2566.01px, rgb(240 240 240) 2468.01px 1975.65px,
      rgb(240 240 240) 1618.63px 1375.88px, rgb(240 240 240) 1030.09px 120.173px,
      rgb(240 240 240) 712.739px 3544.26px, rgb(240 240 240) 1911.89px 3916.6px,
      rgb(240 240 240) 3909.45px 2723.67px, rgb(240 240 240) 227.75px 635.518px,
      rgb(240 240 240) 3717.8px 686.982px, rgb(240 240 240) 2062.33px 2160.44px,
      rgb(240 240 240) 1983.96px 2504.24px, rgb(240 240 240) 1340.57px 3193.14px,
      rgb(240 240 240) 3685.6px 3212.36px, rgb(240 240 240) 1934.71px 208.207px,
      rgb(240 240 240) 52.1415px 1332.6px, rgb(240 240 240) 1568.79px 3874.09px,
      rgb(240 240 240) 2245.93px 494.058px, rgb(240 240 240) 1198.13px 3195.7px,
      rgb(240 240 240) 3577.07px 2190.52px, rgb(240 240 240) 74.2252px 3931.89px,
      rgb(240 240 240) 2200.29px 1199.87px, rgb(240 240 240) 3333.07px 875.91px,
      rgb(240 240 240) 1539.74px 36.6037px, rgb(240 240 240) 607.672px 1894.14px,
      rgb(240 240 240) 1417.95px 433.629px, rgb(240 240 240) 682.781px 2229.99px,
      rgb(240 240 240) 3039.51px 3869.9px, rgb(240 240 240) 1830.44px 440.529px,
      rgb(240 240 240) 136.634px 75.4124px, rgb(240 240 240) 410.953px 398.116px,
      rgb(240 240 240) 1266.1px 642.689px, rgb(240 240 240) 3356.2px 578.606px,
      rgb(240 240 240) 530.869px 2308.41px, rgb(240 240 240) 2660.64px 3343.83px,
      rgb(240 240 240) 2233.17px 3565.36px, rgb(240 240 240) 2398.05px 1783.18px,
      rgb(240 240 240) 2887.01px 3132.91px, rgb(240 240 240) 1607.64px 2864.86px,
      rgb(240 240 240) 565.901px 1.11948px, rgb(240 240 240) 3698.98px 1026.61px,
      rgb(240 240 240) 433.887px 75.8698px, rgb(240 240 240) 182.623px 1769.44px,
      rgb(240 240 240) 683.343px 1997.46px, rgb(240 240 240) 3688.31px 3599.14px,
      rgb(240 240 240) 3502.65px 1451.69px, rgb(240 240 240) 441.879px 2991.22px,
      rgb(240 240 240) 1497.54px 802.716px, rgb(240 240 240) 638.063px 2474.49px,
      rgb(240 240 240) 3397.46px 1937.56px, rgb(240 240 240) 3524.11px 693.476px,
      rgb(240 240 240) 1863.04px 2154.21px, rgb(240 240 240) 3845.16px 3615.77px,
      rgb(240 240 240) 757.261px 2324.87px, rgb(240 240 240) 2755.16px 1280.91px,
      rgb(240 240 240) 1488.35px 3418.15px, rgb(240 240 240) 2247.65px 2233.79px,
      rgb(240 240 240) 2192.65px 1278.9px, rgb(240 240 240) 19.239px 3204.07px,
      rgb(240 240 240) 1022.37px 453.777px, rgb(240 240 240) 1708px 3587.7px,
      rgb(240 240 240) 48.159px 482.723px, rgb(240 240 240) 3789.79px 2186.1px;
  }
`;

export default StarContainerMd;
